import React from 'react'
import { styled, Palette } from '@mui/material'

import Typography, { TypographyTypeMap } from '@mui/material/Typography'

export type Variants = 'primary' | 'secondary' | 'outlined' | 'danger'
type Sizes = 'default' | 'small'

interface Props {
  type?: 'button' | 'submit'
  size?: Sizes
  startIcon?: React.ReactNode
  style?: React.CSSProperties
  variant: Variants
  title: string | React.ReactNode
  onClickHandler?: () => void
  textVariant?: TypographyTypeMap['props']['variant']
  disabled?: boolean
}

const getColor = (variant: Variants, palette: Palette): React.CSSProperties => {
  const defaultValues: React.CSSProperties = {
    color: palette.neutral['n1-100'],
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
  }
  switch (variant) {
    case 'primary':
      return defaultValues
    case 'secondary':
      return {
        color: palette.neutral['n7-100'],
        backgroundColor: palette.neutral['n1-100'],
        borderColor: palette.neutral['n1-100'],
      }
    case 'outlined':
      return {
        color: palette.neutral['n1-100'],
        backgroundColor: 'transparent',
        borderColor: palette.neutral['n5-100'],
      }
    case 'danger':
      return {
        color: palette.neutral['n1-100'],
        backgroundColor: palette.accent.a1,
        borderColor: palette.accent.a1,
      }
    default:
      return defaultValues
  }
}

const getSizeProps = (size: Sizes): React.CSSProperties => {
  const defaultValues: React.CSSProperties = {
    padding: '12px 24px',
    borderRadius: '12px',
    borderWidth: '2px',
  }
  switch (size) {
    case 'small':
      return { padding: '6px 16px', borderRadius: '6px', borderWidth: '1px' }
    case 'default':
      return defaultValues
    default:
      return defaultValues
  }
}

const CustomButton = styled('button')<Pick<Props, 'variant' | 'size'>>(
  ({ theme, variant, size }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    gap: '8px',
    cursor: 'pointer',
    ...getSizeProps(size as Sizes),
    ...getColor(variant, theme.palette),
    fontFamily: theme.typography.fontFamily,
    opacity: 0.9,
    transition: '0.9s all',
    '&:hover': {
      opacity: 1,
    },
    '&:active': {},
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  })
)

const StyledButton = (props: Props) => {
  const {
    type = 'button',
    startIcon,
    title,
    variant,
    size = 'default',
    style,
    onClickHandler,
    textVariant = 'base2',
    disabled = false,
    ...otherProps
  } = props

  return (
    <CustomButton
      {...otherProps}
      type={type}
      {...(onClickHandler && { onClick: onClickHandler })}
      style={{ ...style }}
      variant={variant}
      size={size}
      disabled={disabled}
    >
      {startIcon && startIcon}
      {typeof title === 'string' ? (
        <Typography variant={textVariant}>{title}</Typography>
      ) : (
        title
      )}
    </CustomButton>
  )
}

export default StyledButton
