import axios from 'axios'

import { getAccessToken } from './auth.service'

//types
import { IWorkspaceResponse } from 'types/common'

const BASE_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_AUTHENTICATION_PATH}`

export interface DefaultResponse {
  success: boolean
  message?: string
}

interface GetUserResponse extends DefaultResponse {
  data: {
    _id: string
    name: string
    email: string
    oAuthId: string
    oAuthProvider: string
    apiKeys: { name: string; value: string }[]
    workspaces: IWorkspaceResponse[]

    //To be decided
    company: {
      name: string
    }
    subscription: {
      plan: string
      periodEnd: number
    } | null
  }
}

export const me = async (): Promise<GetUserResponse> => {
  const response = await axios.get(`${BASE_URL}/v1/users/me/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })
  return response.data
}

interface UpdateData {
  userName?: string
  companyName?: string
  profileUrl?: string
  oldPassword?: string
  newPassword?: string
}

export const updateProfileData = async (
  userId: string,
  { userName, profileUrl, oldPassword, newPassword }: UpdateData
): Promise<DefaultResponse> => {
  const response = await axios.patch(
    `${BASE_URL}/v1/users/${userId}/update`,
    {
      userName,
      ...(profileUrl && { profileUrl }),
      ...(oldPassword && { oldPassword }),
      ...(newPassword && { newPassword }),
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  )
  return response.data
}

interface CreateAPIKeyResponse extends DefaultResponse {
  data: {
    name: string
    value: string
  }
}

export const createAPIKey = async (
  name: string
): Promise<CreateAPIKeyResponse> => {
  const result = await axios.post(
    `${BASE_URL}/v1/users/apiKeys`,
    {
      apiKeyName: name,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  )
  return result.data
}

export const deleteAPIKey = async (
  apiKeyValue: string
): Promise<DefaultResponse> => {
  const result = await axios.delete(`${BASE_URL}/v1/users/apiKeys`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    params: { apiKeyValue },
  })
  return result.data
}

export const contactSupport = async (
  message: string,
  email?: string
): Promise<DefaultResponse> => {
  const result = await axios.post(
    `${BASE_URL}/v1/users/contact-support`,
    {
      message: message,
      ...(email && { email }),
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  )
  return result.data
}

export interface IRequestEarlyAccess {
  name: string
  email: string
  company: string
  position: string
  useCaseInfo: string
}

export const requestEarlyAccess = async (
  earlyAccessInfo: IRequestEarlyAccess
): Promise<DefaultResponse> => {
  const result = await axios.post(
    `${BASE_URL}/v1/users/early-access`,
    earlyAccessInfo,
  )
  return result.data
}
