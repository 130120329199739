import { useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

//components
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import StyledButton from 'components/StyledButton/StyledButton'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'
import StyledToast from 'components/StyledToast/StyledToast'

//redux
import { selectUserInfo } from 'redux/userSlice'
import { logout } from 'redux/userSlice'
import { appSliceReset } from 'redux/appSlice'

//service
import { clearToken } from 'services/auth.service'

//assets
import User from 'assets/user.png'

const CTAContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  padding: '10px',
  gap: '8px',
  backgroundColor: theme.palette.neutral['n6-100'],
  borderRadius: '12px',
  // position: 'relative',
  // overflow: 'hidden',
}))

const ProfileBox = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  padding: '10px',
  alignItems: 'flex-start',
  gap: '16px',
}))

const AvatarBox = styled('div')<{ image?: string }>(({ image }) => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundImage: `url(${image ? image : User})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  flexShrink: 0,
}))

const ProfileInfo = styled('div')(({ theme }) => ({
  width: 'calc(100% - 56px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
}))

const UserProfile = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  //redux
  const userInfo = useSelector(selectUserInfo)

  //local
  const [logoutConfirmationModal, setLogoutConfirmationModal] = useState(false)

  const logoutHandler = () => {
    //1. Remove access token
    clearToken()

    //2. Reset userSlice, appSlice
    dispatch(logout())
    dispatch(appSliceReset())

    //3. Notify
    // toast(<StyledToast variant="success" title="Logged out successfully" />)

    //4. Navigate to root
    navigate('/', { replace: true })
  }

  return (
    <CTAContainer>
      <ProfileBox onClick={() => navigate('/settings')}>
        <AvatarBox></AvatarBox>
        <ProfileInfo>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="base2"
              sx={{
                maxWidth: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {userInfo.name}
            </Typography>
          </Box>
          <Typography
            sx={{
              maxWidth: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              color: theme.palette.neutral['n3-50'],
            }}
            variant="caption1"
          >
            {userInfo.email}
          </Typography>
        </ProfileInfo>
      </ProfileBox>
      <StyledButton
        startIcon={<LogoutOutlinedIcon fontSize="small" />}
        style={{ alignSelf: 'stretch' }}
        onClickHandler={() => setLogoutConfirmationModal(true)}
        variant="outlined"
        title="Logout"
        textVariant="base2"
        size="small"
      />
      {logoutConfirmationModal && (
        <ConfirmationModal
          title="Logout"
          isOpen={logoutConfirmationModal}
          confirmMessage={`Are you sure you want to logout?`}
          confirmBtnTitle="Logout"
          confirmHandler={logoutHandler}
          closeHandler={() => setLogoutConfirmationModal(false)}
        />
      )}
    </CTAContainer>
  )
}

export default UserProfile
