import React from 'react'
import Select, {
  SingleValue,
  ActionMeta,
  GroupBase,
} from 'react-select'
import { styled } from '@mui/material'

//components
import Typography from '@mui/material/Typography'

//types
import { SelectComponents } from 'react-select/dist/declarations/src/components'

const Container = styled('div')<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  ...(fullWidth && { width: '100%' }),
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flex: 1,
  cursor: 'pointer',
}))

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral['n3-100'],
}))

export interface OptionItem {
  label: string | React.ReactNode
  value: any
  action?: () => void
}

interface Props {
  title?: string
  options: OptionItem[]
  selectedValue: OptionItem
  placeholder: string
  onChange: (
    newValue: SingleValue<OptionItem>,
    actionMeta: ActionMeta<OptionItem>
  ) => void
  width?: number
  components?: Partial<
    SelectComponents<OptionItem, false, GroupBase<OptionItem>>
  >
  isSearchable?: boolean
  fullWidth?: boolean
}

const StyledReactSelect = ({
  title,
  options,
  selectedValue,
  placeholder,
  onChange,
  components,
  width = 100,
  isSearchable = false,
  fullWidth = false,
}: Props) => {
  const customStyles = {
    container: (base: any) => ({
      ...base,
      width: `${width}%`,
    }),
    control: (base: any) => ({
      ...base,
      minHeight: '50px',
      borderRadius: '12px',
      border: '1px solid #343839',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&:hover': {},
    }),
    valueContainer: (base: any, state: any) => ({
      ...base,
      paddingLeft: '16px',
      backgroundColor: state.isSelected ? '#232627' : 'transparent',
    }),
    singleValue: (base: any, state: any) => ({
      ...base,
      color: '#fefefe',
    }),
    multiValue: (base: any) => ({
      ...base,
    }),
    placeholder: (base: any) => ({
      ...base,
      paddingLeft: '12px',
      fontSize: '14px',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    //options wrapper
    menu: (base: any) => ({
      ...base,
      padding: '8px',
      borderRadius: '8px',
      backgroundColor: '#232627',
    }),
    //options list
    MenuList: (base: any) => ({
      ...base,
    }),
    //options list item
    option: (base: any, state: any) => ({
      ...base,
      color: '#fefefe',
      padding: '8px 16px',
      marginBottom: '6px',
      borderRadius: '8px',
      backgroundColor: state.isSelected ? '#000' : 'transparent',
      cursor: 'pointer',
      '&:hover': {
        color: '#E8ECEF',
        backgroundColor: '#000',
      },
    }),
  }

  return (
    <Container fullWidth={fullWidth}>
      {title && <Title variant="base2">{title}</Title>}
      <Select
        //isClearable={true}
        value={selectedValue}
        placeholder={placeholder}
        isSearchable={isSearchable}
        styles={customStyles}
        options={options}
        onChange={onChange}
        components={components}
        //menuIsOpen={true} //Use for debugging
      />
    </Container>
  )
}

export default StyledReactSelect
