import { styled } from '@mui/material'

export const IconContainer = styled('div')<{
  src: string
  width?: number
  height?: number
}>(({ theme, src, width, height }) => ({
  display: 'flex',
  backgroundImage: `url(${src})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  width: width ? `${width}px` : '20px',
  height: height ? `${height}px` : '20px',
}))
