import { useState } from 'react'
import { styled, useTheme } from '@mui/material'

//components
import EmailIcon from '@mui/icons-material/Email'
import StyledModal from 'components/StyledModal/StyledModal'
import StyledInput from 'components/StyledInput/StyledInput'
import StyledButton from 'components/StyledButton/StyledButton'
import StyledReactSelect from 'components/StyledReactSelect/StyledReactSelect'

import { WORKSPACE_ROLES } from 'utils/constants'
import { capitalizeFLetter } from 'utils/utils'

const InviteForm = styled('form')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  flex: 1,
}))

const Wrapper = styled('div')(() => ({
  display: 'flex',
  //alignItems: 'center',
  gap: '32px',
}))

const ActionContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '12px',
  flexGrow: 1,
}))

interface Props {
  isOpen: boolean
  closeHandler: () => void
  submitActionHandler: (email: string, role: string) => void
  loading?: boolean
  editMode?: boolean
  defaultValues?: { email: string; role: string }
}

const InviteOrEditMemberModal = ({
  editMode = false,
  loading = false,
  isOpen,
  closeHandler,
  submitActionHandler,
  defaultValues,
}: Props) => {
  const theme = useTheme()

  let defaultEmail = ''
  let defaultRole = {
    value: WORKSPACE_ROLES[0].value,
    label: WORKSPACE_ROLES[0].label,
  }

  if (editMode && defaultValues) {
    if (defaultValues.email) {
      defaultEmail = defaultValues.email
    }

    if (defaultValues.role) {
      defaultRole = {
        value: defaultValues.role,
        label: capitalizeFLetter(defaultValues.role),
      }
    }
  }

  //local
  const [memberEmail, setMemberEmail] = useState(defaultEmail)
  const [selectedRole, setSelectedRole] = useState<any>(defaultRole)

  return (
    <StyledModal
      isOpen={isOpen}
      title="Invite a member"
      closeHandler={closeHandler}
      width={640}
    >
      <InviteForm
        onSubmit={(e) => {
          e.preventDefault()
          submitActionHandler(memberEmail, selectedRole.value)
        }}
      >
        <StyledInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setMemberEmail(e.target.value)
          }}
          inputType="email"
          autoFocus={true}
          id="invite-members"
          label="Email"
          placeholder="Member email"
          labelIcon={
            <EmailIcon
              fontSize="small"
              sx={{ color: theme.palette.neutral['n4-50'] }}
            />
          }
          value={memberEmail}
          isRequired={true}
          fullWidth={true}
          disabled={editMode}
        />
        <StyledReactSelect
          isSearchable={true}
          title="Role"
          selectedValue={selectedRole}
          options={WORKSPACE_ROLES}
          placeholder="Select role"
          onChange={(valueObj) => {
            if (valueObj) {
              setSelectedRole({
                value: valueObj.value,
                label: valueObj.label,
              })
            }
          }}
          fullWidth={true}
        />
        <ActionContainer>
          <StyledButton
            onClickHandler={closeHandler}
            title="Cancel"
            variant="outlined"
            textVariant="base1"
          />
          <StyledButton
            type="submit"
            title={
              editMode
                ? loading
                  ? 'Updating...'
                  : 'Update'
                : loading
                ? 'Inviting...'
                : 'Invite'
            }
            variant="primary"
            textVariant="base1"
            disabled={loading}
          />
        </ActionContainer>
      </InviteForm>
    </StyledModal>
  )
}

export default InviteOrEditMemberModal
