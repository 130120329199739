import { useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { toast } from 'react-toastify'

//components
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Switch from '@mui/material/Switch'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined'
import StyledModal from 'components/StyledModal/StyledModal'
import StyledInput from 'components/StyledInput/StyledInput'
import StyledButton from 'components/StyledButton/StyledButton'
import StyledReactSelect, {
  OptionItem,
} from 'components/StyledReactSelect/StyledReactSelect'
import StyledToast from 'components/StyledToast/StyledToast'
import { MachinesPricing } from 'types/common'
import getMachineSpecs, { MachineSpecs } from 'utils/getMachineSpecs'
import { components } from 'react-select'

const NotebookForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  flex: 1,
}))

const SwitchWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const LabelWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

const ActionContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '12px',
  flexGrow: 1,
}))

const FooterWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '12px',
  marginTop: '-8px',
}))

const PricingContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '8px 2px',
}))

const PriceDisplay = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  'span:last-child': {
    marginLeft: '-4px',
  },
}))

const StyledOption = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledLabel = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0px',
}))

interface Props {
  machinesPricing: MachinesPricing
  isOpen: boolean
  closeHandler: () => void
  submitActionHandler: (
    name: string,
    machineType: string,
    spot: boolean,
    location: string
  ) => void
  priceFetching?: boolean
  loading?: boolean
}

const SpecsLabel = (specs: MachineSpecs) => {
  return (
    <Typography
      sx={{ color: (theme) => theme.palette.neutral['n4-75'] }}
      variant="caption"
    >
      {specs.cpu} vCPUs, {specs.memory}GB RAM, {specs.diskSize}
      GB Disk
      {specs.gpu
        ? `, ${specs.gpu.number} ${specs.gpu.type} (${specs.gpu.memory}GB) GPU`
        : ''}
    </Typography>
  )
}

const CreateNotebookModal = ({
  loading = false,
  isOpen,
  closeHandler,
  submitActionHandler,
  machinesPricing,
  priceFetching,
}: Props) => {
  const theme = useTheme()

  //local
  const [notebookName, setNotebookName] = useState('')
  const [selectedProviderRegion, setSelectedProviderRegion] =
    useState<OptionItem>({
      value: Object.keys(machinesPricing)[0],
      label: Object.keys(machinesPricing)[0],
    })
  const [selectedInstanceType, setSelectedInstanceType] = useState<OptionItem>({
    label: 'Select instance type',
    value: 0,
  })
  const [isSpotEnabled, setIsSpotEnabled] = useState(true)

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSpotEnabled(event.target.checked)
  }

  return (
    <StyledModal
      isOpen={isOpen}
      title="Create a new notebook"
      closeHandler={closeHandler}
      width={640}
      loading={priceFetching}
    >
      <NotebookForm
        onSubmit={(e) => {
          e.preventDefault()
          const pattern =
            /^[a-z0-9]([-a-z0-9]*[a-z0-9])?([a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/
          const isValid = pattern.test(notebookName)

          if (!isValid) {
            toast(
              <StyledToast
                variant="error"
                title={`Please ensure notebook name adheres to the specified naming rules.`}
              />
            )
            return
          }

          if (!selectedInstanceType?.value) {
            toast(
              <StyledToast
                variant="error"
                title={`Select instance type to continue`}
              />
            )
            return
          }
          submitActionHandler(
            notebookName,
            String(selectedInstanceType.value),
            isSpotEnabled,
            selectedProviderRegion.value
          )
        }}
      >
        <StyledInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNotebookName(e.target.value)
          }}
          autoFocus={true}
          id="create-new-notebook"
          label="Name your notebook"
          placeholder="Enter name"
          labelIcon={
            <StickyNote2OutlinedIcon
              sx={{ color: theme.palette.neutral['n4-50'] }}
            />
          }
          value={notebookName}
          isRequired={true}
          fullWidth={true}
          info="The name should only include lowercase letters, numbers, and hyphens. It must start and end with a letter or number, and be up to 30 characters long."
        />
        <StyledReactSelect
          isSearchable={true}
          title="Select location"
          selectedValue={selectedProviderRegion}
          options={Object.keys(machinesPricing).map((providerRegion) => {
            return {
              value: providerRegion,
              label: providerRegion,
            } as OptionItem
          })}
          placeholder="Select location"
          onChange={(valueObj) => {
            if (valueObj) {
              setSelectedProviderRegion({
                value: valueObj.value as number,
                label: valueObj.label as any,
              })
            }
          }}
          fullWidth
        />
        <StyledReactSelect
          isSearchable={true}
          title="Instance type"
          selectedValue={selectedInstanceType}
          options={Object.keys(
            machinesPricing[selectedProviderRegion.value]
          ).map((machine) => {
            return {
              value: machine,
              label: machine.toUpperCase(),
              specs: getMachineSpecs(selectedProviderRegion.value, machine),
            } as OptionItem
          })}
          placeholder="Select instance type"
          onChange={(valueObj) => {
            if (valueObj) {
              setSelectedInstanceType({
                value: valueObj.value as number,
                label: valueObj.label as any,
              })
            }
          }}
          components={{
            Option: (props) => {
              const specs = (props.data as any).specs as
                | MachineSpecs
                | undefined
              return (
                <components.Option {...props}>
                  <StyledOption>
                    <Typography variant="base2">{props.label}</Typography>
                    {specs !== undefined && SpecsLabel(specs)}
                  </StyledOption>
                </components.Option>
              )
            },
            SingleValue: (props) => {
              const specs = !selectedProviderRegion.value
                ? getMachineSpecs(
                    selectedProviderRegion.value,
                    props.data.value
                  )
                : undefined
              return (
                <components.SingleValue {...props}>
                  <StyledLabel>
                    <Typography variant="base2">{props.data.label}</Typography>
                    {specs !== undefined && SpecsLabel(specs)}
                  </StyledLabel>
                </components.SingleValue>
              )
            },
          }}
          fullWidth
        />
        <SwitchWrapper>
          <LabelWrapper>
            <Typography
              variant="base2"
              sx={{ color: theme.palette.neutral['n3-100'] }}
            >
              Enable spot
            </Typography>

            <Tooltip
              title="Spot instances offer cost-effective computing resources that can be interrupted by the cloud provider when demand is high, providing significant savings compared to regular instances"
              placement="right"
            >
              <InfoOutlinedIcon
                fontSize="small"
                sx={{
                  color: theme.palette.neutral['n3-100'],
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </LabelWrapper>
          <Switch
            checked={isSpotEnabled}
            onChange={handleSwitchChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </SwitchWrapper>
        <FooterWrapper>
          {selectedInstanceType?.value ? (
            isSpotEnabled ? (
              <PricingContainer>
                <PriceDisplay>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme.palette.neutral['n2-75'] }}
                  >
                    On Demand:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme.palette.neutral['n3-100'] }}
                  >
                    {`$${machinesPricing[selectedProviderRegion.value][
                      selectedInstanceType.value
                    ].onDemand.toFixed(2)}/hr`}
                  </Typography>
                </PriceDisplay>
                <PriceDisplay>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme.palette.neutral['n2-75'] }}
                  >
                    Spot:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme.palette.neutral['n3-100'] }}
                  >
                    {`$${machinesPricing[selectedProviderRegion.value][
                      selectedInstanceType.value
                    ].spot.toFixed(2)}/hr`}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: theme.palette.error.light }}
                  >
                    {/* // % savings */}
                    {`(${(
                      ((machinesPricing[selectedProviderRegion.value][
                        selectedInstanceType.value
                      ].spot -
                        machinesPricing[selectedProviderRegion.value][
                          selectedInstanceType.value
                        ].onDemand) /
                        machinesPricing[selectedProviderRegion.value][
                          selectedInstanceType.value
                        ].onDemand) *
                      100
                    ).toFixed(2)}%)`}
                  </Typography>
                </PriceDisplay>
              </PricingContainer>
            ) : (
              <PricingContainer>
                <PriceDisplay>
                  <Typography
                    variant="base1"
                    sx={{ color: theme.palette.neutral['n2-75'] }}
                  >
                    Pricing:
                  </Typography>
                  <Typography
                    variant="base1"
                    sx={{ color: theme.palette.neutral['n3-100'] }}
                  >
                    {`$${machinesPricing[selectedProviderRegion.value][
                      selectedInstanceType.value
                    ].onDemand.toFixed(2)}/hr`}
                  </Typography>
                </PriceDisplay>
              </PricingContainer>
            )
          ) : null}
          <ActionContainer>
            <StyledButton
              onClickHandler={closeHandler}
              title="Cancel"
              variant="outlined"
              textVariant="base1"
            />
            <StyledButton
              type="submit"
              title={loading ? 'Creating...' : 'Create'}
              variant="primary"
              textVariant="base1"
              disabled={
                loading ||
                !notebookName ||
                !selectedInstanceType.value ||
                !selectedProviderRegion.value
              }
            />
          </ActionContainer>
        </FooterWrapper>
      </NotebookForm>
    </StyledModal>
  )
}

export default CreateNotebookModal
