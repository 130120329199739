import { styled, useTheme } from '@mui/material'

//components
import Typography from '@mui/material/Typography'
import StyledTable from 'components/StyledTables/StyledTable'
import {
  tableEntry,
  tableHeader,
} from 'components/StyledTables/TableComponents'
import { ComponentLoader } from 'components/Loader/Loader'

//redux
import { IQuotasTable } from 'types/common'

const TableContainer = styled('section')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  overflowX: 'scroll',
  borderRadius: '0 0 20px 20px',
  color: 'black !important',
  backgroundColor: theme.palette.neutral['n6-100'],
  '&::-webkit-scrollbar': {
    height: '8px',
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    height: '4px',
    backgroundColor: theme.palette.neutral['n4-50'],
  },
}))

interface Props {
  quotas: IQuotasTable[]
  loading: boolean
}

const QuotasInfo = ({ loading, quotas }: Props) => {
  const theme = useTheme()

  const quotasTable = {
    columns: [
      {
        Header: tableHeader('Resource name', theme),
        accessor: 'resourceName',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return tableEntry(row.values.resourceName, theme)
        },
      },
      {
        Header: tableHeader('Current usage', theme),
        accessor: 'currentUsage',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return tableEntry(row.values.currentUsage, theme)
        },
      },
      {
        Header: tableHeader('Maximum quota', theme),
        accessor: 'maxQuota',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return tableEntry(row.values.maxQuota, theme)
        },
        //width: 150,
      },
    ],
    rows: quotas,
  }

  return (
    <div>
      <TableContainer>
        {loading && <ComponentLoader />}
        {!loading && quotasTable.rows.length === 0 && (
          <Typography
            sx={{ color: theme.palette.neutral['n1-100'] }}
            variant="body2"
          >
            No information available
          </Typography>
        )}
        {!loading && quotasTable.rows.length > 0 && (
          <StyledTable
            pagination={true}
            pageCount={5}
            columns={quotasTable.columns}
            data={quotasTable.rows}
            hiddenColumns={[]} //Note: pass 'accessor' value not 'Header' name
          />
        )}
      </TableContainer>
    </div>
  )
}

export default QuotasInfo
