import { IQuotaInfo, IQuotasTable } from "types/common"

const quotasToTabularData = (quotaInfo: IQuotaInfo) => {
  const quotasTable: IQuotasTable[] = []

  Object.keys(quotaInfo.quotas_usage).forEach((key) => {
    quotasTable.push({
      resourceName: key,
      currentUsage: quotaInfo.quotas_usage[key].current_usage,
      maxQuota: quotaInfo.quotas_usage[key].max_quota,
    })
  })
  return quotasTable.sort((a, b) => b.currentUsage - a.currentUsage)
}

export default quotasToTabularData
