export const MAX_FILES = 5
export const SIDE_PANEL_WIDTH = 280
export const CHATBOT_STATUS_POLLING_TIME = 10 * 1000 // seconds * 1000
export const supportedExtensions = ['.csv', '.doc', '.docx', '.pdf', '.txt']
export const ACCESS_TOKEN_KEY = 'xcloud_access_token'
export const WORKSPACE_TOKEN_KEY = 'xcloud_invite_token'
export const STANDARD_ERROR_MESSAGE =
  'Oops! Something went wrong. Please try again later.'

export const WORKSPACE_ROLES = [
  //{ value: 'owner', label: 'Owner' },
  { value: 'admin', label: 'Admin' },
  { value: 'billing', label: 'Billing' },
  { value: 'write', label: 'Write' },
  { value: 'read', label: 'Read' },
]
