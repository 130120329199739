import { createTheme } from '@mui/material'

import palette from './palette'
import typography from './typography'

export const darkTheme = createTheme({
  palette,
  spacing: 8,
  typography,
})
