import { Event } from '@mui/icons-material'
import { Typography, styled } from '@mui/material'
import StyledButton from 'components/StyledButton/StyledButton'

const NoticeWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  padding: '24px 40px',
  gap: '32px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
}))

const InactiveWorkspace = () => {
  return (
    <NoticeWrapper>
      <Typography variant="body2">
        This workspace is not activated yet, to activate it please schedule a
        demo with our team.
      </Typography>
      <StyledButton
        data-cal-link="sarthak-langde-stochastic/demo"
        title="Schedule a demo"
        startIcon={<Event />}
        variant="primary"
      />
    </NoticeWrapper>
  )
}

export default InactiveWorkspace
