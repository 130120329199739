import { useRef } from 'react'
import { styled, useTheme } from '@mui/material'
import { toast } from 'react-toastify'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import StyledToast from 'components/StyledToast/StyledToast'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap:'8px'
}))

const InputBox = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
  borderRadius: '12px',
  border: `2px solid ${theme.palette.neutral['n5-100']}`
}))

const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minHeight: '36px',
  marginBottom: '0px',
  padding: '14px 16px',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  border: 'none',
  outline: 'none',
  borderRadius: '12px',
  color: theme.palette.neutral['n3-50'],
  backgroundColor: theme.palette.neutral['n7-50'],
}))

const CopyBtnContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: '8px',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}))

interface Props {
  label: string
  value: string
}

const CopyTextField = ({ label, value }: Props) => {
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)

  const copyTextHandler = () => {
    // Select the text field
    let copyText = inputRef.current

    // Copy the text inside the text field
    if (copyText?.value) {
      navigator.clipboard.writeText(copyText.value)
    }

    //Copied to clipboard
    toast(<StyledToast variant="info" title="Copied to clipboard" />)
  }

  return (
    <Container>
      {label && (
        <Typography variant="base2" sx={{ fontWeight: 500, textAlign: 'left' }}>
          {label}
        </Typography>
      )}
      <InputBox>
        <StyledInput ref={inputRef} value={value} disabled />
        <Tooltip placement="top" title="Copy">
          <CopyBtnContainer onClick={copyTextHandler}>
            <FileCopyOutlinedIcon
              fontSize="small"
              style={{ color: theme.palette.neutral['n4-100'] }}
            />
          </CopyBtnContainer>
        </Tooltip>
      </InputBox>
    </Container>
  )
}

export default CopyTextField
