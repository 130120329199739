import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { IWorkspace, WorkspaceRoles } from 'types/common'

interface UserInfo {
  userId: string
  name: string
  email: string
  apiKeys: { name: string; value: string }[]
  workspaces: IWorkspace[]
  companyName: string
  profileUrl: string
  isPermissionGranted: boolean
}

interface userState {
  theme: 'light' | 'dark'
  isLoggedIn: boolean
  userInfo: UserInfo
  currentWorkspace: IWorkspace | null
  refetchUserDataCount: number
}

export const initialState: userState = {
  isLoggedIn: false,
  theme: 'light',
  currentWorkspace: null,
  refetchUserDataCount: 0,
  userInfo: {
    userId: '',
    name: '',
    email: '',
    apiKeys: [],
    workspaces: [],
    companyName: '',
    profileUrl: '',
    isPermissionGranted: false,
    // subscription: null,
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    refetchUserData: (state) => {
      state.refetchUserDataCount += 1
    },
    setTheme: (state, action: PayloadAction<'light' | 'dark'>) => {
      state.theme = action.payload
    },
    login: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setUserInfo: (state, action: PayloadAction<Partial<UserInfo>>) => {
      if (action.payload.workspaces) {
        state.currentWorkspace = state.currentWorkspace
          ? state.currentWorkspace
          : action.payload.workspaces[0]
      }
      state.userInfo = {
        ...state.userInfo,
        ...action.payload,
      }
    },
    setCurrentWorkspace: (state, action: PayloadAction<string>) => {
      const workspace = state.userInfo.workspaces.find(
        (item) => item.id === action.payload
      )
      if (workspace) {
        state.currentWorkspace = workspace
      }
    },
    updateWorkspaces: (state, action: PayloadAction<IWorkspace[]>) => {
      state.userInfo.workspaces = action.payload
    },
    updateMemberInCurrentWorkspace: (
      state,
      action: PayloadAction<{ memberId: string; role: WorkspaceRoles }>
    ) => {
      if (state.currentWorkspace) {
        state.currentWorkspace.members = state.currentWorkspace.members.map(
          (member) => {
            if (member.id === action.payload.memberId) {
              return {
                ...member,
                role: action.payload.role,
              }
            } else {
              return member
            }
          }
        )
      }
    },
    removeMemberFromCurrentWorkspace: (
      state,
      action: PayloadAction<string>
    ) => {
      if (state.currentWorkspace) {
        state.currentWorkspace.members = state.currentWorkspace.members.filter(
          (member) => member.id !== action.payload
        )
      }
    },
    addWorkspace: (state, action: PayloadAction<IWorkspace>) => {
      state.userInfo.workspaces = [...state.userInfo.workspaces, action.payload]
      state.currentWorkspace = action.payload
    },
    removeWorkspace: (
      state,
      action: PayloadAction<{ id: string; resetCurrentWorkspace?: boolean }>
    ) => {
      const filteredWorkspaces = state.userInfo.workspaces.filter(
        (item) => item.id !== action.payload.id
      )
      state.userInfo.workspaces = filteredWorkspaces

      if (action.payload.resetCurrentWorkspace) {
        state.currentWorkspace = filteredWorkspaces[0]
      }
    },
    addAPIkey: (
      state,
      action: PayloadAction<{ value: string; name: string }>
    ) => {
      state.userInfo.apiKeys = [...state.userInfo.apiKeys, action.payload]
    },
    removeAPIKey: (state, action: PayloadAction<{ value: string }>) => {
      const filteredAPIkeys = state.userInfo.apiKeys.filter(
        (item) => item.value !== action.payload.value
      )
      state.userInfo.apiKeys = filteredAPIkeys
    },
    logout: () => {
      //NOTE: returning the initial state to reset the state
      return { ...initialState }
    },
  },
})

export const {
  setTheme,
  login: setIsLoggedIn,
  setUserInfo,
  setCurrentWorkspace,
  addAPIkey,
  removeAPIKey,
  addWorkspace,
  updateWorkspaces,
  removeWorkspace,
  refetchUserData,
  updateMemberInCurrentWorkspace,
  removeMemberFromCurrentWorkspace,
  logout,
} = userSlice.actions

export const selectTheme = (state: RootState) => state.user.theme
export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn
export const selectUserInfo = (state: RootState) => state.user.userInfo
export const selectUserId = (state: RootState) => state.user.userInfo.userId
export const selectWorkspaces = (state: RootState) =>
  state.user.userInfo.workspaces
export const selectCurrentWorkspace = (state: RootState) =>
  state.user.currentWorkspace
export const selectApiKeys = (state: RootState) => state.user.userInfo.apiKeys
export const selectRefetchUserDataCount = (state: RootState) =>
  state.user.refetchUserDataCount

export default userSlice
