import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

//slices
import appSlice, { initialState as appInitialState } from './appSlice'
import userSlice from './userSlice'

const X_CLOUD_STATE = 'x_cloud_state'

export const store = configureStore({
  reducer: combineReducers({
    app: appSlice.reducer,
    user: userSlice.reducer,
  }),
  preloadedState: loadFromLocalStorage(),
})

//important*** subscribe to use localStorage
store.subscribe(() => saveToLocalStorage(store.getState()))

//infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

//export a hook that can be reused to resolve types
export const useAppDispatch: () => AppDispatch = useDispatch

//helper functions to use local storage
function saveToLocalStorage(state: any) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(X_CLOUD_STATE, serializedState)
  } catch (e) {
    console.log(e)
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem(X_CLOUD_STATE)

    if (serializedState === null) return undefined

    const state = JSON.parse(serializedState)

    return {
      app: { ...appInitialState },
      user: { ...state.user }, //Only load the auth & user info localStorage
    }
  } catch (error) {
    console.log('LOCAL_STORAGE_SYNC_FAILED', error)
    return undefined
  }
}
