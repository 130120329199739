import { styled } from '@mui/material'

export const TabContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minWidth: 'fit-content',
  borderBottom: `1px solid ${theme.palette.neutral['n5-100']}`,
}))
