import axios from 'axios'

import { getAccessToken } from './auth.service'

//types
import {
  DataResponse,
  DefaultResponse,
  IQuotaInfo,
  IUsageInfo,
  MachinesPricing,
} from 'types/common'

const BASE_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_USAGE_SERVICE_PATH}`

export interface GetUsage extends DefaultResponse {
  data: IUsageInfo
}

export const getUsageInfo = async (
  workspaceId: string,
  startTime?: number,
  endTime?: number
): Promise<GetUsage> => {
  const result = await axios.get(`${BASE_URL}/v1/usage/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
    params: {
      ...(startTime && { start_timestamp: startTime }),
      ...(endTime && { end_timestamp: endTime }),
    },
  })
  return result.data
}

export interface GetQuotas extends DefaultResponse {
  data: IQuotaInfo
}

export const getQuotasInfo = async (
  workspaceId: string
): Promise<GetQuotas> => {
  const result = await axios.get(`${BASE_URL}/v1/quotas/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
  })
  return result.data
}

export const getHourlyMachinePricing = async (
  workspaceId: string
): Promise<MachinesPricing> => {
  const {
    data: { data },
  } = await axios.get<
    DataResponse<{
      pricing: Record<string, Record<string, number>>
      spot_pricing: Record<string, Record<string, number>>
    }>
  >(`${BASE_URL}/v1/pricing/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
  })

  const pricingObj: Record<string, Record<string, Record<string, number>>> = {}

  Object.keys(data.pricing).forEach((providerRegionName) => {

    if (!pricingObj[providerRegionName]) {
      pricingObj[providerRegionName] = {}
    }

    Object.keys(data.pricing[providerRegionName]).forEach((resourceName) => {
      if (!pricingObj[providerRegionName][resourceName]) {
        pricingObj[providerRegionName][resourceName] = {}
      }

      pricingObj[providerRegionName][resourceName].onDemand =
        data.pricing[providerRegionName][resourceName]
    })
  })

  Object.keys(data.spot_pricing).forEach((providerRegionName) => {
    
    if (!pricingObj[providerRegionName]) {
      pricingObj[providerRegionName] = {}
    }

    Object.keys(data.spot_pricing[providerRegionName]).forEach(
      (resourceName) => {
        if (!pricingObj[providerRegionName][resourceName]) {
          pricingObj[providerRegionName][resourceName] = {}
        }

        pricingObj[providerRegionName][resourceName].spot =
          data.spot_pricing[providerRegionName][resourceName]
      }
    )
  })

  return pricingObj as MachinesPricing
}
