import { styled } from '@mui/material'
//assets
import LogoImg from 'assets/xcloud_logo.png'

const LogoWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flex: '0 0 auto',
  padding: '0 0 0 28px',
  marginTop: '40px',
  marginBottom: '40px',
}))

const Logo = () => {
  return (
    <LogoWrapper>
      <img width={150} src={LogoImg} alt="xcloud-logo" />
    </LogoWrapper>
  )
}

export default Logo
