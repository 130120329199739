import { memo } from 'react'
import { styled } from '@mui/material'
import { Outlet } from 'react-router-dom'

//layout
import AppLayout from 'layouts/AppLayout'

const DeploymentsOutlet = () => {
  return (
    <AppLayout overflow='hidden'>
      <Outlet />
    </AppLayout>
  )
}

export default memo(DeploymentsOutlet)
