import { styled } from '@mui/material'
import { Outlet } from 'react-router-dom'

const Main = styled('main')(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.neutral['n7-100'],
}))

const AuthPageLayout = () => (
  <Main>
    <Outlet />
  </Main>
)

export default AuthPageLayout
