import { memo } from 'react'
import { Outlet } from 'react-router-dom'

//layout
import AppLayout from 'layouts/AppLayout'

const ExecutionsOutlet = () => {
  return (
    <AppLayout overflow='hidden'>
      <Outlet />
    </AppLayout>
  )
}

export default memo(ExecutionsOutlet)
