import React, { useEffect, useState, memo } from 'react'
import { styled } from '@mui/material'
import { useSearchParams, useNavigate } from 'react-router-dom'

//layout
import AppLayout from 'layouts/AppLayout'
import PageHeader from 'components/PageHeader/PageHeader'
import { TabContainer } from 'components/StyledTab/TabContainer'
import { TabItem } from 'components/StyledTab/TabItem'
import EditProfile from './components/EditProfile/EditProfile'
import UpdatePassword from './components/UpdatePassword/UpdatePassword'
import Credentials from './components/Credentials/Credentials'
import { ComponentLoader } from 'components/Loader/Loader'

const PageContent = styled('div')(() => ({
  flex: '1 1 auto',
  width: '100% ',
  display: 'flex',
  flexDirection: 'column',
  padding: '32px ',
}))

const TabComponentWrapper = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px 0'
}))

const SETTINGS_TABS = [
  {
    name: 'Edit profile',
    value: 'edit-profile',
  },
  {
    name: 'Password',
    value: 'password',
  },
  {
    name: 'Credentials',
    value: 'credentials',
  },
]

const SettingsOutlet = () => {
  const navigate = useNavigate()
  const [URLSearchParams] = useSearchParams()
  const tab = URLSearchParams.get('tab')

  let defaultActiveTab = SETTINGS_TABS[0].value

  if (tab) {
    defaultActiveTab = tab
  }

  const [activeSubTab, setActiveSubTab] = useState<string>(defaultActiveTab)

  useEffect(() => {
    navigate(`/settings?tab=${activeSubTab}`)
  }, [activeSubTab])

  return (
    <AppLayout overflow="hidden">
        <PageHeader title="Settings" path="/settings" />
        <PageContent>
          <TabContainer>
            {React.Children.toArray(
              SETTINGS_TABS.map((tab) => (
                <TabItem
                  isActive={activeSubTab === tab.value}
                  onClick={() => setActiveSubTab(tab.value)}
                >
                  {tab.name}
                </TabItem>
              ))
            )}
          </TabContainer>
          <TabComponentWrapper>
            {activeSubTab === SETTINGS_TABS[0].value && <EditProfile />}
            {activeSubTab === SETTINGS_TABS[1].value && <UpdatePassword />}
            {activeSubTab === SETTINGS_TABS[2].value && <Credentials />}
          </TabComponentWrapper>
        </PageContent>
    </AppLayout>
  )
}

export default memo(SettingsOutlet)
