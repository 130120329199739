import axios from 'axios'

import { getAccessToken } from './auth.service'

//types
import { WorkspaceRoles, IWorkspaceResponse } from 'types/common'

const BASE_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_AUTHENTICATION_PATH}`

export interface DefaultResponse {
  success: boolean
  message?: string
}

interface CreateWorkspace extends DefaultResponse {
  data: {
    _id: string
    name: string
    owner: string
    members: {
      user: string
      role: WorkspaceRoles
    }[]
    credits: number
    createdBy: string
    createdAt: string
    updatedAt: string
  }
}

export const createWorkspace = async (
  name: string
): Promise<CreateWorkspace> => {
  const result = await axios.post(
    `${BASE_URL}/v1/workspaces`,
    {
      name: name,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  )
  return result.data
}

interface GetWorkspace extends DefaultResponse {
  data: IWorkspaceResponse[]
}

interface JoinWorkspaceResponse extends DefaultResponse {
  data: {
    workspaceId: string
  }
}

export const getWorkspaces = async (): Promise<GetWorkspace> => {
  const result = await axios.get(`${BASE_URL}/v1/workspaces/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })
  return result.data
}

export const inviteMemberToWorkspace = async (
  workspaceId: string,
  emails: string[],
  role: string
): Promise<CreateWorkspace> => {
  const result = await axios.post(
    `${BASE_URL}/v1/workspaces/${workspaceId}/invite`,
    {
      invitedUserEmails: [...emails],
      role,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  )
  return result.data
}

export const updateMemberRoleInWorkspace = async (
  workspaceId: string,
  memberId: string,
  role: string
): Promise<DefaultResponse> => {
  const result = await axios.patch(
    `${BASE_URL}/v1/workspaces/${workspaceId}/members/${memberId}/role/`,
    {
      role,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  )
  return result.data
}

export const joinWorkspace = async (
  token: string
): Promise<JoinWorkspaceResponse> => {
  const result = await axios.post<JoinWorkspaceResponse>(
    `${BASE_URL}/v1/workspaces/join`,
    {
      token,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  )
  return result.data
}

export const leaveWorkspace = async (
  workspaceId: string
): Promise<DefaultResponse> => {
  const result = await axios.post(
    `${BASE_URL}/v1/workspaces/${workspaceId}/leave`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  )
  return result.data
}

export const removeMemberFromWorkspace = async (
  workspaceId: string,
  memberId: string
): Promise<DefaultResponse> => {
  const result = await axios.post(
    `${BASE_URL}/v1/workspaces/${workspaceId}/remove`,
    {
      memberId,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  )
  return result.data
}
