import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const jumpingAnimation = keyframes`
  0% {
      opacity: .2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: .1;
    }
`
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

const Container1 = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
`

const Dot = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;

  &:nth-of-type(1) {
    animation: ${jumpingAnimation} 0.6s 0.1s ease-in infinite;
  }
  &:nth-of-type(2) {
    animation: ${jumpingAnimation} 0.6s 0.2s ease-in infinite;
  }
  &:nth-of-type(3) {
    animation: ${jumpingAnimation} 0.6s 0.3s ease-in infinite;
  }
`

const Loader = () => {
  return (
    <Container>
      <Wrapper>
        <Dot />
        <Dot />
        <Dot />
      </Wrapper>
    </Container>
  )
}

export const ComponentLoader = () => {
  return (
    <Container1>
      <Wrapper>
        <Dot />
        <Dot />
        <Dot />
      </Wrapper>
    </Container1>
  )
}

export default Loader
