import { Highlight, themes } from 'prism-react-renderer'

interface Props {
  codeSnippet: string
  language: string
  showLineNumbers?: boolean
}

const CodeHighlight = ({ codeSnippet, language, showLineNumbers }: Props) => {
  return (
    <div>
      <Highlight
        theme={themes.shadesOfPurple}
        code={codeSnippet}
        language={language}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre
            style={{
              ...style,
              borderRadius: '8px',
              padding: '12px',
              whiteSpace: 'pre-line',
            }}
          >
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line })}>
                {showLineNumbers && <span>{i + 1}</span>}
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  )
}

export default CodeHighlight
