import React from 'react'

declare module '@mui/material/styles' {
  interface Palette {
    accent: {
      a1: React.CSSProperties['color']
      a2: React.CSSProperties['color']
      a3: React.CSSProperties['color']
      a4: React.CSSProperties['color']
      a5: React.CSSProperties['color']
    }
    neutral: {
      'n1-100': React.CSSProperties['color']
      'n2-100': React.CSSProperties['color']
      'n3-100': React.CSSProperties['color']
      'n4-100': React.CSSProperties['color']
      'n5-100': React.CSSProperties['color']
      'n6-100': React.CSSProperties['color']
      'n7-100': React.CSSProperties['color']
      'n1-75': React.CSSProperties['color']
      'n2-75': React.CSSProperties['color']
      'n3-75': React.CSSProperties['color']
      'n4-75': React.CSSProperties['color']
      'n5-75': React.CSSProperties['color']
      'n6-75': React.CSSProperties['color']
      'n7-75': React.CSSProperties['color']
      'n1-50': React.CSSProperties['color']
      'n2-50': React.CSSProperties['color']
      'n3-50': React.CSSProperties['color']
      'n4-50': React.CSSProperties['color']
      'n5-50': React.CSSProperties['color']
      'n6-50': React.CSSProperties['color']
      'n7-50': React.CSSProperties['color']
      'n1-25': React.CSSProperties['color']
      'n2-25': React.CSSProperties['color']
      'n3-25': React.CSSProperties['color']
      'n4-25': React.CSSProperties['color']
      'n5-25': React.CSSProperties['color']
      'n6-25': React.CSSProperties['color']
      'n7-25': React.CSSProperties['color']
      'n1-10': React.CSSProperties['color']
    }
    chatbot: {
      'bg-query': React.CSSProperties['color']
      'bg-response': React.CSSProperties['color']
      query: React.CSSProperties['color']
      response: React.CSSProperties['color']
    }
  }

  interface PaletteOptions {
    accent: {
      a1: React.CSSProperties['color']
      a2: React.CSSProperties['color']
      a3: React.CSSProperties['color']
      a4: React.CSSProperties['color']
      a5: React.CSSProperties['color']
    }
    neutral: {
      'n1-100': React.CSSProperties['color']
      'n2-100': React.CSSProperties['color']
      'n3-100': React.CSSProperties['color']
      'n4-100': React.CSSProperties['color']
      'n5-100': React.CSSProperties['color']
      'n6-100': React.CSSProperties['color']
      'n7-100': React.CSSProperties['color']
      'n1-75': React.CSSProperties['color']
      'n2-75': React.CSSProperties['color']
      'n3-75': React.CSSProperties['color']
      'n4-75': React.CSSProperties['color']
      'n5-75': React.CSSProperties['color']
      'n6-75': React.CSSProperties['color']
      'n7-75': React.CSSProperties['color']
      'n1-50': React.CSSProperties['color']
      'n2-50': React.CSSProperties['color']
      'n3-50': React.CSSProperties['color']
      'n4-50': React.CSSProperties['color']
      'n5-50': React.CSSProperties['color']
      'n6-50': React.CSSProperties['color']
      'n7-50': React.CSSProperties['color']
      'n1-25': React.CSSProperties['color']
      'n2-25': React.CSSProperties['color']
      'n3-25': React.CSSProperties['color']
      'n4-25': React.CSSProperties['color']
      'n5-25': React.CSSProperties['color']
      'n6-25': React.CSSProperties['color']
      'n7-25': React.CSSProperties['color']
      'n1-10': React.CSSProperties['color']
    }
    chatbot: {
      'bg-query': React.CSSProperties['color']
      'bg-response': React.CSSProperties['color']
      query: React.CSSProperties['color']
      response: React.CSSProperties['color']
    }
  }
}

const palette = {
  primary: {
    main: '#368FF9', // '#5142FB' secondary, //#8E55EA original
    // dark: '',
    // light: '',
  },
  secondary: {
    main: '#0084FF',
    // dark: '',
    // light: '',
  },
  background: {
    default: '#fff',
    paper: '#f5f5f5',
    hover: '#cecece',
  },
  accent: {
    a1: '#D84C10',
    a2: '#3FDD78',
    a3: '#3FDD78',
    a4: '#8C6584',
    a5: '#DDA73F',
  },
  neutral: {
    'n1-100': '#FEFEFE',
    'n2-100': '#F3F5F7',
    'n3-100': '#E8ECEF',
    'n4-100': '#6C7275',
    'n5-100': '#343839',
    'n6-100': '#232627',
    'n7-100': '#141718',
    /*75% value*/
    'n1-75': 'rgba(232, 236, 239, 0.75)',
    'n2-75': 'rgba(232, 236, 239, 0.75)',
    'n3-75': 'rgba(232, 236, 239, 0.75)',
    'n4-75': 'rgba(232, 236, 239, 0.75)',
    'n5-75': 'rgba(232, 236, 239, 0.75)',
    'n6-75': 'rgba(232, 236, 239, 0.75)',
    'n7-75': 'rgba(232, 236, 239, 0.75)',

    /*50% value*/
    'n1-50': '#FEFEFE',
    'n2-50': '#F3F5F7',
    'n3-50': 'rgba(232, 236, 239, 0.5)',
    'n4-50': 'rgba(108, 114, 117, 0.5)',
    'n5-50': 'rgba(52, 56, 57, 0.5);',
    'n6-50': 'rgba(35, 38, 39, 0.5)',
    'n7-50': '#141718',

    /*25% value*/
    'n1-25': '#FEFEFE',
    'n2-25': '#F3F5F7',
    'n3-25': 'rgba(232, 236, 239, 0.25)',
    'n4-25': 'rgba(108, 114, 117, 0.25)',
    'n5-25': '#343839',
    'n6-25': '#232627',
    'n7-25': '#141718',

    /*10% value*/
    'n1-10': 'rgba(254, 254, 254, 0.1)',
  },
  success: {
    main: '#62c370',
  },
  error: {
    main: '#ee2e31',
  },
  warning: {
    main: '#f1d302',
  },
  chatbot: {
    'bg-query': '#4483ff',
    'bg-response': '#f0f0f0',
    query: '#ffffff',
    response: '#141718',
    //'bg-query': '#F3F5F7',
    //'bg-response': '#F9E4CB',
    //query: '#141718',
    //response: '#141718',
  },
}

export default palette
