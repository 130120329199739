import { styled, useTheme } from '@mui/material'
import dayjs from 'dayjs'

//components
import Typography from '@mui/material/Typography'
import { getColor } from 'components/StatusPill/StatusPill'

//types
import { Status } from 'types/common'
import { ChevronRight } from '@mui/icons-material'

const Card = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '300px',
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  cursor: 'pointer',
  borderRadius: '12px',
  backgroundColor: theme.palette.neutral['n5-100'],
  border: `1px solid ${theme.palette.neutral['n4-50']}`,
}))

const CardFooter = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}))

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

interface Props {
  title: string
  status: Status
  createdTime: number
  onClickHandler: () => void
}

const ResourceCard = ({
  title,
  status,
  createdTime,
  onClickHandler,
}: Props) => {
  const theme = useTheme()

  return (
    <Card onClick={onClickHandler}>
      <TitleContainer>
        <Typography
          variant="base1"
          sx={{
            textTransform: 'capitalize',
            maxWidth: '225px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>
        <ChevronRight color="inherit" />
      </TitleContainer>
      <CardFooter>
        <Typography variant="base2" sx={{ fontWeight: 400 }}>
          {dayjs(dayjs.unix(createdTime)).format('MMM D, h:mm A')}
        </Typography>

        <Typography
          variant="base2"
          sx={{
            color: `${getColor(status, theme.palette)}`,
          }}
        >
          {Status[status]}
        </Typography>
      </CardFooter>
    </Card>
  )
}

export default ResourceCard
