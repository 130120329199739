import { useEffect, useState } from 'react'
import { Link, styled } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

//components
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'

const defaultBorderRadius = 4

const Paragraph = styled('div')(({ theme }) => ({
  userSelect: 'none',
  fontSize: '16px',
  marginBottom: '16px',
  '@media (max-width: 768px)': {
    fontSize: '14px',
  },
}))

const Container = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '54px',
  border: '1px solid #e2e2e2',
  borderRadius: `${defaultBorderRadius}px`,
  textAlign: 'center',
  '@media (max-width: 768px)': {
    width: '80%',
    padding: '16px',
  },
}))

export const LogoText = styled('p')(({ theme }) => ({
  userSelect: 'none',
  fontWeight: '500',
  fontSize: '24px',
  marginBottom: '32px',
}))

const AuthFailure = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [errorType, setErrorType] = useState('Authentication error')
  const [errorMessage, setErrorMessage] = useState(STANDARD_ERROR_MESSAGE)

  useEffect(() => {
    const handleUrlParams = async () => {
      const message = searchParams.get('error_message')
      if (message) {
        setErrorMessage(message)
      }
    }
    handleUrlParams()
  }, [])

  return (
    <Container>
        <LogoText>xChat</LogoText>
        <Paragraph>
          <h2>Authentication failed</h2>
          <br />
          {`${errorType}: ${errorMessage}`}
        </Paragraph>
        <p style={{ marginTop: '16px' }}>
          Having trouble signup or login?
          <Link
            sx={{ marginLeft: '4px' }}
            href={`https://tally.so#tally-open=wvMGyQ&user_id=${''}&tally-emoji-text=✉️&tally-emoji-animation=wave`}
            variant="body2"
            underline="always"
          >
            Get in touch with us
          </Link>
        </p>
    </Container>
  )
}

export default AuthFailure
