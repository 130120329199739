import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

//components
import Typography from '@mui/material/Typography'
import StyledInput from 'components/StyledInput/StyledInput'
import StyledToast from 'components/StyledToast/StyledToast'
import StyledButton from 'components/StyledButton/StyledButton'

//redux
import { selectUserInfo, setUserInfo } from 'redux/userSlice'

//service
import { updateProfileData } from 'services/user.service'

//assets
import PasswordIcon from 'assets/icons/password.png'
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'
import handleServiceError from 'utils/handleServiceError'

const FormWrapper = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
}))

interface FormValues {
  password: string
  newPassword: string
  confirmPassword: string
}

const UpdatePassword = () => {
  const theme = useTheme()

  //redux
  const userInfo = useSelector(selectUserInfo)

  //local
  const [isLoading, setIsLoading] = useState(false)

  const initialValues: FormValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
  }

  async function updatePasswordHandler(
    formObject: FormValues,
    actions: FormikHelpers<FormValues>
  ) {
    setIsLoading(true)

    try {
      await updateProfileData(userInfo.userId, {
        oldPassword: formObject.password,
        newPassword: formObject.newPassword,
      })

      actions.setSubmitting(false)
      actions.resetForm()
        toast(
          <StyledToast variant="success" title="Update password successful" />
        )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount={false}
        validationSchema={Yup.object().shape({
          password: Yup.string().required('Please enter your password'),
          newPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .max(255)
            .matches(
              /^(?=.*[a-z])/,
              `Password should contain one lower case letter`
            )
            .matches(
              /^(?=.*[A-Z])/,
              'Password should contain one upper case letters'
            )
            .matches(/^(?=.*[0-9])/, 'Password should contain one number'),

          confirmPassword: Yup.string()
            .min(8)
            .max(255)
            .required('Please confirm your password')
            .oneOf([Yup.ref('newPassword')], 'Password do not match'),
        })}
        onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
          //handler function
          updatePasswordHandler(values, actions)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <StyledInput
              inputType="password"
              autoFocus={false}
              id="password"
              label="Current password"
              placeholder="Password"
              iconImageSrc={PasswordIcon}
              value={values.password}
              isRequired={true}
              fullWidth={true}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={Boolean(touched.password && errors.password)}
              errorMessage={touched.password && errors.password}
              autoComplete="on"
            />
            <StyledInput
              inputType="password"
              autoFocus={false}
              id="newPassword" //NOTE: Id and propertyName should match
              label="New password"
              placeholder="New password"
              iconImageSrc={PasswordIcon}
              value={values.newPassword}
              isRequired={true}
              fullWidth={true}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={Boolean(touched.newPassword && errors.newPassword)}
              errorMessage={touched.newPassword && errors.newPassword}
            />
            <StyledInput
              inputType="password"
              autoFocus={false}
              id="confirmPassword"
              label="Confirm new password"
              placeholder="Confirm new password"
              iconImageSrc={PasswordIcon}
              value={values.confirmPassword}
              isRequired={true}
              fullWidth={true}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={Boolean(
                touched.confirmPassword && errors.confirmPassword
              )}
              errorMessage={touched.confirmPassword && errors.confirmPassword}
            />
            <StyledButton
              style={{ alignSelf: 'flex-start' }}
              type="submit"
              title={isLoading ? 'Updating...' : 'Change password'}
              variant="primary"
              textVariant="base1"
              disabled={isLoading || isSubmitting}
            />
          </FormWrapper>
        )}
      </Formik>
    </>
  )
}

export default UpdatePassword
