import React from 'react'
import { styled } from '@mui/material'

const DivRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  marginTop: '16px',
}))

const DivMainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: '20px',
  alignItems: 'center',
  color: theme.palette.neutral['n1-100'],
  fontSize: '14px',
}))

const DivBtnContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: '15px',
  columnGap: '10px',
}))

const Btn = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px 8px',
  borderRadius: '5px',
  outline: 'none',
  cursor: 'pointer',
  border: `2px solid ${theme.palette.neutral['n4-50']}`,
  color: theme.palette.neutral['n2-50'],
  backgroundColor: 'transparent',
  opacity: 0.8,
  '&:hover': {
    opacity: 1,
  },
  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}))

const Pagination = ({
  pageIndex,
  pageOptions,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
}) => {
  return (
    <DivRoot>
      <DivMainContainer>
        <div>
          <span>
            Page
            <span style={{ marginLeft: '5px' }}>
              {pageIndex + 1} of {pageOptions.length}
            </span>
          </span>
        </div>
        <DivBtnContainer>
          <Btn onClick={() => previousPage()} disabled={!canPreviousPage}>
            Prev
          </Btn>
          <Btn onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </Btn>
        </DivBtnContainer>
      </DivMainContainer>
    </DivRoot>
  )
}

export default Pagination
