import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

//components
import Typography from '@mui/material/Typography'
import PageHeader from 'components/PageHeader/PageHeader'
import { ComponentLoader } from 'components/Loader/Loader'
import StyledButton from 'components/StyledButton/StyledButton'
import StyledToast from 'components/StyledToast/StyledToast'
import CreateNotebookModal from './components/CreateNotebookModal/CreateNotebookModal'
import ResourceCard from 'components/ResourceCard/ResourceCard'

//service
import * as notebookService from 'services/notebook.service'

//redux
import { selectCurrentWorkspace } from 'redux/userSlice'

//types
import { INotebook } from 'types/notebook'

//utils
import useFetchData from 'hooks/useFetchData'
import pollAndSync from 'utils/pollAndSync'
import ActionsWithRefresh from 'components/ActionsWithRefresh'
import { MachinesPricing, Status } from 'types/common'
import useFilteredData from 'hooks/useFilteredData'
import useCheckWorkspaceActivation from 'hooks/useCheckWorkspaceActivation'
import InactiveWorkspace from 'components/InactiveWorkspace'
import handleServiceError from 'utils/handleServiceError'
import { getHourlyMachinePricing } from 'services/usageQuota.service'

const CardsWrapper = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '24px 40px',
  gap: '32px',
  alignContent: 'flex-start',
}))

interface NotebookPollConfig {
  id: string
  workspaceId: string
  notebookName: string
  status: Status
}

const Notebooks = () => {
  const navigate = useNavigate()

  //redux
  const currentWorkSpace = useSelector(selectCurrentWorkspace)

  //local

  const [isLoading, setIsLoading] = useState(false)
  const [notebooks, setNotebooks] = useState<INotebook[]>([])

  const [isCreateNotebookModalOpen, setIsCreateNotebookModalOpen] =
    useState(false)
  const [machinesPricing, setMachinesPricing] =
    useState<MachinesPricing | null>(null)
  const [fetchingPricing, setFetchingPricing] = useState(false)
  const { loading: refreshing, fetch } = useFetchData()
  const { loading: checkingWorkspaceStatus, isActivated } =
    useCheckWorkspaceActivation()
  const cancelFns: { [key: string]: () => void } = useRef({}).current

  useEffect(() => {
    if (!currentWorkSpace) return
    setFetchingPricing(true)
    getHourlyMachinePricing(currentWorkSpace.id)
      .then(setMachinesPricing)
      .catch(console.error)
      .finally(() => setFetchingPricing(false))
  }, [currentWorkSpace])

  useEffect(() => {
    if (!currentWorkSpace) {
      return
    }

    const fetchData = async () => {
      try {
        setIsLoading(true)
        const notebooks = await notebookService.getNotebooks(
          currentWorkSpace.id
        )
        setNotebooks(notebooks ?? [])
      } catch (err) {
        console.log(err)
        setNotebooks([])
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [currentWorkSpace])

  useFilteredData(
    notebooks,
    [] as ReadonlyArray<NotebookPollConfig>,
    (prev) => {
      return notebooks
        .filter((nb) => nb.status <= Status.Deploying)
        .filter((nb) => !prev.find((prevNb) => prevNb.id === nb._id))
        .map((nb) => ({
          id: nb._id,
          workspaceId: nb.workspace_id,
          notebookName: nb.notebook_name,
          status: nb.status,
        }))
    },
    (syncableNbs) => {
      syncableNbs.forEach((nb) => {
        cancelFns[nb.id] = pollAndSync(
          () =>
            notebookService.getNotebookByName(nb.workspaceId, nb.notebookName),
          undefined,
          (data) => {
            if (data) {
              setNotebooks((prev) => {
                const index = prev.findIndex(
                  (notebook) => notebook._id === data._id
                )
                if (index !== -1) {
                  prev[index] = data
                }
                return [...prev]
              })
            }
          },
          (data) => (data ? data.status >= Status.Running : false)
        )
      })
    }
  )

  // cancel polling
  useEffect(() => {
    return () => {
      Object.values(cancelFns).forEach((cancel) => cancel())
    }
  }, [cancelFns])

  const createNotebookHandler = async (
    name: string,
    machineType: string,
    spot: boolean,
    location: string
  ) => {
    if (!currentWorkSpace) {
      return
    }
    try {
      setIsLoading(true)
      const notebook = await notebookService.createNotebook(
        currentWorkSpace.id,
        {
          name,
          machineType,
          spot,
          location,
        }
      )
      setNotebooks((prev) => [...prev, notebook])
      toast(
        <StyledToast
          variant="success"
          title={`'${name}' notebook created successfully.`}
        />
      )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsCreateNotebookModalOpen(false)
    }
  }

  const handleRefresh = () => {
    if (!currentWorkSpace) return
    fetch(
      notebookService.getNotebooks(currentWorkSpace.id),
      (res) => res ?? [],
      (data) => {
        if (data) {
          setNotebooks(data)
        }
      }
    )
  }

  return (
    <>
      <PageHeader
        title="Notebooks"
        path="/notebooks"
        loading={refreshing || checkingWorkspaceStatus || fetchingPricing}
        actions={
          <ActionsWithRefresh
            disabled={checkingWorkspaceStatus || !isActivated}
            onRefresh={handleRefresh}
          >
            <StyledButton
              style={{ alignSelf: 'stretch' }}
              type="submit"
              title={false ? 'Creating...' : 'Create notebook'}
              variant="primary"
              textVariant="base1"
              disabled={
                checkingWorkspaceStatus || !isActivated || fetchingPricing
              }
              onClickHandler={() => setIsCreateNotebookModalOpen(true)}
            />
          </ActionsWithRefresh>
        }
      />
      {checkingWorkspaceStatus || isLoading ? (
        <ComponentLoader />
      ) : !isActivated ? (
        <InactiveWorkspace />
      ) : (
        <CardsWrapper>
          <>
            {notebooks.length === 0 && (
              <Typography variant="base1">
                You have not created any notebooks yet.
              </Typography>
            )}
            {notebooks.length > 0 &&
              React.Children.toArray(
                notebooks.map((notebook) => (
                  <ResourceCard
                    title={notebook.notebook_name}
                    status={notebook.status}
                    createdTime={notebook.metadata.started_at}
                    onClickHandler={() =>
                      navigate(`/notebooks/${notebook.notebook_name}`)
                    }
                  />
                ))
              )}
          </>
        </CardsWrapper>
      )}
      {isCreateNotebookModalOpen && machinesPricing !== null && (
        <CreateNotebookModal
          machinesPricing={machinesPricing}
          isOpen={isCreateNotebookModalOpen}
          loading={isLoading}
          submitActionHandler={createNotebookHandler}
          closeHandler={() => setIsCreateNotebookModalOpen(false)}
        />
      )}
    </>
  )
}

export default Notebooks
