import { memo } from 'react'
import { styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

//component
import { ComponentLoader } from 'components/Loader/Loader'

//redux
import { selectIsAppLoading } from 'redux/appSlice'
import { selectIsLoggedIn } from 'redux/userSlice'

//utils
import { SIDE_PANEL_WIDTH } from 'utils/constants'

const Main = styled('main')<{ overflow?: 'hidden' | 'auto' }>(
  ({ theme, overflow }) => ({
    width: `calc(100% - ${SIDE_PANEL_WIDTH}px)`,
    height: '100%',
    display: 'flex',
    backgroundColor: theme.palette.neutral['n7-100'],
    padding: '24px 24px 24px 0px',
    ...(overflow && { overflow: overflow }),
  })
)

const PageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  backgroundColor: theme.palette.neutral['n6-100'],
}))

const AppLayout = ({
  children,
  overflow,
}: {
  children: React.ReactNode
  overflow?: 'hidden' | 'auto'
}) => {
  //redux
  const isAppLoading = useSelector(selectIsAppLoading)
  const isLoggedIn = useSelector(selectIsLoggedIn)

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />
  }

  return (
    <Main overflow={overflow}>
      <PageContainer>
        {children}
        {/* {!isAppLoading ? children : <ComponentLoader />} */}
      </PageContainer>
    </Main>
  )
}

export default memo(AppLayout)
