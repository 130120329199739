import { useState, useEffect } from 'react'
import { styled, useTheme } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

//components
import PageHeader from 'components/PageHeader/PageHeader'
import { ComponentLoader } from 'components/Loader/Loader'
import Typography from '@mui/material/Typography'
import StyledToast from 'components/StyledToast/StyledToast'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'
import CodeHighlight from 'components/CodeHighlight/CodeHighlight'
import { getColor } from 'components/StatusPill/StatusPill'

//assets
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

//service
import * as notebookService from 'services/notebook.service'

//redux
import { selectCurrentWorkspace } from 'redux/userSlice'

//types
import { INotebook } from 'types/notebook'
import CopyTextField from 'components/CopyTextField/CopyTextFiled'
import StyledButton from 'components/StyledButton/StyledButton'

//utils
import { Status } from 'types/common'
import pollAndSync from 'utils/pollAndSync'
import ActionsWithRefresh from 'components/ActionsWithRefresh'
import useFetchData from 'hooks/useFetchData'
import handleServiceError from 'utils/handleServiceError'

const PageContent = styled('div')(() => ({
  flex: '1 1 auto',
  width: '100% ',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 40px',
  gap: '24px',
}))

const DetailsContainer1 = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '24px',
}))

const DetailsContainer2 = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
}))

const ParametersContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: '16px',
  borderRadius: '8px',
}))

const Parameter = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
}))

const DeleteIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '8px',
  borderRadius: '8px',
  backgroundColor: theme.palette.neutral['n4-25'],
  cursor: 'pointer',
}))

const Notebook = () => {
  //hooks
  const theme = useTheme()
  const navigate = useNavigate()
  const { name: notebookName } = useParams()

  //redux
  const currentWorkSpace = useSelector(selectCurrentWorkspace)

  //local
  const [isLoading, setIsLoading] = useState(false)
  const [notebook, setNotebook] = useState<INotebook | null>(null)
  const [isDeleteNotebookModalOpen, setIsDeleteNotebookModalOpen] =
    useState(false)

  const { fetch, loading: refreshing } = useFetchData()

  useEffect(() => {
    if (!currentWorkSpace) {
      return
    }

    setIsLoading(true)
    const cancelPolling = pollAndSync(
      () =>
        notebookService.getNotebookByName(currentWorkSpace.id, notebookName),
      undefined,
      (data) => {
        // dont update state if data is null
        if (data) setNotebook(data)
      },
      (data) => (data ? data.status >= Status.Running : false),
      (err) => console.error(err),
      () => setIsLoading(false)
    )

    return () => {
      cancelPolling()
    }
  }, [currentWorkSpace, notebookName])

  const deleteNotebookHandler = async () => {
    if (!currentWorkSpace || !notebook?.notebook_name) {
      return
    }
    try {
      setIsLoading(true)
      await notebookService.deleteNotebook(
        currentWorkSpace.id,
        notebook.notebook_name
      )
      toast(
        <StyledToast
          variant="success"
          title={`'${notebook?.notebook_name}' notebook was deleted successfully`}
        />
      )
      navigate('/notebooks')
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsDeleteNotebookModalOpen(false)
    }
  }

  let startedDate = 'NA'
  let endDate = 'NA'
  if (notebook) {
    if (notebook.metadata.started_at) {
      startedDate = dayjs(dayjs.unix(notebook.metadata.started_at)).format(
        'MMM D, h:mm A'
      )
    }
    if (notebook.metadata.ended_at) {
      endDate = dayjs(dayjs.unix(notebook.metadata.ended_at)).format(
        'MMM D, h:mm A'
      )
    }
  }

  const parameters = [
    {
      parameter: 'Notebook name',
      value: notebook?.notebook_name || 'NA',
    },
    {
      parameter: 'Started at',
      value: startedDate,
    },
    {
      parameter: 'Ended at',
      value: endDate,
    },
    // {
    //   parameter: 'Status',
    //   value: notebook?.status || 'NA',
    // },
    {
      parameter: ' Machine type',
      value: notebook?.container_specs.machine_type || 'NA',
    },
    {
      parameter: 'Spot',
      value: notebook?.container_specs.spot.toString() || 'NA',
    },
  ]

  let executionCommand = ''

  if (notebook?.container_specs.command) {
    executionCommand = `${notebook?.container_specs.command?.join(' ')}`
  }

  if (notebook?.container_specs.args) {
    executionCommand += ` ${notebook?.container_specs.args?.join(' ')}`
  }

  let executionEnvVars = ``
  if (notebook?.container_specs.env) {
    const envArray = []
    const envObj = notebook.container_specs.env
    for (const key in envObj) {
      envArray.push(`${key}: "${envObj[key]}"`)
    }
    executionEnvVars = envArray.join('\n')
  }

  const handleRefresh = () => {
    if (!currentWorkSpace) return
    fetch(
      notebookService.getNotebookByName(currentWorkSpace.id, notebookName),
      undefined,
      (data) => {
        if (data) setNotebook(data)
      }
    )
  }

  return (
    <>
      <PageHeader
        title="Notebook"
        subTitle={notebookName}
        path="/notebooks"
        loading={refreshing}
        actions={
          <ActionsWithRefresh variant="icon" onRefresh={handleRefresh}>
            <DeleteIconContainer
              onClick={() => setIsDeleteNotebookModalOpen(true)}
            >
              <DeleteOutlineIcon
                fontSize="small"
                sx={{ color: theme.palette.neutral['n3-100'] }}
              />
            </DeleteIconContainer>
          </ActionsWithRefresh>
        }
      />
      <PageContent>
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <>
            <DetailsContainer1>
              {parameters.map((item) => (
                <Parameter>
                  <Typography variant="base1" sx={{ fontWeight: 400 }}>
                    {item.parameter}
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.neutral['n3-50'],
                      fontWeight: 400,
                      maxWidth: '150px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    variant="base2"
                  >
                    {item.value}
                  </Typography>
                </Parameter>
              ))}
              <Parameter>
                <Typography variant="base1" sx={{ fontWeight: 400 }}>
                  Status
                </Typography>
                <Typography
                  variant="base2"
                  sx={{
                    textTransform: 'capitalize',
                    color: `${getColor(
                      notebook?.status || Status.Default,
                      theme.palette
                    )}`,
                  }}
                >
                  {notebook ? Status[notebook.status] : 'NA'}
                </Typography>
              </Parameter>
            </DetailsContainer1>
            <DetailsContainer2>
              <ParametersContainer>
                <CopyTextField
                  label="Image"
                  value={notebook?.container_specs.image || 'N/A'}
                />
                <CopyTextField
                  label="Base URL"
                  value={notebook?.access_details.base_url || 'N/A'}
                />
                <CopyTextField
                  label="Token"
                  value={notebook?.access_details.token || 'N/A'}
                />
                <CopyTextField
                  label="Notebook URL"
                  value={notebook?.access_details.full_url || 'N/A'}
                />
                <StyledButton
                  style={{
                    alignSelf: 'flex-start',
                  }}
                  startIcon={<OpenInNewIcon />}
                  type="button"
                  title="Open notebook"
                  variant="primary"
                  textVariant="base1"
                  disabled={notebook?.status !== Status.Running}
                  onClickHandler={() => {
                    window.open(notebook?.access_details.full_url, '_blank')
                  }}
                />
              </ParametersContainer>
            </DetailsContainer2>
          </>
        )}
      </PageContent>
      {isDeleteNotebookModalOpen && (
        <ConfirmationModal
          title="Delete notebook"
          isOpen={isDeleteNotebookModalOpen}
          confirmMessage={`You won't be able to undo this action. Are you sure you want to delete '${notebook?.notebook_name}' ?`}
          confirmBtnTitle={isLoading ? 'Deleting...' : 'Delete'}
          confirmHandler={deleteNotebookHandler}
          closeHandler={() => setIsDeleteNotebookModalOpen(false)}
          loading={isLoading}
        />
      )}
    </>
  )
}

export default Notebook
