import { useState, useEffect, memo } from 'react'
import { styled, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

//layout
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import AppLayout from 'layouts/AppLayout'
import PageHeader from 'components/PageHeader/PageHeader'
import { ComponentLoader } from 'components/Loader/Loader'
import StyledButton from 'components/StyledButton/StyledButton'
import StyledToast from 'components/StyledToast/StyledToast'
import InviteOrEditMemberModal from './components/InviteOrEditMemberModal/InviteOrEditMemberModal'
import StyledTable from 'components/StyledTables/StyledTable'
import {
  tableEntry,
  tableHeader,
} from 'components/StyledTables/TableComponents'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'

//redux
import {
  selectCurrentWorkspace,
  selectUserInfo,
  updateMemberInCurrentWorkspace,
  removeWorkspace,
  updateWorkspaces,
  removeMemberFromCurrentWorkspace,
} from 'redux/userSlice'

//service
import * as workspaceService from 'services/workspace.service'

//types
import { WorkspaceRoles, IMember } from 'types/common'

//utils
import { capitalizeFLetter } from 'utils/utils'
import handleServiceError from 'utils/handleServiceError'
import ActionsWithRefresh from 'components/ActionsWithRefresh'
import useFetchData from 'hooks/useFetchData'

const isUserOwnerOrAdmin = (memberId: string, members: IMember[]) => {
  let isAuthorized = false
  const member = members.find(({ id }) => memberId === id)

  if (!member) {
    return isAuthorized
  }

  if (member.role === 'owner' || member.role === 'admin') {
    isAuthorized = true
    return isAuthorized
  }

  return isAuthorized
}

const isUserWorkspaceOwner = (memberId: string, members: IMember[]) => {
  let isOwner = false
  const member = members.find(({ id }) => memberId === id)

  if (!member) {
    return isOwner
  }

  if (member.role === 'owner') {
    isOwner = true
    return isOwner
  }

  return isOwner
}

const Tag = styled('div')(({ theme }) => ({
  display: 'inline',
  fontWeight: 'bold',
  fontSize: '12px',
  lineHeight: '13px',
  background: 'greenyellow',
  color: 'green',
  padding: '2px 4px 1px',
  borderRadius: '3px',
  whiteSpace: 'nowrap',
  marginLeft: '8px',
}))

const PageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  backgroundColor: theme.palette.neutral['n6-100'],
}))

const PageContent = styled('div')(() => ({
  flex: '1 1 auto',
  width: '100% ',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 32px',
}))

const TableContainer = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  overflowX: 'scroll',
  borderRadius: '0 0 20px 20px',
  color: 'black !important',
  backgroundColor: theme.palette.neutral['n6-100'],
  '&::-webkit-scrollbar': {
    height: '8px',
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    height: '4px',
    backgroundColor: theme.palette.neutral['n4-50'],
  },
}))

const UpdateIconContainer = styled('button')(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  padding: '6px',
  borderRadius: '8px',
  backgroundColor: theme.palette.neutral['n4-25'],
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'not-allowed',
  },
}))

const AdminOutlet = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  //redux
  const userInfo = useSelector(selectUserInfo)
  const currentWorkspace = useSelector(selectCurrentWorkspace)

  //local
  const [isLoading, setIsLoading] = useState(false)
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false)
  const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false)
  const [isLeaveWorkspaceModalOpen, setIsLeaveWorkspaceModalOpen] =
    useState(false)
  const [isRemoveMemberModalOpen, setIsRemoveMemberModalOpen] = useState(false)
  const [currentMember, setCurrentMember] = useState({
    id: '',
    email: '',
    role: '',
  })

  const { fetch, loading: refreshing } = useFetchData()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await workspaceService.getWorkspaces()
        dispatch(
          updateWorkspaces(
            response.data.map((item) => ({
              id: item.workspace._id,
              name: item.workspace.name,
              owner: item.workspace.owner._id,
              members: item.workspace.members.map((member) => ({
                id: member.user._id,
                name: member.user.name,
                email: member.user.email,
                role: member.role,
              })),
            }))
          )
        )
      } catch (error) {
        console.log('FETCH_WORKSPACE_ERROR', { error })
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [currentWorkspace])

  let isOwner = false
  let isAuthorized = false

  if (currentWorkspace) {
    isAuthorized = isUserOwnerOrAdmin(
      userInfo.userId,
      currentWorkspace?.members
    )
    isOwner = isUserWorkspaceOwner(userInfo.userId, currentWorkspace?.members)
  }

  const inviteMemberHandler = async (email: string, role: string) => {
    if (!currentWorkspace) {
      return
    }
    try {
      setIsLoading(true)
      await workspaceService.inviteMemberToWorkspace(
        currentWorkspace.id,
        [email],
        role
      )
      toast(
        <StyledToast variant="success" title={`Invite sent successfully`} />
      )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsInviteMemberModalOpen(false)
    }
  }

  const updateMemberRoleHandler = async (email: string, role: string) => {
    if (!currentWorkspace) {
      return
    }
    try {
      setIsLoading(true)
      await workspaceService.updateMemberRoleInWorkspace(
        currentWorkspace.id,
        currentMember.id,
        role
      )
      dispatch(
        updateMemberInCurrentWorkspace({
          memberId: currentMember.id,
          role: role as WorkspaceRoles,
        })
      )
      toast(
        <StyledToast variant="success" title={`Updated role successfully`} />
      )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsEditMemberModalOpen(false)
    }
  }

  const removeMemberHandler = async () => {
    if (!currentWorkspace) {
      return
    }
    try {
      setIsLoading(true)
      await workspaceService.removeMemberFromWorkspace(
        currentWorkspace.id,
        currentMember.id
      )
      dispatch(removeMemberFromCurrentWorkspace(currentMember.id))
      toast(
        <StyledToast variant="success" title={`Member removed successfully`} />
      )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsRemoveMemberModalOpen(false)
    }
  }

  const leaveWorkspaceHandler = async () => {
    if (!currentWorkspace) {
      return
    }
    try {
      setIsLoading(true)
      await workspaceService.leaveWorkspace(currentWorkspace.id)
      dispatch(
        removeWorkspace({
          id: currentWorkspace.id,
          resetCurrentWorkspace: true,
        })
      )
      toast(
        <StyledToast variant="info" title={`Navigated to default workspace`} />
      )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsLeaveWorkspaceModalOpen(false)
    }
  }

  const EditRoleBox = (memberId: string, values: any) => (
    <UpdateIconContainer
      disabled={false}
      onClick={() => {
        setCurrentMember({
          id: values.id,
          email: values.email,
          role: values.role,
        })
        setIsEditMemberModalOpen(true)
      }}
    >
      <EditOutlinedIcon
        fontSize="small"
        sx={{
          color: theme.palette.neutral['n3-100'],
        }}
      />
    </UpdateIconContainer>
  )

  const ActionBox = (memberId: string, values: any) => (
    <button
      style={{
        padding: '4px 8px',
        cursor: 'pointer',
        borderRadius: '4px',
        border: 'none',
        fontSize: '14px',
        fontWeight: 500,
      }}
      onClick={() => {
        setCurrentMember({
          id: values.id,
          role: values.role,
          email: values.email,
        })
        setIsRemoveMemberModalOpen(true)
      }}
    >
      Remove
    </button>
  )

  const userTable = {
    columns: [
      {
        Header: tableHeader('Id', theme),
        accessor: 'id',
      },
      {
        Header: tableHeader('Name', theme),
        accessor: 'name',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return (
            <Typography
              sx={{ color: theme.palette.neutral['n1-100'] }}
              variant="base1"
            >
              {row.values.name}
              <span>{row.values.id === userInfo.userId && <Tag>You</Tag>}</span>
            </Typography>
          )
        },
      },
      {
        Header: tableHeader('Email', theme),
        accessor: 'email',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return tableEntry(row.values.email, theme)
        },
      },
      {
        Header: tableHeader('Role', theme),
        accessor: 'role',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return tableEntry(row.values.role, theme)
        },
      },
      {
        Header: tableHeader('Edit', theme),
        accessor: 'action1',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          if (row.values.role === 'Owner') {
            return tableEntry('-', theme)
          }

          if (isOwner) {
            return EditRoleBox(row.values.id, row.values)
          }

          if (
            isAuthorized &&
            ['Billing', 'Read', 'Write'].includes(row.values.role)
          ) {
            return EditRoleBox(row.values.id, row.values)
          }

          return tableEntry('-', theme)
        },
      },
      {
        Header: tableHeader('Action', theme),
        accessor: 'action2',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          //First check for the Owner
          //Don't allow any edit
          if (row.values.role === 'Owner') {
            return tableEntry('-', theme)
          }

          if (isOwner) {
            return ActionBox(row.values.id, row.values)
          }

          if (
            isAuthorized &&
            ['Billing', 'Read', 'Write'].includes(row.values.role)
          ) {
            return ActionBox(row.values.id, row.values)
          }

          return tableEntry('-', theme)
        },
      },
    ],
    rows: currentWorkspace
      ? currentWorkspace.members.map((member) => ({
          id: member.id,
          name: member.name,
          email: member.email,
          role: capitalizeFLetter(member.role),
        }))
      : [],
  }

  const onRefresh = () => {
    fetch(
      workspaceService.getWorkspaces(),
      (response) => {
        return response.success ? response.data : []
      },
      (data) => {
        const workspaces = data.map((item) => ({
          id: item.workspace._id,
          name: item.workspace.name,
          owner: item.workspace.owner._id,
          members: item.workspace.members.map((member) => ({
            id: member.user._id,
            name: member.user.name,
            email: member.user.email,
            role: member.role,
          })),
        }))
        dispatch(updateWorkspaces(workspaces))
      }
    )
  }
  return (
    <AppLayout overflow="hidden">
      <PageContainer>
        <PageHeader
          title="Admin"
          path="/admin"
          actions={<ActionsWithRefresh onRefresh={onRefresh} />}
          loading={refreshing}
        />
        <PageContent>
          {isLoading ? (
            <ComponentLoader />
          ) : (
            currentWorkspace && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    marginBottom: '16px',
                  }}
                >
                  <Typography variant="body2">Members</Typography>
                  {isAuthorized ? (
                    <Stack direction="row" sx={{ gap: '8px' }}>
                      <StyledButton
                        size="small"
                        title="Invite members"
                        variant="primary"
                        textVariant="base1"
                        disabled={false}
                        onClickHandler={() => setIsInviteMemberModalOpen(true)}
                      />
                      {!isOwner && (
                        <StyledButton
                          size="small"
                          title="Leave workspace"
                          variant="danger"
                          textVariant="base1"
                          disabled={false}
                          onClickHandler={() =>
                            setIsLeaveWorkspaceModalOpen(true)
                          }
                        />
                      )}
                    </Stack>
                  ) : (
                    <StyledButton
                      size="small"
                      title="Leave workspace"
                      variant="danger"
                      textVariant="base1"
                      disabled={false}
                      onClickHandler={() => setIsLeaveWorkspaceModalOpen(true)}
                    />
                  )}
                </div>
                <TableContainer>
                  {userTable.rows.length === 0 && (
                    <Typography
                      sx={{ color: theme.palette.neutral['n1-100'] }}
                      variant="body2"
                    >
                      No members in this workspace
                    </Typography>
                  )}
                  {userTable.rows.length > 0 && (
                    <StyledTable
                      pagination={false}
                      pageCount={5}
                      columns={userTable.columns}
                      data={userTable.rows}
                      hiddenColumns={['id']} //Note: pass 'accessor' value not 'Header' name
                    />
                  )}
                </TableContainer>
              </>
            )
          )}
        </PageContent>
      </PageContainer>
      {isInviteMemberModalOpen && (
        <InviteOrEditMemberModal
          isOpen={isInviteMemberModalOpen}
          loading={isLoading}
          closeHandler={() => setIsInviteMemberModalOpen(false)}
          submitActionHandler={inviteMemberHandler}
        />
      )}
      {isEditMemberModalOpen && (
        <InviteOrEditMemberModal
          editMode={true}
          isOpen={isEditMemberModalOpen}
          loading={isLoading}
          closeHandler={() => setIsEditMemberModalOpen(false)}
          submitActionHandler={updateMemberRoleHandler}
          defaultValues={currentMember}
        />
      )}
      {isLeaveWorkspaceModalOpen && (
        <ConfirmationModal
          loading={isLoading}
          title="Leave workspace"
          isOpen={isLeaveWorkspaceModalOpen}
          confirmMessage={`You won't be able to undo this action. Are you sure you want to leave this workspace?`}
          confirmBtnTitle="Leave"
          confirmHandler={leaveWorkspaceHandler}
          closeHandler={() => setIsLeaveWorkspaceModalOpen(false)}
        />
      )}
      {isRemoveMemberModalOpen && (
        <ConfirmationModal
          loading={isLoading}
          title="Remove member"
          isOpen={isRemoveMemberModalOpen}
          confirmMessage={`You won't be able to undo this action. Are you sure you want to remove this member from the workspace?`}
          confirmBtnTitle="Remove"
          confirmHandler={removeMemberHandler}
          closeHandler={() => setIsRemoveMemberModalOpen(false)}
        />
      )}
    </AppLayout>
  )
}

export default memo(AdminOutlet)

// {currentWorkspace.members.map((member) => (
//   <Stack
//     sx={{
//       justifyContent: 'space-between',
//     }}
//     direction="row"
//     key={member.id}
//   >
//     <Stack>
//       <Typography variant="base1">
//         {member.name}
//         <span>
//           {member.id === userInfo.userId && <Tag>You</Tag>}
//         </span>
//       </Typography>
//       <Typography
//         variant="caption1"
//         sx={{ color: theme.palette.neutral['n4-100'] }}
//       >
//         {member.email}
//       </Typography>
//     </Stack>
//     <Stack direction="row" alignItems="center" spacing={2}>
//       <Typography variant="base1" sx={{}}>
//         {member.role}
//       </Typography>
//       <UpdateIconContainer>
//         <EditOutlinedIcon fontSize="small" />
//       </UpdateIconContainer>
//     </Stack>
//   </Stack>
// ))}
