import React, { useState, useEffect, useCallback } from 'react'
import { styled, useTheme } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

//components
import Typography from '@mui/material/Typography'
import PageHeader from 'components/PageHeader/PageHeader'
import LogVisualizer from 'components/LogVisualizer/LogVisualizer'
import CodeHighlight from 'components/CodeHighlight/CodeHighlight'
import StyledToast from 'components/StyledToast/StyledToast'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'
import { TabContainer } from 'components/StyledTab/TabContainer'
import { TabItem } from 'components/StyledTab/TabItem'
import { ComponentLoader } from 'components/Loader/Loader'
import { getColor } from 'components/StatusPill/StatusPill'

//assets
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined'

//service
import * as executionService from 'services/execution.service'

//redux
import { selectCurrentWorkspace } from 'redux/userSlice'

//types
import { IExecution } from 'types/execution'
import CopyTextField from 'components/CopyTextField/CopyTextFiled'

//utils
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'
import { Status } from 'types/common'
import useFetchData from 'hooks/useFetchData'
import pollAndSync from 'utils/pollAndSync'
import ActionsWithRefresh from 'components/ActionsWithRefresh'
import handleServiceError from 'utils/handleServiceError'

const NODE_SELECTOR_WIDTH = 150

const PageContent = styled('div')(() => ({
  flex: '1 1 auto',
  width: '100% ',
  display: 'flex',
  flexDirection: 'column',
}))

const ParametersContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: '16px',
  borderRadius: '8px',
}))

const Parameter = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
}))

const DetailsContainer = styled('div')(({ theme }) => ({
  flex: '1 1 0',
  width: '100%',
  display: 'flex',
}))

const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: `calc(100% - ${NODE_SELECTOR_WIDTH}px)`,
  padding: '16px 40px',
  gap: '24px',
  borderRight: `1px solid ${theme.palette.neutral['n5-100']}`,
}))

const DetailsContainer1 = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '24px',
}))

const DetailsContainer2 = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  display: 'flex',
}))

const Nodes = styled('div')(({ theme }) => ({
  width: `${NODE_SELECTOR_WIDTH}px`,
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  gap: '12px',
}))

const NodeItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  backgroundColor: theme.palette.neutral['n5-100'],
  borderRadius: '8px',
  cursor: 'pointer',
}))

const BlockTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
}))

const DeleteIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '8px',
  borderRadius: '8px',
  backgroundColor: theme.palette.neutral['n4-25'],
  cursor: 'pointer',
}))

const EXECUTION_TABS = [
  {
    name: 'Container details',
  },
  {
    name: 'Logs',
  },
]

const Execution = () => {
  //hooks
  const theme = useTheme()
  const navigate = useNavigate()
  const { name: jobName } = useParams()

  //redux
  const currentWorkSpace = useSelector(selectCurrentWorkspace)

  //local
  const [isLoading, setIsLoading] = useState(false)
  const [job, setJob] = useState<IExecution | null>(null)
  const [currentNode, setCurrentNode] = useState(0)
  const [activeSubTab, setActiveSubTab] = useState(1)
  const [isDeleteExecutionModalOpen, setIsDeleteExecutionModalOpen] =
    useState(false)

  const { fetch, loading: refreshing } = useFetchData()

  useEffect(() => {
    if (!currentWorkSpace) {
      return
    }

    const cancelPolling = pollAndSync(
      () => executionService.getExecutionByName(currentWorkSpace.id, jobName),
      undefined,
      (data) => setJob(data),
      (data) => (data ? data.status > Status.Running : false),
      (err) => console.error(err),
      () => setIsLoading(false)
    )

    return () => {
      cancelPolling()
    }
  }, [currentWorkSpace, jobName])

  const deleteExecutionHandler = async () => {
    if (!currentWorkSpace || !job?.job_name) {
      return
    }
    try {
      setIsLoading(true)
      await executionService.deleteExecution(currentWorkSpace.id, job?.job_name)
      toast(
        <StyledToast
          variant="success"
          title={`'${job?.job_name}' execution was deleted successfully`}
        />
      )
      navigate('/executions')
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsDeleteExecutionModalOpen(false)
    }
  }

  let startedDate = 'NA'
  let endDate = 'NA'
  if (job) {
    if (job.metadata.started_at) {
      startedDate = dayjs(dayjs.unix(job.metadata.started_at)).format(
        'MMM D, h:mm A'
      )
    }
    if (job.metadata.ended_at) {
      endDate = dayjs(dayjs.unix(job.metadata.ended_at)).format('MMM D, h:mm A')
    }
  }

  const parameters = [
    {
      parameter: 'Job name',
      value: job?.job_name || 'NA',
    },
    {
      parameter: 'Started at',
      value: startedDate,
    },
    {
      parameter: 'Ended at',
      value: endDate,
    },
    // {
    //   parameter: 'Status',
    //   value: job?.status || 'NA',
    // },
    {
      parameter: ' Machine type',
      value: job?.container_specs[currentNode].machine_type || 'NA',
    },
    {
      parameter: 'Spot',
      value: job?.container_specs[currentNode].spot.toString() || 'NA',
    },
  ]

  let executionCommand = ''

  if (job?.container_specs[currentNode].command) {
    executionCommand = `${job?.container_specs[currentNode].command}`
  }

  if (job?.container_specs[currentNode].args) {
    executionCommand += ` ${job?.container_specs[currentNode].args?.join(' ')}`
  }

  let executionEnvVars = ``
  if (job?.container_specs[currentNode].env) {
    const envArray = []
    const envObj = job.container_specs[currentNode].env
    for (const key in envObj) {
      envArray.push(`${key}: "${envObj[key]}"`)
    }
    executionEnvVars = envArray.join('\n')
  }

  const handleRefresh = () => {
    if (!currentWorkSpace) return

    fetch(
      executionService.getExecutionByName(currentWorkSpace.id, jobName),
      undefined,
      (data) => setJob(data)
    )
  }

  return (
    <>
      <PageHeader
        title="Executions"
        subTitle={jobName}
        path="/executions"
        loading={refreshing}
        actions={
          <ActionsWithRefresh variant="icon" onRefresh={handleRefresh}>
            {/* <ArchiveOutlinedIcon /> */}
            <DeleteIconContainer
              onClick={() => setIsDeleteExecutionModalOpen(true)}
            >
              <DeleteOutlineIcon
                fontSize="small"
                sx={{ color: theme.palette.neutral['n3-100'] }}
              />
            </DeleteIconContainer>
          </ActionsWithRefresh>
        }
      />
      <PageContent>
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <>
            <DetailsContainer>
              <Details>
                <DetailsContainer1>
                  {parameters.map((item) => (
                    <Parameter>
                      <Typography variant="base1" sx={{ fontWeight: 400 }}>
                        {item.parameter}
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.neutral['n3-50'],
                          fontWeight: 400,
                          maxWidth: '150px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        variant="base2"
                      >
                        {item.value}
                      </Typography>
                    </Parameter>
                  ))}
                  <Parameter>
                    <Typography variant="base1" sx={{ fontWeight: 400 }}>
                      Status
                    </Typography>
                    <Typography
                      variant="base2"
                      sx={{
                        textTransform: 'capitalize',
                        color: `${getColor(
                          job?.status || Status.Default,
                          theme.palette
                        )}`,
                      }}
                    >
                      {job ? Status[job.status] : 'NA'}
                    </Typography>
                  </Parameter>
                </DetailsContainer1>
                <TabContainer>
                  {React.Children.toArray(
                    EXECUTION_TABS.map((tab, index) => (
                      <TabItem
                        isActive={activeSubTab === index + 1}
                        onClick={() => setActiveSubTab(index + 1)}
                      >
                        {tab.name}
                      </TabItem>
                    ))
                  )}
                </TabContainer>
                <DetailsContainer2>
                  {activeSubTab === 1 && (
                    <ParametersContainer>
                      <CopyTextField
                        label="Execution code directory"
                        value={job?.code_specs.code_dir || 'N/A'}
                      />
                      <CopyTextField
                        label="Image"
                        value={job?.container_specs[currentNode].image || 'N/A'}
                      />
                      <CopyTextField
                        label="Command"
                        value={executionCommand || 'N/A'}
                      />
                      <Parameter>
                        <Typography variant="base1" sx={{ fontWeight: 400 }}>
                          Environment variables
                        </Typography>
                        <CodeHighlight
                          codeSnippet={executionEnvVars || 'N/A'}
                          language="javascript"
                        />
                      </Parameter>
                    </ParametersContainer>
                  )}
                  {activeSubTab === 2 && (
                    <ParametersContainer>
                      <LogVisualizer
                        resourceName={jobName || ''}
                        getLogsFn={executionService.getExecutionLog}
                        nodeIndex={currentNode}
                      />
                    </ParametersContainer>
                  )}
                </DetailsContainer2>
              </Details>
              <Nodes>
                <BlockTitle>
                  <MemoryOutlinedIcon fontSize="small" />
                  <Typography variant="body2">Nodes</Typography>
                </BlockTitle>
                {job?.container_specs.map((_, index) => (
                  <NodeItem onClick={() => setCurrentNode(index)}>
                    Node {index + 1}
                  </NodeItem>
                ))}
              </Nodes>
            </DetailsContainer>
          </>
        )}
      </PageContent>
      {isDeleteExecutionModalOpen && (
        <ConfirmationModal
          title="Delete execution"
          isOpen={isDeleteExecutionModalOpen}
          confirmMessage={`You won't be able to undo this action. Are you sure you want to delete '${job?.job_name}' ?`}
          confirmBtnTitle={isLoading ? 'Deleting...' : 'Delete'}
          confirmHandler={deleteExecutionHandler}
          closeHandler={() => setIsDeleteExecutionModalOpen(false)}
          loading={isLoading}
        />
      )}
    </>
  )
}

export default Execution
