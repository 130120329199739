import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

//components
import Typography from '@mui/material/Typography'
import PageHeader from 'components/PageHeader/PageHeader'
import { ComponentLoader } from 'components/Loader/Loader'
import ResourceCard from 'components/ResourceCard/ResourceCard'

//service
import * as deploymentService from 'services/deployment.service'

//redux
import { selectCurrentWorkspace } from 'redux/userSlice'

//types
import { IDeployment } from 'types/deployment'
import useFetchData from 'hooks/useFetchData'
import ActionsWithRefresh from 'components/ActionsWithRefresh'
import useFilteredData from 'hooks/useFilteredData'
import { Status } from 'types/common'
import pollAndSync from 'utils/pollAndSync'
import InactiveWorkspace from 'components/InactiveWorkspace'
import useCheckWorkspaceActivation from 'hooks/useCheckWorkspaceActivation'

const CardsWrapper = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '24px 40px',
  gap: '32px',
  alignContent: 'flex-start',
}))

interface DeploymentPollConfig {
  id: string
  workspaceId: string
  deploymentName: string
  status: Status
}

const Deployments = () => {
  const navigate = useNavigate()

  //redux
  const currentWorkSpace = useSelector(selectCurrentWorkspace)

  const [deployments, setDeployments] = useState<IDeployment[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const { fetch, loading: refreshing } = useFetchData()
  const { loading: checkingWorkspaceStatus, isActivated } =
    useCheckWorkspaceActivation()
  const cancelFns: { [key: string]: () => void } = useRef({}).current

  useEffect(() => {
    if (!currentWorkSpace) {
      return
    }

    const fetchData = async () => {
      try {
        setIsLoading(true)
        const deployments = await deploymentService.getDeployments(
          currentWorkSpace.id
        )
        setDeployments(deployments)
      } catch (err) {
        console.log(err)
        setDeployments([])
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [currentWorkSpace])

  useFilteredData(
    deployments,
    [] as DeploymentPollConfig[],
    (prev) => {
      return deployments
        .filter((dp) => dp.status <= Status.Deploying)
        .filter((dp) => !prev.find((j) => j.id === dp._id))
        .map((dp) => ({
          id: dp._id,
          workspaceId: dp.workspace_id,
          deploymentName: dp.deployment_name,
          status: dp.status,
        }))
    },
    (syncableDps) => {
      syncableDps.forEach((dp) => {
        cancelFns[dp.id] = pollAndSync(
          () =>
            deploymentService.getDeploymentByName(
              dp.workspaceId,
              dp.deploymentName
            ),
          undefined,
          (data) =>
            setDeployments((prev) => {
              const index = prev.findIndex((dp) => dp._id === data._id)
              if (index !== -1) {
                prev[index] = data
              }
              return [...prev]
            }),
          (data) => (data ? data.status >= Status.Running : false)
        )
      })
    }
  )

  useEffect(() => {
    return () => {
      Object.values(cancelFns).forEach((canceller) => canceller())
    }
  }, [cancelFns])

  const handleRefresh = () => {
    if (!currentWorkSpace) return

    fetch(
      deploymentService.getDeployments(currentWorkSpace.id),
      (res) => res ?? [],
      (data) => setDeployments(data)
    )
  }

  return (
    <>
      <PageHeader
        title="Deployments"
        path="/deployments"
        loading={refreshing || checkingWorkspaceStatus}
        actions={
          <ActionsWithRefresh
            disabled={checkingWorkspaceStatus || !isActivated}
            onRefresh={handleRefresh}
          />
        }
      />
      {checkingWorkspaceStatus || isLoading ? (
        <ComponentLoader />
      ) : !isActivated ? (
        <InactiveWorkspace />
      ) : (
        <CardsWrapper>
          <>
            {deployments.length === 0 && (
              <Typography variant="base1">
                You have not deployed any models yet.
              </Typography>
            )}
            {deployments.length > 0 &&
              React.Children.toArray(
                deployments.map((deployment) => (
                  <ResourceCard
                    title={deployment.deployment_name}
                    status={deployment.status}
                    createdTime={deployment.metadata.started_at}
                    onClickHandler={() =>
                      navigate(`/deployments/${deployment.deployment_name}`)
                    }
                  />
                ))
              )}
          </>
        </CardsWrapper>
      )}
    </>
  )
}

export default Deployments
