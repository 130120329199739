import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

//component
import StyledToast from 'components/StyledToast/StyledToast'

//services
import * as authService from 'services/auth.service'
import * as userService from 'services/user.service'

//redux
import { setIsAppLoading } from 'redux/appSlice'
import {
  logout,
  selectIsLoggedIn,
  setUserInfo,
  selectRefetchUserDataCount,
} from 'redux/userSlice'

const LoadUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  //redux
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const refetchUserDataCount = useSelector(selectRefetchUserDataCount)

  const sessionExpiredHandler = () => {
    authService.clearToken()
    dispatch(logout())
    toast(
      <StyledToast
        variant="error"
        title="Please login again as your session has expired"
      />
    )
    navigate('/login', { replace: true })
  }

  //First: Get user details
  useEffect(() => {
    console.log('USE_EFFECT:GET_USER')
    const getUser = async () => {
      try {
        dispatch(setIsAppLoading(true))
        const userData = await userService.me()
        dispatch(
          setUserInfo({
            userId: userData.data._id,
            email: userData.data.email,
            name: userData.data.name,
            workspaces: userData.data.workspaces.map((item) => ({
              id: item.workspace._id,
              name: item.workspace.name,
              owner: item.workspace.owner._id,
              members: item.workspace.members.map((member) => ({
                id: member.user._id,
                name: member.user.name,
                email: member.user.email,
                role: member.role,
              })),
            })),
            apiKeys: userData.data.apiKeys,
            companyName: userData.data.company
              ? userData.data.company.name
              : '',
          })
        )
      } catch (error) {
        sessionExpiredHandler()
      } finally {
        dispatch(setIsAppLoading(false))
      }
    }
    if (isLoggedIn) {
      getUser()
    } else {
      //TODO:Decide
    }
  }, [refetchUserDataCount])
  return <></>
}

export default LoadUser
