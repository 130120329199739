import React from 'react'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    poster: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    base1?: React.CSSProperties
    base2?: React.CSSProperties
    caption1?: React.CSSProperties
    caption2?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    base1?: true
    base2?: true
    caption1?: true
    caption2?: true
  }
}

const typography = {
  fontFamily: 'Inter',
  h1: {
    fontSize: '64px',
    fontWeight: 700,
    lineHeight: '72px',
  },
  h2: {
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '56px',
  },
  h3: {
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '48px',
  },
  h4: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  h5: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '40px',
  },
  h6: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  body1: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '36px',
    letterSpacing: '-0.02em',
  },
  body2: {
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  base1: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.02em',
  },
  base2: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  caption1: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  caption2: {
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '16px',
  },
}
export default typography
