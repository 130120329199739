import React from 'react'
import { styled, Palette, useTheme } from '@mui/material'

//components
import Typography from '@mui/material/Typography'
import { IconContainer } from 'components/IconContainer/IconContainer'

//assets
import RightMark from 'assets/icons/check-light.png'
import CrossMark from 'assets/icons/x-close-light.png'

const getColor = (
  variant: string,
  palette: Palette
): React.CSSProperties['color'] => {
  switch (variant) {
    case 'success':
      return palette.accent.a2
    case 'error':
      return palette.accent.a1
    case 'info':
      return palette.primary.main
    default:
      return palette.accent.a2
  }
}

const Container = styled('div')<{ variant: string }>(({ theme, variant }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '16px 24px 16px 16px',
  alignItems: 'center',
  gap: '12px',
  backgroundColor: getColor(variant, theme.palette),
  borderRadius: '12px',
}))

interface Props {
  title: string
  variant: 'success' | 'error' | 'info'
}

const StyledToast = ({ title, variant }: Props) => {
  const theme = useTheme()
  return (
    <Container variant={variant}>
      {variant !== 'info' && (
        <IconContainer
          src={variant === 'success' ? RightMark : CrossMark}
          width={24}
          height={24}
        />
      )}
      <Typography
        sx={{
          maxWidth: '600px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: theme.palette.neutral['n1-100'],
          fontWeight: 600,
        }}
        variant="body2"
      >
        {title}
      </Typography>
    </Container>
  )
}

export default StyledToast
