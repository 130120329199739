import { Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ErrorBoundary } from 'react-error-boundary'

//Protect route layout
import LayoutWrapper from 'layouts/LayoutWrapper'
import AuthPageLayout from 'layouts/AuthPageLayout'

//dashboard
import DashboardOutlet from 'pages/Dashboard/DashboardOutlet'
import Dashboard from 'pages/Dashboard/Dashboard'

//notebook
import NotebooksOutlet from 'pages/Notebooks/NotebooksOutlet'
import Notebooks from 'pages/Notebooks/Notebooks'
import Notebook from 'pages/Notebooks/Notebook'

//execution
import ExecutionsOutlet from 'pages/Executions/ExecutionsOutlet'
import Executions from 'pages/Executions/Executions'
import Execution from 'pages/Executions/Execution'

//deployment
import DeploymentsOutlet from 'pages/Deployments/DeploymentsOutlet'
import Deployments from 'pages/Deployments/Deployments'
import Deployment from 'pages/Deployments/Deployment'

//billing and management
import BillingOutlet from 'pages/Billing/BillingOutlet'
import Billing from 'pages/Billing/Billing'
import AdminOutlet from 'pages/Admin/AdminOutlet'
import SettingsOutlet from 'pages/Settings/SettingsOutlet'

//authentication
import Login from 'pages/Auth/Login'
import VerifyEmail from 'pages/Auth/VerifyEmail'
import AuthSuccess from 'pages/Auth/AuthSuccess'
import AuthFailure from 'pages/Auth/AuthFailure'
import ForgotPassword from 'pages/Auth/ForgotPassword'
import ResetPassword from 'pages/Auth/ResetPassword'
import JoinWorkspace from 'pages/Auth/JoinWorkspace'
import WaitList from 'pages/Auth/WaitList'
import EarlyAccess from 'pages/Auth/EarlyAccess'

//not found
import NotFound from 'pages/NotFound/NotFound'

//components
import {
  ErrorFallback,
  myErrorHandler,
} from 'components/ErrorFallback/ErrorFallback'

//styles, themes and assets
import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import { getCalApi } from '@calcom/embed-react'

function App() {
  React.useEffect(() => {
    ;(async function () {
      const cal = await getCalApi()
      cal('ui', {
        theme: 'dark',
        hideEventTypeDetails: false,
        layout: 'month_view',
      })
    })()
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <ToastContainer
        closeButton={false}
        position="top-center"
        hideProgressBar={true}
        limit={2}
      />
      <Routes>
        <Route path="" element={<LayoutWrapper />}>
          <Route path="" element={<Navigate to={'/notebooks'} replace />} />
          <Route path="" element={<DashboardOutlet />}>
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
          <Route path="" element={<NotebooksOutlet />}>
            <Route path="notebooks" element={<Notebooks />} />
            <Route path="notebooks/:name" element={<Notebook />} />
          </Route>
          <Route path="" element={<ExecutionsOutlet />}>
            <Route path="executions" element={<Executions />} />
            <Route path="executions/:name" element={<Execution />} />
          </Route>
          <Route path="" element={<DeploymentsOutlet />}>
            <Route path="deployments" element={<Deployments />} />
            <Route path="deployments/:name" element={<Deployment />} />
          </Route>
          <Route path="" element={<BillingOutlet />}>
            <Route path="billing" element={<Billing />} />
          </Route>
          <Route path="" element={<SettingsOutlet />}>
            <Route path="settings" element={<></>} />
          </Route>
          <Route path="admin" element={<AdminOutlet />} />
        </Route>
        <Route path="" element={<AuthPageLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="join-workspace" element={<JoinWorkspace />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route path="auth/success" element={<AuthSuccess />} />
          <Route path="auth/failure" element={<AuthFailure />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ErrorBoundary>
  )
}

export default App
