import { styled } from '@mui/material'

//components
import StyledButton from 'components/StyledButton/StyledButton'
import Typography from '@mui/material/Typography'
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'

const ErrorContainer = styled('div')(() => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  color: '#FEFEFE',
  backgroundColor: '#141718',
}))

export const myErrorHandler = (
  error: Error,
  info: { componentStack: string }
) => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.log('ERROR', error)
}

export function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: { message: String }
  resetErrorBoundary: () => any
}) {
  return (
    <ErrorContainer role="alert">
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
       {STANDARD_ERROR_MESSAGE} <br />
        Please try refreshing the page{' '}
      </Typography>
      <StyledButton
        title="Try again"
        variant="primary"
        textVariant="base1"
        onClickHandler={() => window.location.reload()}
      />
    </ErrorContainer>
  )
}
