import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, FormikHelpers } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

//components
import Typography from '@mui/material/Typography'
import StyledInput from 'components/StyledInput/StyledInput'
import StyledButton from 'components/StyledButton/StyledButton'
import UploadFiles from 'components/UploadFiles/UploadFiles'
import StyledToast from 'components/StyledToast/StyledToast'

//redux
import { selectUserInfo, setUserInfo } from 'redux/userSlice'

//service
import { updateProfileData } from 'services/user.service'

//assets
import EmailIcon from 'assets/icons/email.png'
import UserIcon from 'assets/icons/user.png'
import UserPlaceholder from 'assets/user.png'
import CompanyIcon from 'assets/icons/marker-pin.png'
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'
import handleServiceError from 'utils/handleServiceError'

const UploadImageInput = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
}))

const ProfileBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}))

const ProfileInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  flex: '1 0 0',
}))

const UploadImageBtn = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderStyle: 'solid',
  gap: '8px',
  cursor: 'pointer',
  color: theme.palette.neutral['n1-100'],
  backgroundColor: 'transparent',
  borderColor: theme.palette.neutral['n5-100'],
  padding: '12px 24px',
  borderRadius: '12px',
  borderWidth: '2px',
}))

const AvatarBox = styled('div')<{ image?: string | ArrayBuffer }>(
  ({ theme, image }) => ({
    width: '112px',
    height: '112px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundImage: `url(${image ? image : UserPlaceholder})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  })
)

const FormWrapper = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
}))

interface FormValues {
  userName: string
  email: string
  //companyName: string
}

const EditProfile = () => {
  //hooks
  const theme = useTheme()
  const dispatch = useDispatch()

  //redux
  const userInfo = useSelector(selectUserInfo)

  const initialValues: FormValues = {
    userName: userInfo.name ? userInfo.name : '',
    email: userInfo.email ? userInfo.email : '',
    //companyName: userInfo.companyName ? userInfo.companyName : '',
  }
  let defaultProfileUrl = userInfo.profileUrl ? userInfo.profileUrl : ''
  const [isLoading, setIsLoading] = useState(false)
  const [avatarData, setAvatarData] = useState<{
    file?: File
    url: ArrayBuffer | string
  }>({
    url: defaultProfileUrl,
  })

  const profileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      return
    }

    setAvatarData((prev) => ({
      ...prev,
      ...(e.target.files && { file: e.target.files[0] }),
    }))
    previewAndSetImage(e.target.files[0])
  }

  const previewAndSetImage = (file: File) => {
    try {
      const reader = new FileReader()
      if (file && file.size / 1000 > 1000) {
        toast(
          <StyledToast
            variant="error"
            title="Image size should be less than 1 MB"
          />
        )
        return
      }
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        setAvatarData((prev) => ({
          ...prev,
          ...(reader.result && { url: reader.result }),
        }))
      }
    } catch (error) {
      toast(<StyledToast variant="error" title={STANDARD_ERROR_MESSAGE} />)
    }
  }

  async function editProfileHandler(
    formObject: FormValues,
    actions: FormikHelpers<FormValues>
  ) {
    setIsLoading(true)

    try {
      await updateProfileData(userInfo.userId, {
        userName: formObject.userName,
        profileUrl: '',
        //companyName: formObject.companyName,
      })

      actions.setSubmitting(false)
      dispatch(
        setUserInfo({
          name: formObject.userName,
          //companyName: formObject.companyName,
        })
      )
      toast(
        <StyledToast variant="success" title="Update user data successfully" />
      )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {/* <UploadImageInput>
        <Typography
          sx={{ color: theme.palette.neutral['n1-100'] }}
          variant='base2'
        >
          Avatar
        </Typography>
        <ProfileBox>
          <AvatarBox image={avatarData.url} />
          <ProfileInfo>
            <UploadFiles
              inputId='xchat-profile-image'
              accept='image/jpeg, image/png, image/jpg'
              onChange={(event) => profileChangeHandler(event)}
            >
              <UploadImageBtn>
                <Typography variant='base1'>Upload new image</Typography>
              </UploadImageBtn>
            </UploadFiles>
            <Typography
              sx={{ color: theme.palette.neutral['n3-50'] }}
              variant='caption1'
            >
              At least 800x800 px recommended. JPG or PNG is allowed.
            </Typography>
          </ProfileInfo>
        </ProfileBox>
      </UploadImageInput> */}
      <Formik
        initialValues={initialValues}
        validateOnMount={false}
        validationSchema={Yup.object().shape({
          userName: Yup.string().max(255).required('Name is required'),
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          // companyName: Yup.string()
          //   .max(255)
          //   .required('Company name is required'),
        })}
        onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
          //handler function
          editProfileHandler(values, actions)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <StyledInput
              inputType="text"
              autoFocus={false}
              id="userName"
              label="Name"
              placeholder="Enter your name"
              iconImageSrc={UserIcon}
              value={values.userName}
              isRequired={true}
              fullWidth={true}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={Boolean(touched.userName && errors.userName)}
              errorMessage={touched.userName && errors.userName}
            />
            <StyledInput
              disabled={true}
              inputType="email"
              autoFocus={false}
              id="email"
              label="Email"
              placeholder="Email address"
              iconImageSrc={EmailIcon}
              value={values.email}
              isRequired={true}
              fullWidth={true}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={Boolean(touched.email && errors.email)}
              errorMessage={touched.email && errors.email}
            />
            {/* <StyledInput
              inputType="text"
              autoFocus={false}
              id="companyName"
              label="Company"
              placeholder="Enter company name"
              iconImageSrc={CompanyIcon}
              value={values.companyName}
              isRequired={true}
              fullWidth={true}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={Boolean(touched.companyName && errors.companyName)}
              errorMessage={touched.companyName && errors.companyName}
            /> */}
            <StyledButton
              style={{ alignSelf: 'flex-start' }}
              type="submit"
              title={isLoading ? 'Updating...' : 'Save changes'}
              variant="primary"
              textVariant="base1"
              disabled={isLoading || isSubmitting}
            />
          </FormWrapper>
        )}
      </Formik>
    </>
  )
}

export default EditProfile
