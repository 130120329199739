import { useState, useEffect, useCallback } from 'react'
import { styled, useTheme } from '@mui/material'

//components
import PageHeader from 'components/PageHeader/PageHeader'
import { ComponentLoader } from 'components/Loader/Loader'

//assets
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'

const PageContent = styled('div')(() => ({
  flex: '1 1 auto',
  width: '100% ',
  display: 'flex',
  flexDirection: 'column',
}))

const ActionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}))

const Execution = () => {
  return (
    <>
      <PageHeader title="Dashboard" path="/dashboard" />
      <PageContent>
        <></>
      </PageContent>
    </>
  )
}

export default Execution
