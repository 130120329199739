import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

interface AppState {
  referral: string
  isSidebarOpen: boolean
  isContactModalOpen: boolean
  isAppLoading: boolean
}

export const initialState: AppState = {
  referral: '',
  isSidebarOpen: true,
  isContactModalOpen: false,
  isAppLoading: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReferral: (state, action: PayloadAction<string>) => {
      state.referral = action.payload
    },
    setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload
    },
    setIsContactModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isContactModalOpen = action.payload
    },
    setIsAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppLoading = action.payload
    },
    appSliceReset: () => {
      //NOTE: returning the initial state to reset the state
      return { ...initialState }
    },
  },
})

export const {
  setReferral,

  setIsSidebarOpen,
  setIsContactModalOpen,

  setIsAppLoading,

  appSliceReset,
} = appSlice.actions

export const selectReferral = (state: RootState) => state.app.referral
export const selectIsSidebarOpen = (state: RootState) => state.app.isSidebarOpen
export const selectIsAppLoading = (state: RootState) => state.app.isAppLoading
export const selectIsContactModalOpen = (state: RootState) =>
  state.app.isContactModalOpen

export default appSlice
