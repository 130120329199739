import React, { useState, useMemo, useCallback } from 'react'
import { styled, useTheme } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

//icons
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'

//component
import Logo from '../Logo/Logo'
import UserProfile from '../UserProfile/UserProfile'
import StyledMenuItem from 'components/StyledMenuItem/StyledMenuItem'
import StyledReactSelect from 'components/StyledReactSelect/StyledReactSelect'
import ContactSupportModal from 'components/ContactSupportModal/ContactSupportModal'
import StyledToast from 'components/StyledToast/StyledToast'
import CreateWorkspace from 'components/CreateWorkspace/CreateWorkspace'
import Divider from '@mui/material/Divider'

//redux
import {
  selectIsLoggedIn,
  selectWorkspaces,
  selectCurrentWorkspace,
  setCurrentWorkspace,
  logout,
  addWorkspace,
  removeWorkspace,
  selectUserInfo,
} from 'redux/userSlice'
import { setIsContactModalOpen, selectIsContactModalOpen } from 'redux/appSlice'

//service
import { createWorkspace } from 'services/workspace.service'
import { contactSupport } from 'services/user.service'

//utils
import { SIDE_PANEL_WIDTH, STANDARD_ERROR_MESSAGE } from 'utils/constants'
import { IWorkspace } from 'types/common'
import handleServiceError from 'utils/handleServiceError'

const SidePanelContainer = styled('div')(() => ({
  width: `${SIDE_PANEL_WIDTH}px`,
  display: 'flex',
  flexDirection: 'column',
}))

const WorkSpaceWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 16px',
  marginBottom: '16px',
}))

const NavContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 16px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': { width: '8px' },
  '&::-webkit-scrollbar-track': {
    height: '80%',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: theme.palette.neutral['n4-50'],
  },
}))

const ProfileContainer = styled('div')(() => ({
  marginTop: 'auto',
  width: `100%`,
  padding: '16px 16px 24px 16px',
}))

const MainNavItemWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 16px',
}))

const SidePanel = () => {
  //hooks
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //redux
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const userInfo = useSelector(selectUserInfo)
  const workspaces = useSelector(selectWorkspaces)
  const currentWorkspace = useSelector(selectCurrentWorkspace)
  const isContactModalOpen = useSelector(selectIsContactModalOpen)

  //local
  const [isLoading, setIsLoading] = useState(false)
  const [isAddWorkspaceModalOpen, setIsAddWorkspaceModalOpen] = useState(false)

  const toWorkspaceLabel = useCallback((workspace: IWorkspace) => {
    if (workspace.name === 'default') {
      // get owner name
      const owner = workspace.members.find((member) => member.role === 'owner')
      if (owner) return `${owner.name}'s workspace`
    }
    return workspace.name
  }, [])

  const MenuItems = [
    // {
    //   title: 'Dashboard',
    //   path: '/dashboard',
    //   icon: <LeaderboardOutlinedIcon style={{ color: '#8C6584' }} />,
    //   onClick: () => {
    //     navigate('/dashboard')
    //   },
    //   subNav: [],
    // },
    {
      title: 'Notebooks',
      path: '/notebooks',
      icon: (
        <StickyNote2OutlinedIcon
          style={{ color: theme.palette.neutral['n3-100'] }}
        />
      ),
      onClick: () => {
        navigate('/notebooks')
      },
      subNav: [],
    },
    {
      title: 'Executions',
      path: '/executions',
      icon: (
        <AccountTreeOutlinedIcon
          style={{ color: theme.palette.neutral['n3-100'] }}
        />
      ),
      onClick: () => {
        navigate('/executions')
      },
      subNav: [],
    },
    {
      title: 'Deployments',
      path: '/deployments',
      icon: (
        <MemoryOutlinedIcon
          style={{ color: theme.palette.neutral['n3-100'] }}
        />
      ),
      onClick: () => {
        navigate('/deployments')
      },
      subNav: [],
    },
    {
      title: 'Billing & quotas',
      path: '/billing',
      icon: (
        <AccountBalanceWalletOutlinedIcon
          style={{ color: theme.palette.neutral['n3-100'] }}
        />
      ),
      onClick: () => {
        navigate('/billing')
      },
      subNav: [],
    },
    {
      title: 'Admin',
      path: 'admin',
      icon: (
        <AdminPanelSettingsOutlinedIcon
          style={{ color: theme.palette.neutral['n3-100'] }}
        />
      ),
      onClick: () => {
        navigate('/admin')
      },
      subNav: [],
    },
    {
      title: 'Settings',
      path: 'settings',
      icon: (
        <SettingsOutlinedIcon
          style={{ color: theme.palette.neutral['n3-100'] }}
        />
      ),
      onClick: () => {
        navigate('/settings')
      },
      subNav: [],
    },
    {
      title: 'Documentation',
      icon: <MenuBookOutlinedIcon style={{ color: '#8C6584' }} />,
      onClick: () => {
        window.open('https://docs.xcloud.stochastic.ai', '_blank')
      },
      subNav: [],
    },
    {
      title: 'Help & support',
      icon: <HelpOutlineOutlinedIcon style={{ color: '#FF4135' }} />,
      onClick: () => {
        dispatch(setIsContactModalOpen(true))
      },
      subNav: [],
    },
  ]

  const workspaceOptions = useMemo(
    () => [
      {
        value: null,
        label: 'Create workspace',
        action: () => {
          setIsAddWorkspaceModalOpen(true)
        },
      },
      // set label for default workspace to user's name
      ...workspaces.map((item) => ({
        value: item.id,
        label: toWorkspaceLabel(item),
      })),
    ],
    [toWorkspaceLabel, workspaces]
  )

  const addWorkspaceHandler = async (name: string) => {
    // check if workspace name is already present in user's workspaces
    const found = workspaces
      .filter((ws) => ws.owner === userInfo.userId)
      .some((ws) => ws.name === name)
    if (found) {
      toast(
        <StyledToast
          variant="error"
          title={`You already have a workspace named ${name}.`}
        />
      )
      return
    }
    try {
      setIsLoading(true)
      const response = await createWorkspace(name)
      dispatch(
        addWorkspace({
          id: response.data._id,
          name: response.data.name,
          owner: response.data.owner,
          members: response.data.members.map((member) => ({
            id: member.user,
            role: member.role,
            name: userInfo.name,
            email: userInfo.email,
          })),
        })
      )
      toast(
        <StyledToast
          variant="success"
          title={`Workspace created successfully.`}
        />
      )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsAddWorkspaceModalOpen(false)
    }
  }

  //contact support
  const contactSupportHandler = async (message: string, email?: string) => {
    try {
      setIsLoading(true)
      await contactSupport(message, email || userInfo.email)
      toast(<StyledToast variant="success" title="Support request submitted" />)
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      dispatch(setIsContactModalOpen(false))
    }
  }

  return (
    <>
      <SidePanelContainer>
        <Logo />
        <WorkSpaceWrapper>
          <StyledReactSelect
            title="Workspace"
            selectedValue={{
              value: currentWorkspace?.id,
              label: currentWorkspace ? toWorkspaceLabel(currentWorkspace) : '',
            }}
            options={workspaceOptions}
            placeholder="Select workspace"
            onChange={(valueObj) => {
              if (!valueObj) {
                return
              }

              if (valueObj.action) {
                valueObj.action()
                return
              }
              dispatch(setCurrentWorkspace(valueObj.value))
            }}
            fullWidth={true}
          />
        </WorkSpaceWrapper>
        {/* <Divider
          sx={{
            backgroundColor: theme.palette.neutral['n4-25'],
          }}
        /> */}
        <NavContainer>
          {MenuItems.map((mainNavItem, index) => (
            <MainNavItemWrapper key={index}>
              <StyledMenuItem
                onClickHandler={mainNavItem.onClick}
                active={Boolean(
                  window.location.pathname.includes(mainNavItem.path as string)
                )}
                icon={mainNavItem.icon}
                title={mainNavItem.title}
              />
            </MainNavItemWrapper>
          ))}
        </NavContainer>
        {/* <Divider
          sx={{
            backgroundColor: theme.palette.neutral['n4-25'],
          }}
        /> */}
        <ProfileContainer>
          <UserProfile />
        </ProfileContainer>
      </SidePanelContainer>
      {isAddWorkspaceModalOpen && (
        <CreateWorkspace
          isOpen={isAddWorkspaceModalOpen}
          loading={isLoading}
          closeHandler={() => setIsAddWorkspaceModalOpen(false)}
          submitActionHandler={addWorkspaceHandler}
        />
      )}
      {isContactModalOpen && (
        <ContactSupportModal
          loading={isLoading}
          isOpen={isContactModalOpen}
          closeHandler={() => dispatch(setIsContactModalOpen(false))}
          submitHandler={contactSupportHandler}
        />
      )}
    </>
  )
}

export default SidePanel
