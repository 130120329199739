import React from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

export type ComparatorFn<T> = (prevData: T) => T
export type OnChangeFn<T> = (changedData: T) => void

const useFilteredData = <D, T>(
  deps: D,
  initialValue: T,
  comparator: ComparatorFn<T>,
  onChange: OnChangeFn<T>
) => {
  const [filteredData, setFilteredData] = React.useState<T>(initialValue)

  useDeepCompareEffect(() => {
    setFilteredData((prev) => comparator(prev))
  }, [deps])

  useDeepCompareEffect(() => {
    onChange(filteredData)
  }, [filteredData])
}

export default useFilteredData
