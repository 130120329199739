import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

//component
import StyledToast from 'components/StyledToast/StyledToast'

//services
import * as authService from 'services/auth.service'
import * as workspaceService from 'services/workspace.service'

//redux
import { setIsAppLoading } from 'redux/appSlice'
import { refetchUserData, setCurrentWorkspace } from 'redux/userSlice'
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'
import handleServiceError from 'utils/handleServiceError'

const JoinWorkspaceHandler = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const inviteToken = authService.getWorkspaceInviteToken()

    if (inviteToken === null) {
      return
    }

    const joinWorkspace = async () => {
      console.log('USE_EFFECT:INVITE_USER_TO_WORKSPACE')
      try {
        dispatch(setIsAppLoading(true))
        const response = await workspaceService.joinWorkspace(inviteToken)
        authService.clearWorkspaceInviteToken()
        dispatch(refetchUserData())
        dispatch(setCurrentWorkspace(response.data.workspaceId))
        toast(
          <StyledToast
            variant="success"
            title={`You have joined the workspace ${''} successfully`}
          />
        )
      } catch (error) {
        handleServiceError(error)
      } finally {
        dispatch(setIsAppLoading(false))
      }
    }

    joinWorkspace()
  }, [])
  return <></>
}

export default JoinWorkspaceHandler
