import { useState } from 'react'
import { styled, useTheme } from '@mui/material'

//components
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import StyledModal from 'components/StyledModal/StyledModal'
import StyledInput from 'components/StyledInput/StyledInput'
import StyledButton from 'components/StyledButton/StyledButton'

const APIKeyForm = styled('form')(() => ({
  display: 'flex',
  flexDirection:'column',
  gap: '32px',
  flex: 1,
}))

const ActionContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '12px',
  flexGrow: 1,
}))

interface Props {
  isOpen: boolean
  closeHandler: () => void
  submitActionHandler: (name: string) => void
  loading?: boolean
}

const CreateAPIKey = ({
  loading = false,
  isOpen,
  closeHandler,
  submitActionHandler,
}: Props) => {
  const theme = useTheme()

  //local
  const [apiKeyName, setApiKeyName] = useState('')

  return (
    <StyledModal
      isOpen={isOpen}
      title="Create a new API key"
      closeHandler={closeHandler}
      width={640}
    >
      <APIKeyForm
        onSubmit={(e) => {
          e.preventDefault()
          submitActionHandler(apiKeyName)
        }}
      >
        <StyledInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setApiKeyName(e.target.value)
          }}
          autoFocus={true}
          id="create-new-api-key"
          label="Name your API key"
          placeholder="Enter name"
          labelIcon={
            <KeyOutlinedIcon sx={{ color: theme.palette.neutral['n4-50'] }} />
          }
          value={apiKeyName}
          isRequired={true}
          fullWidth={true}
        />
        <ActionContainer>
          <StyledButton
            onClickHandler={closeHandler}
            title="Cancel"
            variant="outlined"
            textVariant="base1"
          />
          <StyledButton
            type="submit"
            title={loading ? 'Creating...' : 'Create'}
            variant="primary"
            textVariant="base1"
            disabled={loading}
          />
        </ActionContainer>
      </APIKeyForm>
    </StyledModal>
  )
}

export default CreateAPIKey
