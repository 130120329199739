import { styled } from '@mui/material'

export const StyledTableRoot = styled('table')(() => ({
  width: '100%',
  borderCollapse: 'collapse',
  borderRadius: '0 0 20px 20px',
  minHeight: '300px',
}))

export const StyledTh = styled('th')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '12px 0',
  borderTop: `1px solid ${theme.palette.neutral['n5-50']}`,
  '&:nth-of-type(1)': {
    paddingLeft: '20px',
  },
}))

export const StyledTrBody = styled('tr')(({ theme, isClickAble }) => ({
  display: 'flex',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.neutral['n5-50']}`,
  cursor: isClickAble ? 'pointer' : 'default',
}))

export const StyledTd = styled('td')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '12px 0',
  '&:nth-of-type(1)': {
    paddingLeft: '20px',
  },
}))
