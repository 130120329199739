import { styled, useTheme } from '@mui/material'

//components
import Typography from '@mui/material/Typography'
import { IconContainer } from 'components/IconContainer/IconContainer'

const NavContainer = styled('div')<{ active: boolean; isSubMenu: boolean }>(
  ({ active, isSubMenu }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: isSubMenu ? '8px 20px 8px 25px' : '12px 10px',
    gap: '20px',
    cursor: 'pointer',
    userSelect: 'none',
    borderRadius: '8px',
    ...(active && {
      background: !isSubMenu
        ? 'linear-gradient(270deg, #323337 50%, rgba(70, 79, 111, 0.5) 100%)'
        : 'linear-gradient(270deg, #373737 50%, rgba(117, 117, 117, 0.29) 100%, rgba(66, 66, 66, 0.29) 100%)',
    }),
  })
)

const Wrapper = styled('div')(({}) => ({
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  //backgroundColor:'#fff' //use for debugging
}))

export const ColorContainer = styled('div')<{
  color: React.CSSProperties['color']
}>(({ color }) => ({
  display: 'flex',
  width: '14px',
  height: '14px',
  backgroundColor: color,
  borderRadius: '4px',
}))

interface Props {
  title: string
  active: boolean
  icon?: React.ReactNode
  iconImageSrc?: string
  onClickHandler?: () => void
  isSubMenu?: boolean
  chatbotColor?: React.CSSProperties['color']
}

const MenuItem = ({
  title,
  icon,
  iconImageSrc,
  active = false,
  isSubMenu = false,
  chatbotColor,
  onClickHandler = () => {},
}: Props) => {
  //hooks
  const theme = useTheme()

  return (
    <NavContainer
      onClick={onClickHandler}
      active={active}
      isSubMenu={isSubMenu}
    >
      {chatbotColor && (
        <Wrapper>
          <ColorContainer color={chatbotColor} />
        </Wrapper>
      )}
      {icon && icon}
      {/* {iconImageSrc && (
        <IconContainer src={iconImageSrc} width={24} height={24} />
      )} */}
      <Typography
        sx={{
          color: active
            ? theme.palette.neutral['n1-100']
            : theme.palette.neutral['n3-75'],
        }}
        variant="base2"
      >
        {title}
      </Typography>
    </NavContainer>
  )
}

export default MenuItem
