import React from 'react'
import { styled, useTheme } from '@mui/material'

//components
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { IconContainer } from 'components/IconContainer/IconContainer'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const Container = styled('div')<{ fullWidth?: boolean; width: number }>(
  ({ fullWidth, width }) => ({
    width: `${width}%`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '8px',
    ...(fullWidth && { width: `100%` }),
  })
)

const Title = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

const InputWrapper = styled('div')<{ fullWidth?: boolean }>(
  ({ theme, fullWidth }) => ({
    ...(fullWidth && { width: '100%' }),
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    padding: '14px 16px',
    gap: '12px',
    backgroundColor: theme.palette.neutral['n6-100'],
    border: `1px solid ${theme.palette.neutral['n5-100']}`,
    borderRadius: '12px',
    alignSelf: 'stretch',
    '&:focus-within': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  })
)

/**
 * Fix for autofill colors
 * @link https://github.com/mui/material-ui/issues/283
 * auto fill caret color
 * @link https://stackoverflow.com/questions/36545662/removing-input-cursor-colour-for-chrome-autocomplete
 */
export const Input = styled('input')(({ theme, disabled }) => ({
  width: '100%',
  background: 'none',
  height: '24px',
  border: 'none',
  outline: 'none',
  fontSize: '14px',
  userSelect: !disabled ? 'auto' : 'none',
  color: !disabled
    ? theme.palette.neutral['n1-100']
    : theme.palette.neutral['n4-50'],
  '&:disabled': {
    cursor: 'not-allowed',
  },
  '&::placeholder': {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: theme.palette.neutral['n4-50'],
  },
  '&:-webkit-autofill': {
    WebkitBoxShadow: `0 0 0 100px ${theme.palette.neutral['n6-100']} inset`,
    WebkitTextFillColor: `${theme.palette.neutral['n3-100']} !important`,
    color: `${theme.palette.neutral['n3-100']} !important`,
    caretColor: 'white',
  },
}))

const ErrorText = styled('div')(() => ({
  color: 'red',
  alignSelf: 'flex-start',
  minHeight: '20px',
  marginLeft: '16px',
  fontSize: '12px',
}))

interface Props {
  id?: string
  label: string
  placeholder: string
  isRequired: boolean
  value: string | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  inputType?: string
  autoFocus?: boolean
  fullWidth?: boolean
  width?: number
  style?: React.CSSProperties
  isError?: boolean
  errorMessage?: string | boolean | undefined
  errorComponent?: JSX.Element
  iconImageSrc?: string
  labelIcon?: React.ReactNode
  disabled?: boolean
  autoComplete?: 'on' | 'off'
  pattern?: string
  info?: string
}

const StyledInput = (props: Props) => {
  const {
    id,
    label,
    iconImageSrc,
    labelIcon,
    placeholder,
    value,
    onChange,
    onBlur = () => {},
    autoFocus = false,
    inputType = 'text',
    isRequired,
    fullWidth = false,
    width = 100,
    isError = false,
    errorMessage = '',
    errorComponent,
    disabled = false,
    autoComplete = 'off',
    pattern,
    info,
  } = props

  const theme = useTheme()

  let error: string | JSX.Element | boolean | undefined = ''
  if (isError) {
    error = errorComponent ? errorComponent : errorMessage
  }

  return (
    <Container fullWidth={fullWidth} width={width}>
      {label && (
        <Title>
          <Typography
            variant="base2"
            sx={{ color: theme.palette.neutral['n3-100'] }}
          >
            {label}
          </Typography>
          {info && (
            <Tooltip title={info} placement="right">
              <InfoOutlinedIcon
                fontSize="small"
                sx={{
                  color: theme.palette.neutral['n3-100'],
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )}
        </Title>
      )}
      <InputWrapper fullWidth={fullWidth}>
        {iconImageSrc && (
          <IconContainer src={iconImageSrc} width={20} height={20} />
        )}
        {labelIcon && labelIcon}
        <Input
          disabled={disabled}
          id={id}
          autoFocus={autoFocus}
          type={inputType}
          value={value}
          required={isRequired}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          spellCheck={false}
          autoComplete={autoComplete}
          pattern={pattern ? pattern : undefined}
        />
      </InputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  )
}

export default StyledInput
