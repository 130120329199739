import { styled, useTheme } from '@mui/material'

//components
import Typography from '@mui/material/Typography'
import StyledTable from 'components/StyledTables/StyledTable'
import {
  tableEntry,
  tableHeader,
} from 'components/StyledTables/TableComponents'
import { ComponentLoader } from 'components/Loader/Loader'

import { IUsageTable } from 'types/common'

const TableContainer = styled('section')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  overflowX: 'scroll',
  borderRadius: '0 0 20px 20px',
  color: 'black !important',
  backgroundColor: theme.palette.neutral['n6-100'],
  '&::-webkit-scrollbar': {
    height: '8px',
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    height: '4px',
    backgroundColor: theme.palette.neutral['n4-50'],
  },
}))

interface Props {
  usageData: IUsageTable[]
  loading: boolean
}

const UsageInfo = ({ loading, usageData }: Props) => {
  const theme = useTheme()

  const usageTable = {
    columns: [
      {
        Header: tableHeader('Resource name', theme),
        accessor: 'resourceName',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return tableEntry(row.values.resourceName, theme)
        },
      },
      {
        Header: tableHeader('Usage', theme),
        accessor: 'usage',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return tableEntry(row.values.usage, theme)
        },
      },
      {
        Header: tableHeader('Resource type', theme),
        accessor: 'resourceType',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return tableEntry(row.values.resourceType, theme)
        },
        //width: 150,
      },
      {
        Header: tableHeader('Provider-region', theme),
        accessor: 'provideRegion',
        Cell: ({ cell: { row } }: { cell: { row: any } }) => {
          return tableEntry(row.values.provideRegion, theme)
        },
        //width: 150,
      },
    ],
    rows: usageData,
  }

  return (
    <div>
      <TableContainer>
        {loading && <ComponentLoader />}
        {!loading && usageTable.rows.length === 0 && (
          <Typography
            sx={{ color: theme.palette.neutral['n1-100'] }}
            variant="body2"
          >
            No information available
          </Typography>
        )}
        {!loading && usageTable.rows.length > 0 && (
          <StyledTable
            pagination={true}
            pageCount={5}
            columns={usageTable.columns}
            data={usageTable.rows}
            hiddenColumns={[]} //Note: pass 'accessor' value not 'Header' name
          />
        )}
      </TableContainer>
    </div>
  )
}

export default UsageInfo
