import { IUsageInfo, IUsageTable } from 'types/common'
import { timeFormatter, getFormattedProviderRegionInfo } from './utils'

const usageToTabularData = (usageInfo: IUsageInfo) => {
  const usageTable: IUsageTable[] = []

  //Normal usage
  Object.keys(usageInfo.usage).forEach((providerRegionName) => {
    Object.keys(usageInfo.usage[providerRegionName]).forEach((resourceName) => {
      if (usageInfo.usage[providerRegionName][resourceName] === 0) {
        return
      }
      usageTable.push({
        resourceName: resourceName,
        resourceType: 'On demand',
        usage: timeFormatter(
          Math.ceil(usageInfo.usage[providerRegionName][resourceName])
        ),
        provideRegion: getFormattedProviderRegionInfo(providerRegionName),
      })
    })
  })

  //Spot usage
  Object.keys(usageInfo.spot_usage).forEach((providerRegionName) => {
    Object.keys(usageInfo.spot_usage[providerRegionName]).forEach(
      (resourceName) => {
        if (usageInfo.spot_usage[providerRegionName][resourceName] === 0) {
          return
        }
        usageTable.push({
          resourceName: resourceName,
          resourceType: 'On demand',
          usage: timeFormatter(
            Math.ceil(usageInfo.spot_usage[providerRegionName][resourceName])
          ),
          provideRegion: getFormattedProviderRegionInfo(providerRegionName),
        })
      }
    )
  })

  return usageTable
}

export default usageToTabularData
