import { styled } from '@mui/material'

export const TabItem = styled('div')<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    minWidth: 'fit-content',
    padding: '8px 16px 8px 16px',
    cursor: 'pointer',
    color: isActive
      ? theme.palette.neutral['n1-100']
      : theme.palette.neutral['n4-100'],
    borderBottom: isActive
      ? `2px solid ${theme.palette.neutral['n1-100']}`
      : '2px solid transparent',
    transition: 'border-bottom 0.2s',
  })
)
