import axios from 'axios'

import { getAccessToken } from './auth.service'

//types
import { IDeployment } from 'types/deployment'
import {
  DefaultResponse,
  ILogs,
  ApiStatus,
  toStatusType,
  DataResponse,
} from 'types/common'

const BASE_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}/inference/backend/v1/deployments`

type ApiDeployment = IDeployment<ApiStatus>

interface DeploymentsResponse extends DefaultResponse {
  data: ApiDeployment[]
}

interface DeploymentResponse extends DefaultResponse {
  data: ApiDeployment
}

const transformDeploymentResponse = (
  deployment: ApiDeployment
): IDeployment => {
  return {
    ...deployment,
    status: toStatusType(deployment.status),
  }
}
export const getDeployments = async (
  workspaceId: string
): Promise<IDeployment[]> => {
  const { data } = await axios.get<DeploymentsResponse>(`${BASE_URL}/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
  })
  return data.data.map(transformDeploymentResponse)
}

export const getDeploymentByName = async (
  workspaceId: string,
  deploymentName: string | undefined
): Promise<IDeployment> => {
  const { data } = await axios.get<DeploymentResponse>(
    `${BASE_URL}/${deploymentName}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        workspaceId: workspaceId,
      },
    }
  )
  return transformDeploymentResponse(data.data)
}

export const getDeploymentLogs = async (
  workspaceId: string,
  deploymentName: string | undefined
): Promise<ILogs> => {
  const {
    data: { data },
  } = await axios.get<DataResponse<ILogs>>(
    `${BASE_URL}/${deploymentName}/logs`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        workspaceId: workspaceId,
      },
    }
  )
  return data
}

export const deleteDeployment = async (
  workspaceId: string,
  name: string
): Promise<DefaultResponse> => {
  const result = await axios.delete(`${BASE_URL}/${name}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
    params: {
      hard_delete: true,
      reason: 'test',
    },
  })
  return result.data
}
