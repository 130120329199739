import { memo } from 'react'
import { styled } from '@mui/material'
import { Outlet } from 'react-router-dom'

//layout
import AppLayout from 'layouts/AppLayout'

const ExecutionContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  backgroundColor: theme.palette.neutral['n6-100'],
}))

const DashboardOutlet = () => {
  return (
    <AppLayout>
      <ExecutionContainer>
        <Outlet />
      </ExecutionContainer>
    </AppLayout>
  )
}

export default memo(DashboardOutlet)
