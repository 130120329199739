// order is very important
export enum Status {
  // processing states
  Default,
  Deploying,
  Cancelling,
  Running,
  // result states
  Failed,
  Cancelled,
  Successful,
}

export type ApiStatus =
  | 'default'
  | 'deploying'
  | 'cancelling'
  | 'running'
  | 'failed'
  | 'cancelled'
  | 'successful'

export interface DefaultResponse {
  success: boolean
  message?: string
}

export interface DataResponse<T> extends DefaultResponse {
  data: T
}

export type ILogs = Record<string, string>

export interface IMetadata {
  error: string | null
  ended_at: number | null
  started_at: number
  started_by: string
  started_reason: string
  cancelled_by: string | null
  cancelled_at: number | null
  cancelled_reason: null
  deleted_by: string | null
  deleted_at: number | null
  deleted_reason: string | null
  archived_by: string | null
  archived_at: number | null
  archived_reason: string | null
}

export interface IContainerSpec {
  machine_type: string
  spot: boolean
  image: string
  command?: string[] | null
  args?: string[] | null
  env: any
}

export interface IQuotaInfo {
  workspace_id: string
  quotas_usage: Record<string, { current_usage: number; max_quota: number }>
}

export interface IUsageInfo {
  workspace_id: string
  start_timestamp: number
  end_timestamp: number
  usage: Record<string, Record<string, number>>
  spot_usage: Record<string, Record<string, number>>
}

export type DropDownSelectOption = {
  label: string | React.ReactNode
  value?: any
  action?: () => void
}

export type WorkspaceRoles = 'owner' | 'admin' | 'billing' | 'write' | 'read'
export interface IMember {
  id: string
  name: string
  email: string
  role: WorkspaceRoles
}

export interface IWorkspace {
  id: string
  name: string
  owner: string
  members: IMember[]
}

export interface IWorkspaceResponse {
  workspace: {
    _id: string
    name: string
    owner: {
      _id: string
      name: string
      email: string
    }
    members: {
      user: {
        _id: string
        name: string
        email: string
      }
      role: WorkspaceRoles
    }[]
  }
  role: WorkspaceRoles
}

export interface IQuotasTable {
  resourceName: string
  currentUsage: number
  maxQuota: number
}

export interface IUsageTable {
  resourceName: string
  usage: string | number
  resourceType: 'On demand' | 'Spot'
  provideRegion: string
}

export interface MachinesPricing {
  [providerRegion: string]: {
    [machine: string]: {
      onDemand: number
      spot: number
    }
  }
}

export const toStatusType = (status: ApiStatus): Status => {
  switch (status) {
    case 'deploying':
      return Status.Deploying
    case 'cancelling':
      return Status.Cancelling
    case 'running':
      return Status.Running
    case 'failed':
      return Status.Failed
    case 'cancelled':
      return Status.Cancelled
    case 'successful':
      return Status.Successful
    case 'default':
    default:
      return Status.Default
  }
}
