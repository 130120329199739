import { Theme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

export type ChatResponseType = 'positive' | 'negative' | 'default'

export const tableEntryColor = (variant: ChatResponseType, theme: Theme) => {
  const tableEntryColor: Record<string, React.CSSProperties['color']> = {
    positive: theme.palette.accent.a2,
    negative: theme.palette.accent.a1,
    default: theme.palette.neutral['n1-100'],
  }
  return tableEntryColor[variant]
}

export const tableEntry = (
  entry: string | number,
  theme: Theme,
  variant?: ChatResponseType
) => {
  return (
    <Typography
      sx={{
        color: tableEntryColor(variant ? variant : 'default', theme),
        fontWeight: 500,
      }}
      variant='base2'
    >
      {entry}
    </Typography>
  )
}

export const tableEntryAnchor = (
  entry: string | number,
  theme: Theme,
  href: string,
  variant?: ChatResponseType
) => {
  return (
    <a href={href} target='_blank'>
      <Tooltip title={entry} placement='top-end'>
        <Typography
          sx={{
            display: 'inline-block',
            width: '550px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: tableEntryColor(variant ? variant : 'default', theme),
            fontWeight: 500,
          }}
          variant='base2'
        >
          {entry}
        </Typography>
      </Tooltip>
    </a>
  )
}

export const tableHeader = (entry: string, theme: Theme) => {
  return (
    <Typography
      sx={{ color: theme.palette.neutral['n4-100'], fontWeight: 500 }}
      variant='base2'
    >
      {entry}
    </Typography>
  )
}
