import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

//redux
import { setIsLoggedIn } from 'redux/userSlice'

//service
import * as authService from 'services/auth.service'
import StyledToast from 'components/StyledToast/StyledToast'

const AuthSuccess = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const handleUrlParams = async () => {
      const token = searchParams.get('access_token')

      if (token) {
        authService.storeToken(token)
        //console.log("Token:", token)
        dispatch(setIsLoggedIn(true))
        // toast(<StyledToast variant="success" title="Logged in successfully" />)
        navigate('/', { replace: true })
      } else {
        toast(<StyledToast variant="error" title="Login failed" />)
        navigate('/login', { replace: true })
      }
    }
    handleUrlParams()
  }, [])

  return (<div>Verifying....</div>)
}

export default AuthSuccess
