import { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import { useSelector } from 'react-redux'

//redux
import { selectCurrentWorkspace } from 'redux/userSlice'

//types
import { ILogs } from 'types/common'

const Container = styled('div')(({ theme }) => ({
  flex: '1 1 0',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '16px',
  backgroundColor: theme.palette.neutral['n7-100'],
  border: `1px solid ${theme.palette.neutral['n5-100']}`,
  borderRadius: '8px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': { width: '8px' },
  '&::-webkit-scrollbar-track': {},
  '&::-webkit-scrollbar-thumb': {
    height: '20%',
    backgroundColor: theme.palette.neutral['n5-100'],
  },
}))

const PreTag = styled('pre')(({ theme }) => ({
  width: '100%',
  height: '100%',
  whiteSpace: 'pre-wrap',
  resize: 'none',
  borderRadius: '4px',
  fontSize: '14px',
  padding: '16px 24px',
  outline: 'none !important',
  color: theme.palette.neutral['n1-100'],
}))

interface Props {
  resourceName: string
  nodeIndex: number
  getLogsFn: (workspaceId: string, resourceName: string) => Promise<ILogs>
}

const LogVisualizer = ({ getLogsFn, resourceName, nodeIndex }: Props) => {
  //redux
  const currentWorkSpace = useSelector(selectCurrentWorkspace)

  //local
  const [logs, setLogs] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!currentWorkSpace) {
      return
    }

    const fetchData = async () => {
      try {
        setIsLoading(true)
        const logsData = await getLogsFn(
          String(currentWorkSpace.id),
          resourceName
        )
        const logs = logsData[nodeIndex]
        setLogs(logs ?? '')
      } catch (err) {
        console.log(err)
        setLogs('')
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [currentWorkSpace, resourceName])

  return (
    <Container>
      <PreTag id="myTextArea" spellCheck={false}>
        {isLoading && <>Fetching logs...</>}
        {!isLoading && logs.length === 0 && <>No logs available</>}
        {logs}
      </PreTag>
    </Container>
  )
}

export default LogVisualizer
