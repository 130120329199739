import axios, { AxiosResponse } from 'axios'

import { ACCESS_TOKEN_KEY, WORKSPACE_TOKEN_KEY } from 'utils/constants'

const BASE_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_AUTHENTICATION_PATH}`

interface DefaultResponse {
  success: boolean
  message?: string
}

interface SignupInput {
  name: string
  email: string
  password: string
  isAgreed: boolean
  referral?: string | null
}

interface LoginInput {
  email: string
  password: string
}

interface LoginResponse extends DefaultResponse {
  data: {
    token: string
    redirectUrl?: string
    isSubscribed?: boolean
  }
}

interface SignupResponse extends DefaultResponse {
  data: {
    token: string
    redirectUrl?: string
    isSubscribed?: boolean
  }
}

interface VerifyEmailResponse extends DefaultResponse {
  data: {
    token: string
  }
}

export const login = async (loginInfo: LoginInput): Promise<LoginResponse> => {
  const result: AxiosResponse = await axios.post(
    `${BASE_URL}/v1/auth/login`,
    loginInfo,
  )
  return result.data
}

export const signup = async (
  signupInfo: SignupInput
): Promise<SignupResponse> => {
  const result: AxiosResponse = await axios.post(
    `${BASE_URL}/v1/auth/signup`,
    signupInfo,
  )
  return result.data
}

export const verifyEmail = async (
  token: string
): Promise<VerifyEmailResponse> => {
  const result: AxiosResponse = await axios.get(
    `${BASE_URL}/v1/auth/verify-email`,
    {
      params: { token: token },
    }
  )
  return result.data
}

export const forgotPassword = async (
  email: string
): Promise<DefaultResponse> => {
  const res: AxiosResponse = await axios.post(
    `${BASE_URL}/v1/auth/forgot-password`,
    { email: email },
  )
  return res.data
}

export const resetPassword = async (
  token: string,
  password: string
): Promise<DefaultResponse> => {
  const res: AxiosResponse = await axios.post(
    `${BASE_URL}/v1/auth/reset-password?token=${token}`,
    { password },
  )
  return res.data
}

export const clearToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
}

export const storeToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

export const clearWorkspaceInviteToken = () => {
  localStorage.removeItem(WORKSPACE_TOKEN_KEY)
}

export const storeWorkspaceInviteToken = (token: string) => {
  localStorage.setItem(WORKSPACE_TOKEN_KEY, token)
}

export const getWorkspaceInviteToken = () => {
  return localStorage.getItem(WORKSPACE_TOKEN_KEY)
}
