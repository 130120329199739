import { useState, useEffect } from 'react'
import { styled, useTheme } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

//components
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Logo from './components/Logo/Logo'
import Loader from 'components/Loader/Loader'
import StyledInput from 'components/StyledInput/StyledInput'
import { StyledForm } from './components/SharedComponents'
import StyledButton from 'components/StyledButton/StyledButton'
import OAuthBtn from './components/OAuthBtn/OAuthBtn'
import DividerOR from 'components/DividerOR/DividerOR'
import { IconContainer } from 'components/IconContainer/IconContainer'
import StyledToast from 'components/StyledToast/StyledToast'

//redux
import { selectReferral } from 'redux/appSlice'
import { setIsLoggedIn } from 'redux/userSlice'

//services
import { signup, login, storeToken } from 'services/auth.service'

//assets
import EmailIcon from 'assets/icons/email.png'
import PasswordIcon from 'assets/icons/password.png'
import GoogleIcon from 'assets/icons/google-login.png'
import UserIcon from 'assets/icons/user.png'
import MicrosoftIcon from 'assets/icons/microsoft-login.png'

//utils
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'
import handleServiceError from 'utils/handleServiceError'

const BASE_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_AUTHENTICATION_PATH}`

const LoginContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  width: '540px',
  padding: '48px 60px',
  borderRadius: '20px',
  marginTop: '60px',
  gap: '18px',
  backgroundColor: theme.palette.neutral['n6-100'],
  '@media (max-width: 768px)': {
    width: '90%',
    flexDirection: 'column',
    marginTop: '16px',
    marginBottom: '16px',
    padding: '16px 24px',
  },
}))

const ToggleContainer = styled('div')(({ theme }) => ({
  height: '48px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px',
  backgroundColor: theme.palette.neutral['n7-100'],
  borderRadius: '12px',
}))

const ToggleItem = styled('div')<{ isActive: boolean; disabled?: boolean }>(
  ({ theme, isActive }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    flex: 1,
    cursor: 'pointer',
    color: isActive
      ? theme.palette.neutral['n3-100']
      : theme.palette.neutral['n4-100'],
    backgroundColor: isActive
      ? theme.palette.neutral['n6-100']
      : theme.palette.neutral['n7-100'],
    borderRadius: '12px',
  })
)

const PasswordContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
}))

const TermsBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '8px 0',
  gap: '8px',
}))

const StyledAnchor = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const AUTH_TABS = {
  SIGN_IN: 'signin',
  CREATE_ACC: 'create',
}

interface FormValues {
  name: string
  email: string
  password: string
  isAgreed: boolean
  referral?: string | null
}

const Login = () => {
  //hooks
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [URLSearchParams] = useSearchParams()

  //redux
  const referral = useSelector(selectReferral)

  //local
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeToggle, setActiveToggle] = useState(AUTH_TABS.CREATE_ACC)
  const [oAuthConsentModalInfo, setOAuthConsentModalInfo] = useState({
    isOpen: false,
    provider: '',
  })

  useEffect(() => {
    const defaultActiveToggle = URLSearchParams.get('active')
    if (defaultActiveToggle) {
      setActiveToggle(defaultActiveToggle)
    }
  }, [])

  const initialValues: FormValues = {
    name: '',
    email: '',
    password: '',
    isAgreed: false,
  }

  const resetOAuthConsent = () => {
    setOAuthConsentModalInfo({
      isOpen: false,
      provider: '',
    })
  }

  async function loginUser(
    formObject: FormValues,
    actions: FormikHelpers<FormValues>
  ) {
    setIsLoading(true)

    try {
      const data = await login({
        email: formObject.email,
        password: formObject.password,
      })

      actions.setSubmitting(false)

      actions.resetForm()

      if (data.data.redirectUrl) {
        // toast(
        //   <StyledToast variant="error" title="Email verification pending" />
        // )
        window.location.href = data.data.redirectUrl
        return
      }

      storeToken(data.data.token)
      dispatch(setIsLoggedIn(true))
      // toast(<StyledToast variant="success" title="Logged in successfully" />)
      navigate('/', { replace: true })
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const registerUser = async (
    formObject: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    let signUpData: FormValues = {
      name: formObject.name,
      email: formObject.email,
      password: formObject.password,
      isAgreed: true,
      ...(referral && referral !== '' && { referral: referral }),
    }
    setIsLoading(true)
    try {
      await signup(signUpData)
      actions.setSubmitting(false)
      actions.resetForm()
      // toast(
      //   <StyledToast
      //     variant="success"
      //     title="Verify your email to complete the signup"
      //   />
      // )
      navigate(`/verify-email?redirect=true`, { replace: true })
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loginWithGoogle = () => {
    //setIsLoading(true)
    //resetOAuthConsent()
    const googleLoginUrl = new URL(`${BASE_URL}/v1/oauth2/google`)

    if (referral) {
      googleLoginUrl.searchParams.set('referral', referral)
    }

    window.open(googleLoginUrl.toString(), '_self', 'noopener,noreferrer')
    //setIsLoading(false)
  }

  const loginWithAzure = () => {
    //setIsLoading(true)
    //resetOAuthConsent()
    const azureLoginUrl = new URL(`${BASE_URL}/v1/oauth2/azure`)

    if (referral) {
      azureLoginUrl.searchParams.set('referral', referral)
    }

    window.open(azureLoginUrl.toString(), '_self', 'noopener,noreferrer')
    //setIsLoading(false)
  }

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <LoginContainer>
          <Logo />
          <ToggleContainer>
            <ToggleItem
              disabled={true}
              onClick={() => {
                setActiveToggle(AUTH_TABS.CREATE_ACC)
              }}
              isActive={activeToggle === AUTH_TABS.CREATE_ACC}
            >
              <Typography variant="base2">Create account</Typography>
            </ToggleItem>
            <ToggleItem
              onClick={() => setActiveToggle(AUTH_TABS.SIGN_IN)}
              isActive={activeToggle === AUTH_TABS.SIGN_IN}
            >
              <Typography variant="base2">Sign in</Typography>
            </ToggleItem>
          </ToggleContainer>
          <Stack spacing={2}>
            <OAuthBtn
              title="Continue with Google"
              onClickHandler={loginWithGoogle}
              disabled={false}
              startIcon={
                <IconContainer src={GoogleIcon} width={24} height={24} />
              }
            />
            {/* <OAuthBtn
              title="Continue with Microsoft"
               onClickHandler={() => setOAuthConsentModalInfo((prev)=>({
                isOpen: true,
                provider: 'Microsoft'
              }))}
              onClickHandler={loginWithAzure}
              disabled={false}
              startIcon={
                <IconContainer src={MicrosoftIcon} width={24} height={24} />
              }
            /> */}
          </Stack>
          <DividerOR />
          <Formik
            initialValues={initialValues}
            validateOnMount={false}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().required('Please enter your password'),
              ...(activeToggle === AUTH_TABS.CREATE_ACC && {
                name: Yup.string().max(255).required('Name is required'),
                password: Yup.string()
                  .min(8, 'Password must be at least 8 characters')
                  .max(255)
                  .matches(
                    /^(?=.*[a-z])/,
                    `Password should contain one lower case letter`
                  )
                  .matches(
                    /^(?=.*[A-Z])/,
                    'Password should contain one upper case letters'
                  )
                  .matches(
                    /^(?=.*[0-9])/,
                    'Password should contain one number'
                  ),
              }),
            })}
            onSubmit={(
              values: FormValues,
              actions: FormikHelpers<FormValues>
            ) => {
              if (activeToggle === AUTH_TABS.SIGN_IN) {
                loginUser(values, actions)
              } else {
                registerUser(values, actions)
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                {activeToggle === AUTH_TABS.CREATE_ACC && (
                  <StyledInput
                    inputType="name"
                    autoFocus={false}
                    id="name"
                    label=""
                    placeholder="Enter your name"
                    iconImageSrc={UserIcon}
                    value={values.name}
                    isRequired={true}
                    fullWidth={true}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isError={Boolean(touched.name && errors.name)}
                    errorMessage={touched.name && errors.name}
                    autoComplete="on"
                  />
                )}
                <StyledInput
                  inputType="email"
                  autoFocus={false}
                  id="email"
                  label=""
                  placeholder="Email address"
                  iconImageSrc={EmailIcon}
                  value={values.email}
                  isRequired={true}
                  fullWidth={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={Boolean(touched.email && errors.email)}
                  errorMessage={touched.email && errors.email}
                  autoComplete="on"
                />
                <PasswordContainer>
                  <StyledInput
                    inputType="password"
                    autoFocus={false}
                    id="password"
                    label=""
                    placeholder="Password"
                    iconImageSrc={PasswordIcon}
                    value={values.password}
                    isRequired={true}
                    fullWidth={true}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isError={Boolean(touched.password && errors.password)}
                    errorMessage={touched.password && errors.password}
                    autoComplete="on"
                  />
                  {activeToggle === AUTH_TABS.SIGN_IN && (
                    <Link
                      underline="none"
                      href="/forgot-password"
                      style={{ alignSelf: 'flex-end' }}
                    >
                      <Typography sx={{ fontWeight: 500 }} variant="base2">
                        Forgot password?
                      </Typography>
                    </Link>
                  )}
                  {activeToggle === AUTH_TABS.CREATE_ACC && (
                    <TermsBox>
                      {/* <Checkbox
                        sx={{
                          color: theme.palette.neutral['n5-100'],
                          '&:hover': { backgroundColor: 'transparent' },
                          '&.Mui-checked': {
                            color: theme.palette.primary.main,
                          },
                          padding: 0,
                        }}
                        required={true}
                        value={values.isAgreed}
                        onChange={handleChange}
                      /> */}
                      <Typography
                        variant="caption1"
                        sx={{
                          color: theme.palette.neutral['n3-100'],
                        }}
                      >
                        By clicking Continue, you agree to our{' '}
                        <StyledAnchor
                          href="https://www.stochastic.ai/terms-of-service"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms
                        </StyledAnchor>{' '}
                        and that you have read our{' '}
                        <StyledAnchor
                          href="https://www.stochastic.ai/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </StyledAnchor>
                      </Typography>
                    </TermsBox>
                  )}
                </PasswordContainer>
                <StyledButton
                  type="submit"
                  title="Continue"
                  variant="primary"
                  textVariant="base1"
                  disabled={isSubmitting}
                />
              </StyledForm>
            )}
          </Formik>
        </LoginContainer>
      )}
    </>
  )
}

export default Login
