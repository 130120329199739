import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material'
import { useSearchParams, useNavigate } from 'react-router-dom'

//components
import PageHeader from 'components/PageHeader/PageHeader'
import { TabContainer } from 'components/StyledTab/TabContainer'
import { TabItem } from 'components/StyledTab/TabItem'
import QuotasInfo from './components/QuotasInfo/QuotasInfo'
import UsageInfo from './components/UsageInfo/UsageInfo'
import useFetchData from 'hooks/useFetchData'
import ActionsWithRefresh from 'components/ActionsWithRefresh'
import {
  GetQuotas,
  GetUsage,
  getQuotasInfo,
  getUsageInfo,
} from 'services/usageQuota.service'
import { useSelector } from 'react-redux'
import { selectCurrentWorkspace } from 'redux/userSlice'
import { IQuotasTable, IUsageTable } from 'types/common'
import usageToTabularData from 'utils/usageToTabularData'
import quotasToTabularData from 'utils/quotasToTabularData'
import pollAndSync from 'utils/pollAndSync'

const PageContent = styled('div')(() => ({
  flex: '1 1 auto',
  width: '100% ',
  display: 'flex',
  flexDirection: 'column',
  padding: '32px ',
}))

const TabComponentWrapper = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '32px 0',
  overflowY: 'auto',
}))

const BILLING_TABS = [
  {
    name: 'Usage',
    value: 'usage',
  },
  {
    name: 'Quotas',
    value: 'quotas',
  },
]

const Billing = () => {
  const [quotas, setQuotas] = useState<IQuotasTable[]>([])
  const [usageData, setUsageData] = useState<IUsageTable[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const [URLSearchParams] = useSearchParams()
  const tab = URLSearchParams.get('tab')
  const currentWorkSpace = useSelector(selectCurrentWorkspace)

  const { fetch, loading: refreshing } = useFetchData()
  const cancelFn = useRef<() => void>(() => {})

  let defaultActiveTab = BILLING_TABS[0].value

  if (tab) {
    defaultActiveTab = tab
  }

  //local
  const [activeSubTab, setActiveSubTab] = useState<string>(defaultActiveTab)

  useEffect(() => {
    if (!currentWorkSpace) return
    if (activeSubTab !== BILLING_TABS[0].value) return
    setIsLoading(true)
    cancelFn.current()
    const canceller = pollAndSync<GetUsage, IUsageTable[]>(
      () => getUsageInfo(currentWorkSpace.id),
      (res) => usageToTabularData(res.data),
      (data) => setUsageData(data),
      () => false,
      (err) => console.error(err),
      () => setIsLoading(false),
      10,
      2 * 60
    )
    cancelFn.current = canceller

    return () => canceller()
  }, [currentWorkSpace, activeSubTab])

  useEffect(() => {
    if (!currentWorkSpace) return
    if(activeSubTab !== BILLING_TABS[1].value) return
    setIsLoading(true)
    cancelFn.current()
    const canceller = pollAndSync<GetQuotas, IQuotasTable[]>(
      () => getQuotasInfo(currentWorkSpace.id),
      (res) => quotasToTabularData(res.data),
      (data) => setQuotas(data),
      () => false,
      (err) => console.error(err),
      () => setIsLoading(false),
      10,
      2 * 60
    )
    cancelFn.current = canceller

    return () => canceller()
  }, [currentWorkSpace, activeSubTab])

  useEffect(() => {
    navigate(`/billing?tab=${activeSubTab}`)
  }, [activeSubTab, navigate])

  const handleRefresh = () => {
    if (!currentWorkSpace) return
    switch (activeSubTab) {
      case BILLING_TABS[0].value:
        fetch(
          getUsageInfo(currentWorkSpace.id),
          (res) => usageToTabularData(res.data),
          (data) => setUsageData(data)
        )
        break
      case BILLING_TABS[1].value:
        fetch(
          getQuotasInfo(currentWorkSpace.id),
          (res) => quotasToTabularData(res.data),
          (data) => setQuotas(data)
        )
        break
      default:
        break
    }
  }

  return (
    <>
      <PageHeader
        title="Billing"
        path="/billing"
        loading={refreshing}
        actions={<ActionsWithRefresh onRefresh={handleRefresh} />}
      />
      <PageContent>
        <TabContainer>
          {React.Children.toArray(
            BILLING_TABS.map((tab) => (
              <TabItem
                isActive={activeSubTab === tab.value}
                onClick={() => setActiveSubTab(tab.value)}
              >
                {tab.name}
              </TabItem>
            ))
          )}
        </TabContainer>
        <TabComponentWrapper>
          {activeSubTab === BILLING_TABS[0].value && (
            <UsageInfo usageData={usageData} loading={isLoading} />
          )}
          {activeSubTab === BILLING_TABS[1].value && (
            <QuotasInfo quotas={quotas} loading={isLoading} />
          )}
        </TabComponentWrapper>
      </PageContent>
    </>
  )
}

export default Billing
