import axios, { AxiosResponse } from 'axios'

import { getAccessToken } from './auth.service'

//types
import { IExecution } from 'types/execution'
import {
  DefaultResponse,
  ILogs,
  ApiStatus,
  toStatusType,
  DataResponse,
} from 'types/common'

const BASE_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}/executor/backend/v1/jobs`

type ApiExecution = IExecution<ApiStatus>

interface ExecutionResponse extends DefaultResponse {
  data: ApiExecution
}

interface ExecutionsResponse extends DefaultResponse {
  data: ApiExecution[]
}

const transformExecutionResponse = (execution: ApiExecution): IExecution => {
  return {
    ...execution,
    status: toStatusType(execution.status),
  }
}

export const getExecutions = async (
  workspaceId: string
): Promise<IExecution[]> => {
  const { data } = await axios.get<ExecutionsResponse>(`${BASE_URL}/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
  })
  return data.data.map(transformExecutionResponse)
}

export const getExecutionByName = async (
  workspaceId: string,
  jobName: string | undefined
): Promise<IExecution> => {
  const { data } = await axios.get<ExecutionResponse>(
    `${BASE_URL}/${jobName}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        workspaceId: workspaceId,
      },
    }
  )
  return transformExecutionResponse(data.data)
}

export const getExecutionLog = async (
  workspaceId: string,
  jobName: string | undefined
): Promise<ILogs> => {
  const {
    data: { data },
  } = await axios.get<DataResponse<ILogs>>(`${BASE_URL}/${jobName}/logs`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
  })
  return data
}

export const deleteExecution = async (
  workspaceId: string,
  name: string
): Promise<DefaultResponse> => {
  const result = await axios.delete(`${BASE_URL}/${name}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
    params: {
      hard_delete: true,
      reason: 'test',
    },
  })
  return result.data
}
