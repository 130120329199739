import { memo } from 'react'
import { Outlet } from 'react-router-dom'

//layout
import AppLayout from 'layouts/AppLayout'

const NotebooksOutlet = () => {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  )
}

export default memo(NotebooksOutlet)
