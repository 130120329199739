import { styled } from '@mui/material'

//components
import Typography from '@mui/material/Typography'

const NotFoundWrapper = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.neutral['n1-100'],
  backgroundColor: theme.palette.neutral['n7-100'],
}))

const NotFound = () => (
  <NotFoundWrapper>
    <Typography variant="h4">404 | Page not found</Typography>
    <Typography variant="body1">
      Sorry 😔, we couldn't find what you were looking for
    </Typography>
  </NotFoundWrapper>
)

export default NotFound
