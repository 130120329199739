import { styled } from '@mui/material'
import Button from '@mui/material/Button'

const defaultBorderRadius = 4

export const LogoText = styled('p')(({ theme }) => ({
  userSelect: 'none',
  fontWeight: '500',
  fontSize: '24px',
  marginBottom: '32px',
}))

export const IconSvg = styled('img')(({ theme }) => ({
  display: 'inline-block',
  width: 24,
  height: 'auto',
}))

export const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  textTransform: 'none',
  color: '#fafafa',
  backgroundColor: '#323232',
  border: '1px solid transparent',
  borderRadius: `${defaultBorderRadius}px`,
  '&:hover:not([disabled])': {
    backgroundColor: '#e2e2e2',
    color: '#424242',
    border: '1px solid #323232',
  },
  '@media (max-width: 600px)': {},
}))
