export const getFileExtension = (filename: string): string => {
  var ext = /^.+\.([^.]+)$/.exec(filename)
  return ext == null ? '' : ext[1]
}

export const getFileNameFromS3Url = (url: string): string | undefined => {
  const pathList = url.split('/')
  const fileNameWithPrefix = pathList.pop()
  const fileName = fileNameWithPrefix?.split('-')
  return fileName?.pop()
}

export function convertUrlToTitle(url: string): string {
  const trimmedUrl: string = url.replace(/\/$/, '') // Remove trailing slash
  const pathname: string = new URL(trimmedUrl).pathname
  const segments: string[] = pathname
    .split('/')
    .filter((segment) => segment !== '')
  const lastSegment: string = segments.pop() || '' // Get the last segment or an empty string if there are no segments
  const sanitizedTitle: string = lastSegment.replace(/[-_]/g, ' ')
  const title: string = sanitizedTitle.replace(/\b\w/g, (char) =>
    char.toUpperCase()
  )
  return title.trim() !== '' ? title : new URL(trimmedUrl).host
}

export function sanitizeUrl(url: string) {
  return url.replace(/\/+$/, '')
  //.replace(/www\./, '')
}

export function validHostName(str: string) {
  const pattern = /(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
  return pattern.test(str)
}

/**
 * @link https://snyk.io/blog/secure-javascript-url-validation/
 * @param url
 * @returns
 */
export function validateURL(url: string): {
  success: boolean
  url: string
  message: string
} {
  const data = {
    success: true,
    // Remove trailing slash
    url: sanitizeUrl(url),
    message: '',
  }

  let givenURL: URL

  // check for the validity
  try {
    givenURL = new URL(data.url)
  } catch (error) {
    data.success = false
    data.message = 'Enter valid link. Eg: https://example.com'
    return data
  }

  //check for https
  if (givenURL.protocol !== 'https:') {
    data.success = false
    data.message = 'Enter a secure link. Eg: https://example.com'
    return data
  }

  //check for proper hostname
  if (!validHostName(givenURL.hostname)) {
    data.success = false
    data.message = 'Enter valid link. Eg: https://example.com'
    return data
  }

  return data
}

export const timeFormatter = (time: number): string => {
  if (time === 0) return `${0} sec`

  if (time > 60) {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes} min ${seconds} sec`
  }

  return `${time} sec`
}

export const getFormattedProviderRegionInfo = (
  providerRegion: string
): string => {
  const [provider, region] = providerRegion.split('_') //Underscore

  return `${provider}-${region}`
}

export const capitalizeFLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}
