import { useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

//components
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader/Loader'
import StyledInput from 'components/StyledInput/StyledInput'
import { StyledForm } from './components/SharedComponents'
import StyledButton from 'components/StyledButton/StyledButton'
import Logo from './components/Logo/Logo'
import StyledToast from 'components/StyledToast/StyledToast'

//services
import { forgotPassword } from 'services/auth.service'

//assets
import EmailIcon from 'assets/icons/email.png'
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'
import handleServiceError from 'utils/handleServiceError'

const Container = styled(Stack)<{ status: string }>(({ theme, status }) => ({
  width: status === 'Success' ? '768px' : '540px',
  display: 'flex',
  alignSelf: 'flex-start',
  padding: '60px',
  borderRadius: '20px',
  marginTop: '60px',
  gap: '18px',
  userSelect: 'none',
  color: theme.palette.neutral['n3-100'],
  backgroundColor: theme.palette.neutral['n6-100'],
  '@media (max-width: 768px)': {
    width: '90%',
    flexDirection: 'column',
    marginTop: '16px',
    marginBottom: '16px',
    padding: '16px 24px',
  },
}))

const Notification = () => {
  return (
    <>
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        Check your email
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        Please check the email address registered with xCloud for instruction to
        reset the password. If you do not receive the password reset email
        within a few minutes, please check your spam folder or other filtering
        tools.
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        Did'nt receive an email?
        <Link
          sx={{ marginLeft: '8px' }}
          href="/forgot-password"
          variant="body2"
          underline="always"
        >
          Resend email
        </Link>
      </Typography>
    </>
  )
}

interface FormValues {
  email: string
}

const ForgotPassword = () => {
  //hooks
  const theme = useTheme()
  const navigate = useNavigate()

  //local
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<'Pending' | 'Success'>('Pending')

  const initialValues: FormValues = { email: '' }

  const forgotPasswordHandler = async (
    formObject: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    actions.setSubmitting(false)
    try {
      setIsLoading(true)
      await forgotPassword(formObject.email)
      toast(
        <StyledToast
          variant="success"
          title="Password reset email sent successfully"
        />
      )
      setStatus('Success')
      actions.resetForm()
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Container status={status}>
          <Logo />
          {status === 'Pending' ? (
            <>
              <Formik
                initialValues={initialValues}
                validateOnMount={false}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Must be a valid email')
                    .max(255)
                    .required('Email is required'),
                })}
                onSubmit={(
                  values: FormValues,
                  actions: FormikHelpers<FormValues>
                ) => {
                  forgotPasswordHandler(values, actions)
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <StyledInput
                      inputType="email"
                      autoFocus={false}
                      id="email"
                      label=""
                      placeholder="Email address"
                      iconImageSrc={EmailIcon}
                      value={values.email}
                      isRequired={true}
                      fullWidth={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isError={Boolean(touched.email && errors.email)}
                      errorMessage={touched.email && errors.email}
                    />
                    <StyledButton
                      type="submit"
                      title="Submit"
                      variant="primary"
                      textVariant="base1"
                      onClickHandler={() => {}}
                      disabled={isSubmitting}
                    />
                  </StyledForm>
                )}
              </Formik>
              <Typography
                onClick={() => navigate('/login')}
                sx={{
                  color: theme.palette.secondary.main,
                  textAlign: 'center',
                  fontWeight: 500,
                  cursor: 'pointer',
                }}
                variant="base2"
              >
                I don't want to reset my password
              </Typography>
            </>
          ) : (
            <Notification />
          )}
        </Container>
      )}
    </>
  )
}

export default ForgotPassword
