import { styled, useTheme } from '@mui/material'

//components
import StyledModal from 'components/StyledModal/StyledModal'
import StyledButton from 'components/StyledButton/StyledButton'
import { Typography } from '@mui/material'

const Wrapper = styled('div')(() => ({
  maxHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  overflowY: 'auto',
}))

const ActionContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '12px',
  flexGrow: 1,
}))

interface Props {
  title: string
  confirmMessage: string
  confirmBtnTitle: string
  isOpen: boolean
  closeHandler: () => void
  confirmHandler: () => void
  loading?: boolean
}

const ConfirmationModal = ({
  title,
  confirmMessage,
  confirmBtnTitle,
  isOpen,
  closeHandler,
  confirmHandler,
  loading
}: Props) => {
  //hooks
  const theme = useTheme()

  return (
    <StyledModal
      isOpen={isOpen}
      title={title}
      closeHandler={closeHandler}
      width={640}
    >
      <Wrapper>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.neutral['n3-100'], fontWeight: 400 }}
        >
          {confirmMessage}
        </Typography>
      </Wrapper>
      <ActionContainer>
        <StyledButton
          onClickHandler={closeHandler}
          title="Cancel"
          variant="outlined"
          textVariant="base1"
        />
        <StyledButton
          onClickHandler={confirmHandler}
          title={confirmBtnTitle}
          variant="danger"
          textVariant="base1"
          disabled={loading}
        />
      </ActionContainer>
    </StyledModal>
  )
}

export default ConfirmationModal
