import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

//components
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Divider from '@mui/material/Divider'
import CopyTextField from 'components/CopyTextField/CopyTextFiled'
import StyledButton from 'components/StyledButton/StyledButton'
import StyledToast from 'components/StyledToast/StyledToast'
import CreateAPIKey from '../CreateAPIKeyModal/CreateAPIKeyModal'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'

//redux
import {
  addAPIkey,
  removeAPIKey,
  selectApiKeys,
  selectCurrentWorkspace,
} from 'redux/userSlice'

//services
import { createAPIKey, deleteAPIKey } from 'services/user.service'

//utils
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'
import handleServiceError from 'utils/handleServiceError'

const CreateBtnWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}))

const APIkeyContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  gap: '16px',
}))

const DeleteIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '24px',
  padding: '14px',
  borderRadius: '8px',
  backgroundColor: theme.palette.neutral['n4-25'],
  cursor: 'pointer',
}))

const Credentials = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  //redux
  const apiKeys = useSelector(selectApiKeys)
  const currentWorkspace = useSelector(selectCurrentWorkspace)

  //local
  const [isLoading, setIsLoading] = useState(false)
  const [currentAPIkey, setCurrentAPIkey] = useState({
    name: '',
    value: '',
  })
  const [isCreateAPIKeyModalOpen, setIsCreateAPIKeyModalOpen] = useState(false)
  const [isDeleteAPIKeyModalOpen, setIsDeleteAPIKeyModalOpen] = useState(false)

  const createAPIKeyHandler = async (name: string) => {
    try {
      setIsLoading(true)
      const response = await createAPIKey(name)
      dispatch(
        addAPIkey({
          name: response.data.name,
          value: response.data.value,
        })
      )
      toast(
        <StyledToast
          variant="success"
          title={`'${name}' API key created successfully.`}
        />
      )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsCreateAPIKeyModalOpen(false)
    }
  }

  const deleteAPIKeyHandler = async () => {
    try {
      setIsLoading(true)
      await deleteAPIKey(currentAPIkey.value)
      dispatch(removeAPIKey({ value: currentAPIkey.value }))
        toast(
          <StyledToast
            variant="success"
            title={`'${currentAPIkey.name}' API key was deleted successfully`}
          />
        )
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
      setIsDeleteAPIKeyModalOpen(false)
      setCurrentAPIkey({ name: '', value: '' })
    }
  }

  return (
    <>
      <CreateBtnWrapper>
        <StyledButton
          style={{ alignSelf: 'stretch' }}
          type="submit"
          title={false ? 'Creating...' : 'Create API key'}
          variant="primary"
          textVariant="base1"
          disabled={false}
          onClickHandler={() => setIsCreateAPIKeyModalOpen(true)}
        />
      </CreateBtnWrapper>
      {React.Children.toArray(
        apiKeys.map((item) => (
          <APIkeyContainer>
            <CopyTextField label={item.name} value={item.value} />
            <DeleteIconContainer
              onClick={() => {
                setCurrentAPIkey({ name: item.name, value: item.value })
                setIsDeleteAPIKeyModalOpen(true)
              }}
            >
              <DeleteOutlineOutlinedIcon
                sx={{ color: theme.palette.neutral['n3-50'] }}
              />
            </DeleteIconContainer>
          </APIkeyContainer>
        ))
      )}
      <Divider
        sx={{
          margin: '16px 0',
          backgroundColor: theme.palette.neutral['n4-25'],
        }}
      />
      {currentWorkspace && (
        <CopyTextField label={'Workspace Id'} value={currentWorkspace.id} />
      )}
      {isCreateAPIKeyModalOpen && (
        <CreateAPIKey
          isOpen={isCreateAPIKeyModalOpen}
          closeHandler={() => setIsCreateAPIKeyModalOpen(false)}
          submitActionHandler={createAPIKeyHandler}
          loading={isLoading}
        />
      )}
      {isDeleteAPIKeyModalOpen && (
        <ConfirmationModal
          title="Delete API key"
          isOpen={isDeleteAPIKeyModalOpen}
          confirmMessage={`You won't be able to undo this action. Are you sure you want to delete '${currentAPIkey.name}' API key ?`}
          confirmBtnTitle={isLoading ? 'Deleting...' : 'Delete'}
          confirmHandler={deleteAPIKeyHandler}
          closeHandler={() => setIsDeleteAPIKeyModalOpen(false)}
        />
      )}
    </>
  )
}

export default Credentials
