import axios from 'axios'

import { getAccessToken } from './auth.service'

//types
import { DefaultResponse, ApiStatus, toStatusType } from 'types/common'
import { INotebook } from 'types/notebook'

const BASE_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}/notebook/backend/v1/notebooks/`

type ApiNotebook = INotebook<ApiStatus>

interface NotebooksResponse extends DefaultResponse {
  data: ApiNotebook[]
}

interface NotebookResponse extends DefaultResponse {
  data: ApiNotebook
}

interface CreateNotebookResponse extends DefaultResponse {
  data: ApiNotebook
}

const transformNotebookResponse = (notebook: ApiNotebook): INotebook => {
  return {
    ...notebook,
    status: toStatusType(notebook.status),
  }
}

export const getNotebooks = async (
  workspaceId: string
): Promise<INotebook[]> => {
  const { data } = await axios.get<NotebooksResponse>(BASE_URL, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
  })
  return data.data.map(transformNotebookResponse)
}

export const getNotebookByName = async (
  workspaceId: string,
  notebookName: string | undefined
): Promise<INotebook | null> => {
  const { data } = await axios.get<NotebookResponse>(
    `${BASE_URL}/${notebookName}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        workspaceId: workspaceId,
      },
    }
  )
  return transformNotebookResponse(data.data)
}

export const createNotebook = async (
  workspaceId: string,
  data: {
    name: string
    machineType: string
    spot: boolean
    location: string
  }
): Promise<INotebook> => {
  const result = await axios.post<CreateNotebookResponse>(
    BASE_URL,
    {
      notebook_name: data.name,
      container_specs: {
        machine_type: data.machineType,
        spot: data.spot,
      },
      location: data.location,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        workspaceId: workspaceId,
      },
    }
  )
  return transformNotebookResponse(result.data.data)
}

export const deleteNotebook = async (
  workspaceId: string,
  name: string
): Promise<DefaultResponse> => {
  const { data } = await axios.delete(`${BASE_URL}/${name}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      workspaceId: workspaceId,
    },
    params: {
      hard_delete: true,
      reason: 'test',
    },
  })
  return data
}
