import { styled, useTheme } from '@mui/material'

//components
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'

const Container = styled('div')<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  ...(fullWidth && { width: '100%' }),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: '8px',
}))

const Wrapper = styled('div')(() => ({
  alignSelf: 'stretch',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral['n3-100'],
}))

const Textarea = styled('textarea')(({ theme, disabled }) => ({
  resize: 'none',
  width: '100%',
  maxWidth: '100%',
  background: 'none',
  lineHeight: '24px',
  padding: '14px 16px',
  outline: 'none',
  fontSize: '14px',
  fontFamily: theme.typography.fontFamily,
  userSelect: !disabled ? 'auto' : 'none',
  color: !disabled
    ? theme.palette.neutral['n1-100']
    : theme.palette.neutral['n4-50'],
  border: `1px solid ${theme.palette.neutral['n5-100']}`,
  borderRadius: '12px',
  '&:focus': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '&::-webkit-scrollbar': { width: '8px' },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '12px',
    backgroundColor: 'lightgrey',
  },
}))

interface Props {
  id?: string,
  rows: number
  label: string
  placeholder: string
  isRequired: boolean
  value: string | undefined
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  inputType?: string
  autoFocus?: boolean
  fullWidth?: boolean
  style?: React.CSSProperties
  disabled?: boolean
  infoText?: string
  showCheckbox?: boolean
  checked?: boolean
  checkboxHandleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const StyledTextArea = ({
  id,
  rows,
  label,
  placeholder,
  value,
  onChange,
  onBlur = () => {},
  autoFocus = false,
  infoText,
  isRequired,
  fullWidth = false,
  disabled = false,
  checked,
  showCheckbox,
  checkboxHandleChange,
}: Props) => {
  const theme = useTheme()

  return (
    <Container fullWidth={fullWidth}>
      {label && (
        <Wrapper>
          <Title variant="base2">{label}</Title>
          {showCheckbox && (
            <Checkbox checked={checked} onChange={checkboxHandleChange} />
          )}
        </Wrapper>
      )}
      <Textarea
        id={id}
        rows={rows}
        disabled={disabled}
        autoFocus={autoFocus}
        value={value}
        required={isRequired}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        spellCheck={false}
      />

      {infoText && (
        <Typography
          sx={{ color: theme.palette.neutral['n3-50'] }}
          variant="caption2"
        >
          {infoText}
        </Typography>
      )}
    </Container>
  )
}

export default StyledTextArea
