export type PollFn<T> = () => Promise<T>
export type ResolverFn<T, D> = (response: T) => D
export type OnSuccessFn<D> = (data: D) => void
export type StopperFn<D> = (data: D) => boolean

const pollAndSync = <T, D = T>(
  fn: PollFn<T>,
  resolver: ResolverFn<T, D> = (response) => response as unknown as D,
  onSucess: OnSuccessFn<D>,
  stopper: StopperFn<D>,
  onError?: (error: Error) => void,
  finalizer?: () => void,
  intervalSecs = 10,
  timeOutSecs = 60 * 10
) => {
  let timeoutId: NodeJS.Timeout | null = null
  const endTime = Date.now() + timeOutSecs * 1000

  const fetchData = async () => {
    try {
      const response = await fn()
      const resolvedData = resolver(response)
      onSucess(resolvedData)
      if (!stopper(resolvedData) && Date.now() < endTime) {
        timeoutId = setTimeout(fetchData, intervalSecs * 1000)
      }
    } catch (error) {
      if (onError) {
        onError(error as Error)
      }
    } finally {
      if (finalizer) {
        finalizer()
      }
    }
  }

  fetchData()

  return () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  }
}

export default pollAndSync
