import React from 'react'

const useFetchData = () => {
  const [loading, setLoading] = React.useState(false)

  const fetch = <T, D = T>(
    promise: Promise<T>,
    resolver = (response: T) => response as unknown as D,
    onSucess?: (response: D) => void,
    onError?: (error: any) => void
  ) => {
    setLoading(true)
    promise
      .then((res) => {
        if (onSucess) {
          onSucess(resolver(res))
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error)
        }
        console.error(error)
      })
      .finally(() => setLoading(false))
  }

  return {
    loading,
    fetch,
  }
}

export default useFetchData
