export interface GpuSpecs {
  type: string
  memory: number
  number: number
}

export interface MachineSpecs {
  cpu: number
  memory: number
  diskSize: number
  gpu?: GpuSpecs
}

export type CloudProvider = 'gcp' | 'azure'
export const MACHINES_DATA: Record<string, Record<string, MachineSpecs>> = {
  gcp: {
    cpu_small: {
      cpu: 4,
      memory: 16,
      diskSize: 200,
    },
    cpu_medium: {
      cpu: 8,
      memory: 32,
      diskSize: 400,
    },
    cpu_large: {
      cpu: 16,
      memory: 64,
      diskSize: 600,
    },
    cpu_xlarge: {
      cpu: 30,
      memory: 120,
      diskSize: 800,
    },
    gpu_t4_1: {
      cpu: 4,
      memory: 15,
      diskSize: 500,
      gpu: {
        type: 'T4',
        memory: 16,
        number: 1,
      },
    },
    gpu_t4_2: {
      cpu: 8,
      memory: 30,
      diskSize: 500,
      gpu: {
        type: 'T4',
        memory: 32,
        number: 2,
      },
    },
    gpu_t4_4: {
      cpu: 16,
      memory: 60,
      diskSize: 900,
      gpu: {
        type: 'T4',
        memory: 64,
        number: 4,
      },
    },
    gpu_l4_1: {
      cpu: 8,
      memory: 32,
      diskSize: 500,
      gpu: {
        type: 'L4',
        memory: 24,
        number: 1,
      },
    },
    gpu_l4_2: {
      cpu: 24,
      memory: 48,
      diskSize: 500,
      gpu: {
        type: 'L4',
        memory: 48,
        number: 2,
      },
    },
    gpu_l4_4: {
      cpu: 48,
      memory: 96,
      diskSize: 900,
      gpu: {
        type: 'L4',
        memory: 96,
        number: 4,
      },
    },
    gpu_l4_8: {
      cpu: 96,
      memory: 192,
      diskSize: 900,
      gpu: {
        type: 'L4',
        memory: 192,
        number: 8,
      },
    },
    gpu_v100_1: {
      cpu: 4,
      memory: 15,
      diskSize: 500,
      gpu: {
        type: 'V100',
        memory: 16,
        number: 1,
      },
    },
    gpu_v100_2: {
      cpu: 8,
      memory: 30,
      diskSize: 500,
      gpu: {
        type: 'V100',
        memory: 32,
        number: 2,
      },
    },
    gpu_v100_4: {
      cpu: 16,
      memory: 60,
      diskSize: 900,
      gpu: {
        type: 'V100',
        memory: 64,
        number: 4,
      },
    },
    gpu_v100_8: {
      cpu: 32,
      memory: 120,
      diskSize: 900,
      gpu: {
        type: 'V100',
        memory: 128,
        number: 8,
      },
    },
    //FIXME:

    gpu_a100_40_seventh: {
      cpu: 12,
      memory: 85,
      diskSize: 500,
      gpu: {
        type: 'A100',
        memory: 40,
        number: 1,
      },
    },
    //FIXME:

    gpu_a100_40_half: {
      cpu: 24,
      memory: 170,
      diskSize: 500,
      gpu: {
        type: 'A100',
        memory: 80,
        number: 2,
      },
    },
    gpu_a100_40_1: {
      cpu: 12,
      memory: 85,
      diskSize: 500,
      gpu: {
        type: 'A100',
        memory: 40,
        number: 1,
      },
    },
    gpu_a100_40_2: {
      cpu: 24,
      memory: 170,
      diskSize: 500,
      gpu: {
        type: 'A100',
        memory: 80,
        number: 2,
      },
    },
    gpu_a100_40_4: {
      cpu: 48,
      memory: 340,
      diskSize: 900,
      gpu: {
        type: 'A100',
        memory: 160,
        number: 4,
      },
    },
    gpu_a100_40_8: {
      cpu: 96,
      memory: 680,
      diskSize: 1200,
      gpu: {
        type: 'A100',
        memory: 320,
        number: 8,
      },
    },
    gpu_a100_40_16: {
      cpu: 96,
      memory: 1360,
      diskSize: 1500,
      gpu: {
        type: 'A100',
        memory: 640,
        number: 16,
      },
    },
    //FIXME:
    gpu_a100_80_seventh: {
      cpu: 12,
      memory: 170,
      diskSize: 500,
      gpu: {
        type: 'A100',
        memory: 80,
        number: 1,
      },
    },
    //FIXME:
    gpu_a100_80_half: {
      cpu: 24,
      memory: 340,
      diskSize: 500,
      gpu: {
        type: 'A100',
        memory: 160,
        number: 2,
      },
    },
    gpu_a100_80_1: {
      cpu: 12,
      memory: 170,
      diskSize: 500,
      gpu: {
        type: 'A100',
        memory: 80,
        number: 1,
      },
    },
    gpu_a100_80_2: {
      cpu: 24,
      memory: 340,
      diskSize: 500,
      gpu: {
        type: 'A100',
        memory: 160,
        number: 2,
      },
    },
    gpu_a100_80_4: {
      cpu: 48,
      memory: 680,
      diskSize: 1000,
      gpu: {
        type: 'A100',
        memory: 320,
        number: 4,
      },
    },
    gpu_a100_80_8: {
      cpu: 96,
      memory: 1360,
      diskSize: 1500,
      gpu: {
        type: 'A100',
        memory: 640,
        number: 8,
      },
    },
  },
  azure: {
    cpu_small: {
      cpu: 4,
      memory: 16,
      diskSize: 200,
    },
    cpu_medium: {
      cpu: 8,
      memory: 32,
      diskSize: 400,
    },
    cpu_large: {
      cpu: 16,
      memory: 64,
      diskSize: 600,
    },
    cpu_xlarge: {
      cpu: 32,
      memory: 128,
      diskSize: 800,
    },
    gpu_t4_1: {
      cpu: 4,
      memory: 28,
      diskSize: 500,
      gpu: {
        type: 'T4',
        number: 1,
        memory: 16,
      },
    },
    gpu_t4_4: {
      cpu: 16,
      memory: 440,
      diskSize: 900,
      gpu: {
        type: 'T4',
        number: 4,
        memory: 64,
      },
    },
    gpu_a100_80_1: {
      cpu: 12,
      memory: 220,
      diskSize: 500,
      gpu: {
        type: 'A100',
        number: 1,
        memory: 80,
      },
    },
    gpu_a100_80_2: {
      cpu: 24,
      memory: 440,
      diskSize: 500,
      gpu: {
        type: 'A100',
        number: 2,
        memory: 160,
      },
    },
    gpu_a100_80_4: {
      cpu: 48,
      memory: 880,
      diskSize: 1000,
      gpu: {
        type: 'A100',
        number: 4,
        memory: 320,
      },
    },
  },
}

//NOTE: Use the same to get the region if needed
export const getCloudProvider = (providerRegion: string) => {
  //Eg: gcp_uscentral1 split by underscore and take the first part
  const [provider] = providerRegion.split('_')
  return provider
}

const getMachineSpecs = (providerRegion: string, machine: string) => {
  const provider = getCloudProvider(providerRegion)

  if (!(provider in MACHINES_DATA)) {
    throw new Error(`Provider ${provider} not found`)
  }

  if (!(machine in MACHINES_DATA[provider])) {
    throw new Error(`Machine ${machine} not found`)
  }
  
  return MACHINES_DATA[provider][machine]
}

export default getMachineSpecs
