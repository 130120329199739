import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentWorkspace } from 'redux/userSlice'
import { getQuotasInfo } from 'services/usageQuota.service'

const useCheckWorkspaceActivation = () => {
  const [loading, setLoading] = React.useState(false)
  const [workspaceActivation, setWorkspaceActivation] = React.useState(false)
  const currentWorkspace = useSelector(selectCurrentWorkspace)

  React.useEffect(() => {
    if (!currentWorkspace) return
    setLoading(true)
    getQuotasInfo(currentWorkspace.id)
      .then((res) => {
        if (!res.success) return
        const { quotas_usage } = res.data
        // if any of the quotas is not 0, then workspace is activated
        setWorkspaceActivation(
          Object.values(quotas_usage).some((quota) => quota.max_quota > 0)
        )
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [currentWorkspace])

  return {
    loading,
    isActivated: workspaceActivation,
  }
}

export default useCheckWorkspaceActivation
