import { useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'

//components
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader/Loader'
import StyledInput from 'components/StyledInput/StyledInput'
import { StyledForm } from './components/SharedComponents'
import StyledButton from 'components/StyledButton/StyledButton'
import StyledToast from 'components/StyledToast/StyledToast'
import Logo from './components/Logo/Logo'

//services
import { resetPassword } from 'services/auth.service'

//assets
import PasswordIcon from 'assets/icons/password.png'
import handleServiceError from 'utils/handleServiceError'

const Container = styled(Stack)(({ theme }) => ({
  width: '540px',
  display: 'flex',
  alignSelf: 'flex-start',
  padding: '60px',
  borderRadius: '20px',
  marginTop: '60px',
  gap: '18px',
  userSelect: 'none',
  backgroundColor: theme.palette.neutral['n6-100'],
  '@media (max-width: 768px)': {
    width: '90%',
    flexDirection: 'column',
    marginTop: '16px',
    marginBottom: '16px',
    padding: '16px 24px',
  },
}))

interface FormValues {
  password: string
  confirmPassword: string
}

const ResetPassword = () => {
  //hooks
  const theme = useTheme()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const initialValues: FormValues = { password: '', confirmPassword: '' }

  //local
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function resetPasswordHandler(
    formObject: FormValues,
    actions: FormikHelpers<FormValues>
  ) {
    actions.setSubmitting(false)
    actions.resetForm()

    if (!token) {
      toast(
        <StyledToast
          variant="error"
          title="Reset password failed. Please re-open the link from the email"
        />,
        { autoClose: false }
      )
      return
    }

    try {
      setIsLoading(true)
      await resetPassword(token, formObject.password)
      actions.resetForm()
      toast(<StyledToast variant="success" title="Password reset successful" />)
      navigate('/login', { replace: true })
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Container>
          <Logo />
          <Formik
            initialValues={initialValues}
            validateOnMount={false}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .max(255)
                .matches(
                  /^(?=.*[a-z])/,
                  `Password should contain one lower case letter`
                )
                .matches(
                  /^(?=.*[A-Z])/,
                  'Password should contain one upper case letters'
                )
                .matches(/^(?=.*[0-9])/, 'Password should contain one number'),
              confirmPassword: Yup.string()
                .min(8)
                .max(255)
                .required('Confirm password is required')
                .oneOf([Yup.ref('password')], 'Password do not match'),
            })}
            onSubmit={(
              values: FormValues,
              actions: FormikHelpers<FormValues>
            ) => {
              resetPasswordHandler(values, actions)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                <StyledInput
                  inputType="password"
                  id="password"
                  label=""
                  placeholder="Password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={Boolean(touched.password && errors.password)}
                  errorMessage={touched.password && errors.password}
                  autoComplete="on"
                  iconImageSrc={PasswordIcon}
                  autoFocus={true}
                  isRequired={true}
                  fullWidth={true}
                />
                <StyledInput
                  inputType="password"
                  id="confirmPassword"
                  label=""
                  placeholder="Confirm password"
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  errorMessage={
                    touched.confirmPassword && errors.confirmPassword
                  }
                  iconImageSrc={PasswordIcon}
                  autoFocus={false}
                  fullWidth={true}
                  isRequired={true}
                  autoComplete="off"
                />
                <StyledButton
                  type="submit"
                  title="Reset password"
                  variant="primary"
                  textVariant="base1"
                  onClickHandler={() => {}}
                  disabled={isSubmitting}
                />
              </StyledForm>
            )}
          </Formik>
        </Container>
      )}
    </>
  )
}

export default ResetPassword
