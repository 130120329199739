import React from 'react'
import { styled } from '@mui/material'

import Typography from '@mui/material/Typography'

interface Props {
  type?: 'button' | 'submit'
  startIcon?: React.ReactNode
  style?: React.CSSProperties
  title: string | React.ReactNode
  onClickHandler?: () => void
  disabled?: boolean
}

const CustomButton = styled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderStyle: 'solid',
  gap: '8px',
  cursor: 'pointer',
  color: theme.palette.neutral['n1-100'],
  backgroundColor: 'transparent',
  borderColor: theme.palette.neutral['n5-100'],
  padding: '12px 24px',
  borderRadius: '12px',
  borderWidth: '2px',
  fontFamily: theme.typography.fontFamily,
  '&:hover': {},
  '&:active': {},
}))

const OAuthBtn = (props: Props) => {
  const {
    type = 'button',
    startIcon,
    title,
    style,
    onClickHandler,
    disabled = false,
  } = props

  return (
    <CustomButton
      type={type}
      onClick={onClickHandler}
      style={{ ...style }}
      disabled={disabled}
    >
      {startIcon && startIcon}
      {typeof title === 'string' ? (
        <Typography variant="base1">{title}</Typography>
      ) : (
        title
      )}
    </CustomButton>
  )
}

export default OAuthBtn
