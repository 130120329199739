import { useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

//components
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import StyledButton from 'components/StyledButton/StyledButton'
import Logo from './components/Logo/Logo'
import StyledToast from 'components/StyledToast/StyledToast'

//services
import { verifyEmail } from 'services/auth.service'
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'
import handleServiceError from 'utils/handleServiceError'

const Container = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  padding: '56px',
  borderRadius: '12px',
  marginTop: '60px',
  gap: '16px',
  color: theme.palette.neutral['n3-100'],
  backgroundColor: theme.palette.neutral['n6-100'],
  '@media (max-width: 768px)': {
    width: '90%',
    flexDirection: 'column',
    marginTop: '16px',
    marginBottom: '16px',
    padding: '16px 24px',
  },
}))

const Pending = () => {
  return (
    <>
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        Thank you for signing up
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        We just need to verify your email to complete setting up your account.
        Please click on the link that has sent to your registered email and
        continue with the registration process.
        {/*Already verified <span>
          <Link
            sx={{ marginLeft: '4px' }}
            href="/login"
            variant="body2"
            underline="always"
          >
            login here
          </Link>
        </span> */}
      </Typography>
    </>
  )
}

const Success = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <>
      <Typography
        variant="h3"
        sx={{ color: theme.palette.primary.main, textAlign: 'center' }}
      >
        Email verification success
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        Great news! You've been added to our wait list for exclusive access.
        We'll notify you as soon as access is granted.
      </Typography>
      {/* <StyledButton
        type="button"
        title="Login"
        variant="primary"
        textVariant="base1"
        disabled={false}
        onClickHandler={() => navigate('/login')}
      /> */}
    </>
  )
}

const Fail = () => {
  const theme = useTheme()
  return (
    <>
      <Typography
        variant="h3"
        sx={{ color: theme.palette.accent.a1, textAlign: 'center' }}
      >
        Email verification failed
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        Please make sure that you have opened the link that has sent to your
        email. If you are still having trouble verifying your email contact
        support.
      </Typography>
    </>
  )
}

const EmailVerificationLandingPage = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const redirect = searchParams.get('redirect')

  //component state
  const [isLoading, setIsLoading] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState<
    'Pending' | 'Success' | 'Fail'
  >('Fail')

  const emailVerificationFailed = () => {
    toast(<StyledToast variant="error" title="Email verification failed" />)
  }

  useEffect(() => {
    const verifyEmailHandler = async () => {
      if (!token) {
        setVerificationStatus('Fail')
        emailVerificationFailed()
        return
      }

      try {
        setIsLoading(true)
        await verifyEmail(token)
        setVerificationStatus('Success')
        toast(
          <StyledToast variant="success" title="Email verified successfully" />
        )
      } catch (error) {
        handleServiceError(error)
        setVerificationStatus('Fail')
        emailVerificationFailed()
      } finally {
        setIsLoading(false)
      }
    }
    if (!redirect) {
      verifyEmailHandler()
    } else {
      setVerificationStatus('Pending')
    }
  }, [])

  return (
    <Container>
      <Logo />
      {isLoading ? (
        <>
          <CircularProgress />
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            Verifying
          </Typography>
        </>
      ) : (
        <>
          {verificationStatus === 'Pending' && <Pending />}
          {verificationStatus === 'Success' && <Success />}
          {verificationStatus === 'Fail' && <Fail />}
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            Having trouble signup or login?
            <Link
              sx={{ marginLeft: '8px' }}
              href={`mailto:support@stochastic.ai?subject=xCloud signup or login issue&body = Message`}
              variant="body2"
              underline="always"
            >
              Get in touch with us
            </Link>
          </Typography>
        </>
      )}
    </Container>
  )
}

export default EmailVerificationLandingPage
