import { useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

//components
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import StyledButton from 'components/StyledButton/StyledButton'
import Logo from './components/Logo/Logo'
import StyledToast from 'components/StyledToast/StyledToast'

//services
import { storeWorkspaceInviteToken } from 'services/auth.service'
import { STANDARD_ERROR_MESSAGE } from 'utils/constants'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from 'redux/userSlice'

const Container = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  padding: '56px',
  borderRadius: '12px',
  marginTop: '60px',
  gap: '16px',
  color: theme.palette.neutral['n3-100'],
  backgroundColor: theme.palette.neutral['n6-100'],
  '@media (max-width: 768px)': {
    width: '90%',
    flexDirection: 'column',
    marginTop: '16px',
    marginBottom: '16px',
    padding: '16px 24px',
  },
}))

const Fail = () => {
  const theme = useTheme()
  return (
    <>
      <Typography
        variant="h3"
        sx={{ color: theme.palette.accent.a1, textAlign: 'center' }}
      >
        Join workspace failed
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        Please make sure that you have opened the link that has sent to your
        email. If you are still having trouble joining workspace contact
        support.
      </Typography>
    </>
  )
}

const JoinWorkspace = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  //redux
  const isLoggedIn = useSelector(selectIsLoggedIn)

  //component state
  const [isLoading, setIsLoading] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState<
    'Pending' | 'Success' | 'Fail'
  >('Pending')

  useEffect(() => {
    setIsLoading(true)
    const inviteToken = searchParams.get('inviteToken')
    if (inviteToken) {
      storeWorkspaceInviteToken(inviteToken)
      if (!isLoggedIn) {
        // toast(
        //   <StyledToast
        //     variant="error"
        //     title="Login to continue. Don't have an account? Sign up"
        //   />
        // )
        navigate('/login', { replace: true })
      } else {
        navigate('/')
      }
    } else {
      setVerificationStatus('Fail')
      toast(<StyledToast variant="error" title={STANDARD_ERROR_MESSAGE} />)
    }
    setIsLoading(false)
  }, [])

  return (
    <Container>
        <Logo />
        {isLoading ? (
          <>
            <CircularProgress />
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              Verifying
            </Typography>
          </>
        ) : (
          <>{verificationStatus === 'Fail' && <Fail />}</>
        )}
    </Container>
  )
}

export default JoinWorkspace
