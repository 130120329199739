import React from 'react'
import { styled, Palette } from '@mui/material'

import Tooltip from '@mui/material/Tooltip'

import { Status } from 'types/common'

export const getColor = (
  variant: Status,
  palette: Palette
): React.CSSProperties['color'] => {
  switch (variant) {
    case Status.Running:
    case Status.Deploying:
      return palette.primary.main
    case Status.Successful:
      return palette.accent['a2']
    case Status.Failed:
      return palette.accent['a1']
    case Status.Default:
    default:
      return palette.neutral['n3-50']
  }
}

const PillWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const Pill = styled('div')<{ status: Status }>(({ theme, status }) => ({
  fontSize: '14px',
  padding: '4px 8px',
  fontWeight: 600,
  borderRadius: '4px',
  textTransform: 'capitalize',
  color: `${getColor(status, theme.palette)}`,
}))

interface Props {
  status: Status
  error?: string | null
}

const StatusPill: React.FC<Props> = ({ status, error }) => {
  if (status === Status.Failed && error) {
    return (
      <PillWrapper>
        <Tooltip title={error}>
          <Pill status={status}>{status}</Pill>
        </Tooltip>
      </PillWrapper>
    )
  }
  return (
    <PillWrapper>
      <Pill status={status}>{status}</Pill>
    </PillWrapper>
  )
}

export default StatusPill

// const getColor = (status: string) => {
//   const data: any = {
//     successful: {
//       color: 'green',
//       backgroundColor: '#f0fff7',
//     },
//     running: {
//       color: '#2962ff',
//       backgroundColor: '#e3f5ff',
//     },
//     failed: {
//       color: 'red',
//       backgroundColor: '#FFE7E7',
//     },
//     default: {
//       color: 'grey',
//       backgroundColor: '#f4f4ff',
//     },
//   }

//   return data[status] ? data[status] : data['default']
// }
