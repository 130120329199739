import { Sync } from '@mui/icons-material'
import { Tooltip, styled } from '@mui/material'
import StyledButton from 'components/StyledButton/StyledButton'
import { PropsWithChildren } from 'react'

const ActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '8px',
  borderRadius: '8px',
  backgroundColor: theme.palette.neutral['n4-25'],
  cursor: 'pointer',
}))

interface Props {
  onRefresh: () => void
  variant?: 'text' | 'icon'
  title?: string
  disabled?: boolean
}

const ActionsWithRefresh = ({
  children,
  onRefresh,
  disabled = false,
  variant = 'text',
  title = 'Refresh',
}: PropsWithChildren<Props>) => {
  return (
    <ActionsWrapper>
      {variant === 'text' ? (
        <StyledButton
          variant="outlined"
          title={title}
          startIcon={<Sync fontSize="small" />}
          disabled={disabled}
          onClickHandler={onRefresh}
        />
      ) : (
        <Tooltip title={title} placement="left" arrow>
          <IconContainer
            style={
              disabled
                ? {
                    pointerEvents: 'none',
                    opacity: 0.7,
                  }
                : undefined
            }
            onClick={onRefresh}
          >
            <Sync fontSize="small" />
          </IconContainer>
        </Tooltip>
      )}
      {children}
    </ActionsWrapper>
  )
}

export default ActionsWithRefresh
