import { memo } from 'react'
import { styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

//components
import LoadUser from 'components/LoadUser/LoadUser'
import SidePanel from './components/SidePanel/SidePanel'

//redux
import { selectIsLoggedIn } from 'redux/userSlice'
import { selectIsAppLoading } from 'redux/appSlice'
import { ComponentLoader } from 'components/Loader/Loader'
import JoinWorkspaceHandler from 'components/JoinWorkspaceHandler/JoinWorkspaceHandler'

const App = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100vh',
  color: theme.palette.neutral['n1-100'],
  backgroundColor: theme.palette.neutral['n7-100'],
}))

const LayoutWrapper = () => {
  //redux
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isAppLoading = useSelector(selectIsAppLoading)

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />
  }

  return (
    <App>
      <LoadUser />
      <JoinWorkspaceHandler /> 
      {!isAppLoading ? (
        <>
          <SidePanel />
          <Outlet />
        </>
      ) : (
        <ComponentLoader />
      )}
    </App>
  )
}

export default memo(LayoutWrapper)
