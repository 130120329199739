import { useState } from 'react'
import { styled } from '@mui/material/styles'

//components
import StyledModal from 'components/StyledModal/StyledModal'
import StyledButton from 'components/StyledButton/StyledButton'
import StyledTextArea from 'components/StyledTextarea/StyledTextArea'
import StyledInput from 'components/StyledInput/StyledInput'

const ActionContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '12px',
}))

const Wrapper1 = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '12px',
  flexGrow: 1,
}))

interface Props {
  isOpen: boolean
  closeHandler: () => void
  submitHandler: (message: string) => void
  loading?: boolean
}

const ContactSupportModal = ({
  isOpen,
  closeHandler,
  submitHandler,
  loading,
}: Props) => {
  //local
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  return (
    <StyledModal
      isOpen={isOpen}
      title="Contact support"
      closeHandler={closeHandler}
      width={640}
    >
      {/* <StyledInput
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(e.target.value)
        }}
        label='Email'
        autoFocus={true}
        id='contact-email'
        placeholder='Enter your email address here'
        value={email}
        isRequired={true}
        fullWidth={true}
      /> */}
      <StyledTextArea
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setMessage(e.target.value)
        }}
        autoFocus={true}
        rows={4}
        label=""
        placeholder="What would you like help with? We will get back to you in 24 hours."
        value={message}
        isRequired={true}
        fullWidth={true}
        disabled={false}
      />

      <ActionContainer>
        <StyledButton
          onClickHandler={closeHandler}
          title="Cancel"
          variant="outlined"
          textVariant="base1"
        />
        <StyledButton
          title={loading ? 'Submitting...' : 'Submit'}
          variant="primary"
          textVariant="base1"
          onClickHandler={() => submitHandler(message)}
          disabled={loading}
        />
      </ActionContainer>
    </StyledModal>
  )
}

export default ContactSupportModal
