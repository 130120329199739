import React from 'react'
import { LinearProgress, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

//components
import Typography from '@mui/material/Typography'
import { IconContainer } from 'components/IconContainer/IconContainer'

//assets
import ChevronRightLight from 'assets/icons/chevron-right-light.png'

const LOADER_HEIGHT_SPACING = 0.2

const HeaderContainer = styled('div')(({ theme }) => ({
  width: '100%',
}))

const Header = styled('div')(({ theme }) => ({
  flex: '0 0 auto',
  width: '100%',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  padding: '16px 32px',
  backgroundColor: theme.palette.neutral['n6-100'],
  borderBottom: '1px solid rgba(254, 254, 254, 0.1)',
  borderRadius: '20px 20px 0 0 ',
}))

const HeaderFrame = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
  borderRadius: '20px',
}))

const Spacer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: theme.spacing(LOADER_HEIGHT_SPACING),
}))

interface Props {
  title: string
  path: string
  subTitle?: string
  actions?: React.ReactNode
  loading?: boolean
}

const PageHeader = ({ title, path, subTitle, actions, loading }: Props) => {
  const navigate = useNavigate()

  return (
    <HeaderContainer>
      <Header>
        <HeaderFrame>
          <Typography
            variant="h5"
            sx={{ cursor: subTitle ? 'pointer' : 'default' }}
            onClick={() => (subTitle ? navigate(path) : {})}
          >
            {title}
          </Typography>
          {subTitle && (
            <>
              <IconContainer src={ChevronRightLight} width={22} height={22} />
              <Typography variant="h5">{subTitle}</Typography>
            </>
          )}
        </HeaderFrame>
        {actions}
      </Header>
      {loading !== undefined && loading ? (
        <LinearProgress sx={{ height: (theme) => theme.spacing(LOADER_HEIGHT_SPACING) }} />
      ) : (
        // prevents page from jumping when loading
        <Spacer />
      )}
    </HeaderContainer>
  )
}

export default PageHeader
