import { styled } from '@mui/material'
//assets
import LogoImg from 'assets/xcloud_logo.png'

const LogoWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '16px'
}))

const Logo = () => {
  return (
    <LogoWrapper>
      <img width={160} src={LogoImg} alt="xcloud-logo" />
    </LogoWrapper>
  )
}

export default Logo
