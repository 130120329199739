import { useTheme } from '@mui/material'

import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const DividerOR = () => {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
      }}
      spacing={2}
      direction="row"
    >
      <Divider
        sx={{
          flex: 1,
          backgroundColor: theme.palette.neutral['n4-50'],
        }}
      />
      <Typography
        sx={{ color: theme.palette.neutral['n4-50'] }}
        variant="caption1"
      >
        OR
      </Typography>
      <Divider
        sx={{
          flex: 1,
          backgroundColor: theme.palette.neutral['n4-50'],
        }}
      />
    </Stack>
  )
}

export default DividerOR
