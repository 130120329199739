import { AxiosError } from 'axios'
import StyledToast from 'components/StyledToast/StyledToast'
import { toast } from 'react-toastify'
import { STANDARD_ERROR_MESSAGE } from './constants'

export type ApiError = AxiosError & {
  response: {
    data: {
      success: boolean
      message: string
    }
  }
}

export const isApiError = (error: unknown): error is ApiError => {
  return (
    (error as ApiError).response !== undefined &&
    (error as ApiError).response.data !== undefined &&
    (error as ApiError).response.data.success === false &&
    (error as ApiError).response.data.message !== undefined
  )
}

const handleServiceError = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (isApiError(error)) {
      const {
        data: { message },
      } = error.response
      toast(<StyledToast variant="error" title={message} />)
    } else {
      console.error(error.message)
      toast(<StyledToast variant="error" title={STANDARD_ERROR_MESSAGE} />)
    }
  } else {
    console.error(error)
  }
}

export default handleServiceError
